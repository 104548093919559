// AuthContext.js
// ESTE CONTEXTO ES EL QUE SE UTILIZA

import { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import axios, { createAxiosInstance, axiosSinBearer } from "../api/axios";
import { useNavigate } from "react-router-dom";
import { getRole } from "../utils/validate";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [rol, setRol] = useState(null);
  const [token, setToken] = useState(null);
  const [info, setInfo] = useState(null);
  const [notificaciones, setNotificaciones] = useState([]);
  const Navigate = useNavigate();

  const login = async ({ email, password }) => {
    console.log("login send", email, password);

    let auth_token = await axios.post("/api/v1/Auth/Login", {
      Username: email,
      Password: password,
    });

    if (
      auth_token.data.message !== "Success" &&
      auth_token.data.message != "Requiere Autenticación de dos factores"
    ) {
      console.log("response", auth_token);
      return {
        success: false,
        message: auth_token.data,
      };
    } else if (
      auth_token.data.message === "Requiere Autenticación de dos factores"
    ) {
      try {
        return auth_token;
      } catch (error) {
        console.log("error:--> en sendCodeTwF", error);
      }
    } else {
      setToken(auth_token.data.token);
      const decoded = jwtDecode(auth_token.data.token);
      setUser(decoded);
      localStorage.setItem("token", auth_token.data.token);
      const role = getRole();
      setRol(role);

      await infoUser();

      if (role.includes("Supervisor de Bodega")) {
        Navigate("/notificaciones");
      } else {
        Navigate("/inicio");
      }

      return {
        success: true,
      };
    }
  };

  const sendCode = async (data) => {
    console.log("llega--->", data);

    try {
      const resp = await axios.post(
        `/api/v1/Auth/ValidateTwoFactor?id=${data.id}&code=${data.codigo}`
      );
      console.log("sendcodeResponse", resp);

      if (resp.data.message === "Success") {
        setToken(resp.data.token);
        const decoded = jwtDecode(resp.data.token);
        setUser(decoded);
        localStorage.setItem("token", resp.data.token);
        const role = getRole();
        setRol(role);

        await infoUser();

        if (role.includes("Supervisor de Bodega")) {
          Navigate("/notificaciones");
        } else {
          Navigate("/inicio");
        }

        return {
          success: true,
        };
      }
      return resp;
    } catch (error) {
      console.log("error:--> en sendCode", error);
      throw new Error(error);
    }
  };

  // Nueva función para manejar la autenticación SSO
  // const loginSSO = async (id) => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/api/v1/Auth/LoginSSO/${id}`
  //     );
  //     const data = await response.json();
  //     console.log("loginSSO response", data);
  //   } catch (error) {
  //     console.log("error:--> en loginSSO", error.message);
  //   }
  // };

  const loginSSO = async (id) => {
    console.log("loginSSO id", id);

    try {
      const response = await axios.get(`/api/v1/Auth/LoginSSO/${id}`);
      console.log("loginSSO response", response);

      if (response.data.message === "Success") {
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setUser(decoded);
        localStorage.setItem("token", response.data.token);
        const role = getRole();
        setRol(role);

        await infoUser();

        if (role.includes("Supervisor de Bodega")) {
          Navigate("/notificaciones");
        } else {
          Navigate("/inicio");
        }

        return {
          success: true,
        };
      }

      return {
        success: false,
        message: response.data.message,
      };
    } catch (error) {
      console.log("error:--> en loginSSO", error);
      throw new Error(error);
    }
  };

  const axiosInstance = createAxiosInstance();
  const infoUser = async () => {
    try {
      let usuario = await axiosInstance.get("/api/v1/Auth/GetInfo");
      setInfo(usuario.data);
      getNotifications();
      return { success: true };
    } catch (e) {
      console.log("error al obtener información del usuario", e.message);
    }
  };
  const getNotifications = async () => {
    try {
      let resp = await axiosInstance.get("/api/v1/Auth/GetNotificaciones");
      const sortedNotifications = resp.data.sort(
        (a, b) => new Date(b.fecha) - new Date(a.fecha)
      );
      setNotificaciones(sortedNotifications);
      return { success: true };
    } catch (e) {
      console.log("error al obtener notificaciones del usuario", e.message);
    }
  };

  const logout = () => {
    //modificar función
    setUser(null);
    setRol(null);
    setToken(null);
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userInfo = async () => {
      try {
        await infoUser();
      } catch (error) {}
    };
    if (token) {
      const decoded = jwtDecode(token);
      setToken(token);
      setUser(decoded);
      setRol(getRole());
      userInfo();
    } else {
    }
  }, []);

  const isAuthenticated = () => !!user;

  return (
    <AuthContext.Provider
      value={{
        user,
        info,
        notificaciones,
        getNotifications,
        infoUser,
        login,
        logout,
        isAuthenticated,
        rol,
        setUser,
        setRol,
        token,
        sendCode,
        loginSSO,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
