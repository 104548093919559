import styles from './components.module.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, Popper, Backdrop, Button, CircularProgress, Dialog, Drawer, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography, MenuItem, Select } from '@mui/material';
import { updateMotivo } from '../../../../redux/features/motivos/motivoSlice';
import ErrorIcon from '@mui/icons-material/Error';

const data = {
  criticidad:'',
  especialidad:'',
  prioridad:'',
  descripcion:'',
}

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3, requerimientos, open3 }) {

  const dispatch = useDispatch();
  
  const [selectedEspecialidad, setSelectedEspecialidad] = useState({options: []});
  const [selectedSolicitud, setSelectedSolicitud] = useState(null);
  const [selectedPrioridad, setSelectedPrioridad] = useState(null);
  const [especialidades, setEspecialidades] = useState([]);
  const [prioridades, setPrioridades] = useState([]);
  const [solicitudes, setSolicitudes] = useState([]);

  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');

    useEffect(() => {
        if(seleItem){
          console.log('seleItem-->-->', seleItem);
          setFormData({...formData, descripcion: null});
          setFormData(seleItem)
          setSelectedEspecialidad(seleItem?.especialidad || null);
          const especialidadesUnicas = new Set(requerimientos.map(item => item.especialidad));
          const especialidadesArray = Array.from(especialidadesUnicas);
          setEspecialidades(especialidadesArray);
          setSolicitudes(requerimientos.map(item => item.solicitud))
          setSelectedSolicitud(seleItem?.solicitud || null);
        }else{
          setFormData(data);
        }
    }, [seleItem, openEdit])

  const handleChangeEspecialidad = (event, newValue) => {
    console.log(newValue);
    setSelectedPrioridad('');
    if(newValue != null){
      setFormData({ ...formData, especialidad: newValue
        // , solicitud: '', prioridad: '' 
      });
      setSelectedEspecialidad(newValue);
      // setSelectedSolicitud(null);
      // setSelectedPrioridad('');
    } else{
      setFormData({...formData, especialidad: '', solicitud: '', prioridad: ''})
    }
  };
  const handleChangeSolicitud = (event, newValue) => {
    console.log(newValue)
    if(newValue != null){
      setFormData({...formData, solicitud: newValue
        // , prioridad: newValue.prioridad
      })
      setSelectedSolicitud(newValue);
      // setSelectedPrioridad(newValue.prioridad);
    } else{
      setFormData({...formData, solicitud: '', prioridad: ''})
      setSelectedPrioridad(null);
    }
  };

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  const canSave = [formData?.especialidad, formData?.solicitud, formData?.prioridad].every(Boolean) && addRequestStatus === 'idle';
  
  const handleSubmit = async () => {
    console.log(formData);
    if(canSave) {
      try {
        setOpen3(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(updateMotivo(formData)); 
        console.log(resp)
        if(resp.payload.status === 'success'){
            setOpenEdit(false)
            setFormData(data);
        }
      } catch (error) {
        // toast.error('Error al añadir el registro.');
        console.error('Error al añadir el registro', error.message);
      } finally{
        setOpen3(false);
        setAddRequestStatus('idle');
      }
    }
  }

  return (
    <div>
        <Drawer
        open={openEdit}
        anchor='right'
        PaperProps={
          {
            sx: {
              width: { xs: '100%', sm: '75%', md: '40%' },
              backgroundColor:'#efefef',
              color:'#2d3037',
              borderRadius:'0'
            }
          }
      }
        onBackdropClick={() => {
            setOpenEdit(false);
        }}
        >
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open3}
        >
      <CircularProgress color="inherit" />
  </Backdrop>

        <DialogTitle>
          <Grid container={true} spacing={1} px={2}>
              <Grid item  xs={12} sm={12} style={{ display:'flex', gap:'5px'}}>
                  <ErrorIcon style={{ fontSize:'3rem' }} className={styles.iconBg}/>
                  <div style={{ display:'flex', flexDirection:'column' }}>
                      <Typography variant="h6" className={styles.textIconEdit}>Actualizar requerimiento {seleItem?.nombre}</Typography>
                      <Typography variant="span" className={styles.textIconSpan}>Modifica los campos para actualizar el requerimiento.</Typography>
                  </div>
              </Grid>
          </Grid>
        </DialogTitle>     
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={12}>
              {/* SELECT DE ESPECIALIDAD  */} 
              <label className={styles.label}>Especialidad</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color:'#2d3037',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color:'#2d3037',
                                    },
                }}
                disablePortal
                id="campamentos-autocomplete"
                name="campamento"
                disableClearable
                options={Array.isArray(especialidades) && especialidades.length > 0 ? especialidades : []}
                value={selectedEspecialidad || null}
                getOptionLabel={(option) => option}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    sx={{
                      maxHeight: '200px'
                    }}
                    disablePortal 
                  />
                )}
                onChange={(e, value)=> handleChangeEspecialidad(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona una especialidad" />}
              />
            </Grid>
            {especialidades.length > 0 ? (
              <Grid item xs={12} sm={12}>
                <label className={styles.label}>Solicitud</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color:'#2d3037',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color:'#2d3037',
                                    },
                  }}
                  disablePortal
                  disableClearable
                  id="campamentos-autocomplete"
                  name="solicitud"
                  value={selectedSolicitud}
                  options={solicitudes || []}
                  getOptionLabel={(option) => option}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      sx={{
                        maxHeight: '200px',
                      }}
                    />
                  )}
                  onChange={(e, value) => handleChangeSolicitud(e, value)}
                  renderInput={(params) => <TextField {...params} label="Selecciona una solicitud" />}
                />
              </Grid>
            ) :  null}
            
            {especialidades.length > 0  &&
              <Grid item  xs={12} sm={12}>
                <label className={styles.label}>Priodidad</label>
                <Select
                    className={styles.textField}
                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color:'#2d3037',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color:'#2d3037',
                                    },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData?.prioridad}
                    name="prioridad"
                    onChange={(e) => onChange(e)}
                    style={{ width:'100%' }}
                >
                    <MenuItem value="Alta">Alta</MenuItem>
                    <MenuItem value="Media">Media</MenuItem>
                    <MenuItem value="Baja">Baja</MenuItem>
                </Select>
              </Grid>
            }
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Descripción (opcional)</label>
              <TextField
                variant="outlined"
                fullWidth
                name='descripcion'
                type="text"
                multiline
                className={styles.textField}
                sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color:'#2d3037',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color:'#2d3037',
                                    },
                }}
                rows={4}
                value={formData?.descripcion || ''}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding:'0 16px' }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button 
                onClick={handleSubmit}
                className={styles.bgColorEdit}
                variant="contained" 
                disabled={!canSave}
                >
                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
              </Button>
              <Button 
                onClick={()=>setOpenEdit(false)}
                className={styles.bgColorOutlined}
                variant="outlined" 
                >
                Cancelar
              </Button>
            </Grid>          
          </Grid>
        </DialogActions>
      </Drawer>
    </div>
  )
}