import styles from './components.module.css';
import { useEffect, useState } from "react";
import { rotativosTrabajadoresColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const TablePorAsignar = ({ trabajadores, setOpen3, selectedTrabajadores, handleRowSelectionModelChange, selectedHabitacion, habitaciones, filterTrabajadores, setFilterTrabajadores, turnos, setTurnos }) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = rotativosTrabajadoresColumns(setOpen, setSeleItem, setOpenEdit);
    
  useEffect(() => {
    if(selectedHabitacion && selectedHabitacion.habitacion){
      setOpenAlert(true);
      let ruts = new Set();
      habitaciones && habitaciones.map(hab => {
          const trabs = hab.habitacion.reservasCMDIC ? hab.habitacion.reservasCMDIC.map(item => item.trabajador.rut) : [];
          trabs.forEach(rut => ruts.add(rut));
          return trabs;
      });
      ruts = Array.from(ruts);
      console.log('RUTs únicos acumulados: ', ruts);

      const restoDeTurnosEnLaHabitacion = habitaciones.filter(hab => hab.habitacion.id === selectedHabitacion.habitacion.id).map(item => {
        if(item.trabajador){
          return item.trabajador
        } else{
          return null
        }
      }).filter(item => item != null).map(item => item.turno);
      setTurnos(restoDeTurnosEnLaHabitacion);

      const mappedTrabajadores = trabajadores.filter(item => 
        !restoDeTurnosEnLaHabitacion.includes(item.trabajador.turno) 
        && !ruts.includes(item.trabajador.rut)
      );
      setFilterTrabajadores(mappedTrabajadores);
    }else{
      setFilterTrabajadores(trabajadores);
    }

  }, [selectedHabitacion])
  

  return (
    <div style={{ marginTop:'16px' }}>
      <Box sx={{ width: '100%' }}>
        <Collapse in={openAlert}>
            <Alert
            action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                    setOpenAlert(false);
                }}
                >
                <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            sx={{ mb: 2 }}
            >
              <div style={{ display:'flex', flexDirection:'column' }}>
                <span>{filterTrabajadores.length} trabajadores sin habitación y con grupos distintos a los presentes en la habitación.</span>
                <span>Turnos presentes en la habitacion N° {selectedHabitacion && selectedHabitacion.habitacion &&selectedHabitacion.habitacion.numero}: </span>
                <div style={{ display:'flex', flexDirection:'column', padding:'0 10px' }}>
                  {
                    turnos.map(turno => (
                      <span>{turno}</span>
                    ))
                  }
                </div>
              </div>
            </Alert>
        </Collapse>
      </Box>
        <DataGrid
            checkboxSelection={false}  // Deshabilitar selección de múltiples filas
            // rowSelectionModel={selectedTrabajadores.length > 0 ? [selectedTrabajadores[0].trabajador.rut] : []}
            rowSelectionModel={selectedTrabajadores.length > 0 ? [selectedTrabajadores[0].trabajador.id] : []}
            onRowSelectionModelChange={(selection) => {
              const selected = selection.length > 0 ? [selection[0]] : [];
              handleRowSelectionModelChange(selected);
            }}
            // getRowId={row=>row.trabajador.rut ?? Math.random() }
            getRowId={row=>row.trabajador.id ?? Math.random() }
            lots={{ toolbar: GridToolbar }}
            rows={filterTrabajadores}
            columns={finalColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15, 20]}
            localeText={{
              ...esES.components.MuiDataGrid.defaultProps.localeText,
              noRowsLabel: "No hay datos disponibles",
              pagination: {
                  labelRowsPerPage: "Filas por página:",
              }
            }}
            classes={{ menuIcon: styles.menuIcon }}
            style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
            sx={{ 
              '& .MuiDataGrid-cell': {
                color: '#2d3037',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeader': {
                color: '#2d3037',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-footerContainer': {
                color: '#2d3037',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiTablePagination-root': {
                color: '#2d3037',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-toolbarContainer': {
                color: '#2d3037',
              },
              '& .MuiDataGrid-menu': {
                color: '#2d3037',
              },
              '& .MuiSvgIcon-root':{
                color: '#9ca91c',
              },
              '& .MuiTablePagination-actions': {
                color: '#2d3037', // Color for pagination actions
              },
              '& .MuiTablePagination-select': {
                color: '#2d3037', // Color for pagination select
              },
              '& .MuiTablePagination-selectLabel': {
                color: '#2d3037', // Color for pagination select label
              },
              '& .MuiTablePagination-displayedRows': {
                color: '#2d3037', // Color for displayed rows text
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                color: 'red',
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                color: 'red',
              },
            }}
            slotProps={{
                  toolbar:{
                    sx:{
                      color:'#FFF'
                    }
                  },
                  pagination: {
                    sx: {
                      color: '#f19d38',
                    },
                  },
                  menu: {
                    sx: {
                      color: '#f19d38',
                    },
                  },
            }}
        />
    </div>
  )
}

export default TablePorAsignar