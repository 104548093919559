import { Box, Container, Button, Backdrop, CircularProgress } from "@mui/material"
import Header from "../../components/header/Header"
import TableCard from "../../components/tableCard/TableCard";
import SkeletonTable from "../../components/Skeleton";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import CreateArticulo from "./components/CreateArticulo";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchCategorias, fetchInventarios, getAllCategorias, getAllInventarios, getInventariosStatus } from "../../redux/features/inventario/inventarioSlice";

const Inventario = () => {

  const dispatch = useDispatch();
  const inventario = useSelector(getAllInventarios);
  const categorias = useSelector(getAllCategorias);
  const [inventarioActualizado, setInventarioActualizado] = useState([])
  const status = useSelector(getInventariosStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if (status === 'idle') {
    }
    dispatch(fetchInventarios());
    dispatch(fetchCategorias());
    // if (statusCategorias === 'idle') {
    // }
  }, []);
  
  
  useEffect(() => {
    const invt = inventario.map(item => {
      let itemCopia = { ...item };
      const categoria = categorias.find(cat => cat.id === itemCopia.categoriaId);
      itemCopia.categoria = categoria ? categoria.nombre : 'sin categoria';
      return itemCopia;
    });
    setInventarioActualizado(invt);
  }, [inventario, categorias])


  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestíon de artículos"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con el inventario en la plataforma.">
          {<HolidayVillageIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>

        <TableCard>
          <CreateArticulo setOpen2={setOpen3} open2={open3} categorias={categorias}/>
          { status == 'loading' ? <SkeletonTable/> : <Table inventario={inventarioActualizado ? inventarioActualizado : []} setOpen3={setOpen3} categorias={categorias}/>
        }
        </TableCard>
      </Container>
    </Box>
  )
}
export default Inventario