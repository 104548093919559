import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography, Card } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, Fragment } from 'react';

const Show = ({ open, setOpen, seleItem  }) => {
    
useEffect(() => { console.log('seleItem:', seleItem) }, [seleItem]);

  return (
    <Dialog
      open={open}
      onClose={()=>setOpen(false)}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
           backgroundColor:'#efefef',
           color:'#2d3037',
           borderRadius:'0'
        }
      }}
    >
      <DialogTitle>
        <span>Listado de reservas en habitación N°{seleItem ? seleItem.numero : ''}</span>
      </DialogTitle>
      
      <DialogContent>
        <Card style={{ padding:'16px' }}>
          <Grid container={true} spacing={2} style={{ overflowX:'auto' }}>
            {
                (seleItem && seleItem.reservasCMDIC && Array.isArray(seleItem.reservasCMDIC) && seleItem.reservasCMDIC.length > 0 ) ?
                seleItem.reservasCMDIC.map(reg => (
                    <Fragment>
                        <Grid item xs={6}>
                            <span>Trabajador</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span>{reg.trabajador ? reg.trabajador.nombre +' '+reg.trabajador.apellidoPaterno+' '+reg.trabajador.apellidoMaterno : '-'}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span>Rut</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span>{reg.trabajador ? reg.trabajador.rut : '-' }</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span>Inicio</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span>{reg.fechaInicio ? format(reg.fechaInicio, 'dd-MM-yyyy') : '-'}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span>Termino</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span>{reg.fechaTermino ? format(reg.fechaTermino, 'dd-MM-yyyy') : '-'}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span>Turno</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span>{reg.turno ? reg.turno : '-' }</span>
                        </Grid>
                        {seleItem.reservasCMDIC.length > 1 && <hr style={{ color:'rgba(51, 51, 51, 0.11)', height:'2px', width:'100%', marginTop:'10px' }}/>}
                    </Fragment>
                ))
                :<Grid item xs={12}>
                  <span>La habitación no tiene reservas agendadas</span>
                </Grid> 
            }
        </Grid>
        </Card>
      </DialogContent>
      <DialogActions >
        <Button onClick={()=>setOpen(false)} style={{ color:'#2d3037' }}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Show;
