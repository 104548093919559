import styles from './components.module.css'
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import StormIcon from '@mui/icons-material/Storm';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ListItemText, TextField, Typography, Card } from '@mui/material';
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';
import { addDays, differenceInDays, eachDayOfInterval, format, getDay, isValid, parseISO  } from 'date-fns'
import MyCalendar from './CalendarNew';
// import MyCalendar from './Calendar';
import moment from 'moment';
import 'moment/locale/es';
import { addForecast, updateForecast } from '../../../../redux/features/forecast/forecastSlice';
import { DataGrid } from '@mui/x-data-grid';
import { createAxiosInstance } from '../../../../api/axios';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Edit = ({ open2, setOpen2, data, tramos, setTramos, ger, siArea, empresas, contratos, seleItem, setOpen, open, turnos }) => {

  const dispatch = useDispatch();
  const [selectedVice, setSelectedVice] = useState(null);
  const [selectedGer, setSelectedGer] = useState(null);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedTurno, setSelectedTurno] = useState(null);
  const [vicep, setVicep] = useState([]);

  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
    
  const [value, setValue] = useState(dayjs('2022-04-17'));
  const [value2, setValue2] = useState('1');
  const [filteredTramos, setFilteredTramos] = useState([]);
  const [numeroCalendario, setNumeroCalendario] = useState([1]);
  const [check, setCheck] = useState(false);
  const [filterContratos, setFilterContratos] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [search, setSearch] = useState('');

  const handleInputChange = (inputValue) => {
    setSearch(inputValue);
  };

  const changeCalendarNumber = (e) => {
    const count = parseInt(e.target.value);
    if (!isNaN(count)) {
      setNumeroCalendario(prev => {
        if (prev.length < count) {
          return [...Array(count)].map((_, index) => index + 1);
        } else if (prev.length > count) {
          return prev.slice(0, count);
        } else {
          return prev;
        }
      });
    }
  };

  useEffect(() => {
    const getContratos = async () =>{
      try {
        const resp = await axiosInstance.get('/api/v1/Solicitud/Contratos/'+seleItem?.empresa?.idEmpresa);
        setFilterContratos(resp.data);
      }catch(error){
        console.log(error);
      }
    }
    console.log('seleItem:',seleItem)
    if(seleItem){
      setFormData({empresa: seleItem?.empresa, tramos: seleItem?.tramos, id: seleItem?.id})
      // setFilteredTramos(seleItem?.tramos)
      getContratos();
      setTramos({})
      setFilteredTramos(seleItem?.tramos);
    }
  }, [seleItem])

  useEffect(() => {
    if (Array.isArray(ger)) {
      const vp = ger.filter(item => item.startsWith('VP'));
      setVicep(vp);
    }
  }, [ger]);
  
  const formatDate = (dateString) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    if (dateRegex.test(dateString)) {
      return format(new Date(dateString), 'yyyy-MM-dd');
    }
    return dateString;
  };

  
  const handleChange = (e, index) => {
    const { name, value } = e.target;

    // const updatedTramos = [...tramos];

    if (name === '') {
        return;
    } else if (name === 'fechaDesde') {
        const parsedDate = parseISO(value);
        const diaSemana = getDay(parsedDate);
        console.log('Día de la semana:', diaSemana);

        if (diaSemana === 5 || diaSemana === 6 || diaSemana === 0) {
            toast.error("La fecha seleccionada cae en viernes, sábado o domingo.");
            return false;
        } else {
            // if (updatedTramos[index].turno.jornadasLaborales === 5 && updatedTramos[index].turno.jornadasDescanso === 2) {
            if (tramos?.turno?.jornadasLaborales === 5 && tramos?.turno?.jornadasDescanso === 2) {
                if (diaSemana !== 1) {
                  toast.warning("El turno seleccionado solo puede ser programado para los lunes.");
                  // return false;
                }
                // updatedTramos[index]['fechaDesde'] = value;
                // const fechaHasta = addDays(parsedDate, 5);
                // updatedTramos[index]['fechaHasta'] = format(fechaHasta, 'yyyy-MM-dd'); // Formato YYYY-MM-DD
                setTramos(prev => ({...prev, fechaDesde: value }) );
                // setTramos(prev => ({...prev, fechaHasta: format(fechaHasta, 'yyyy-MM-dd') }) );
            // } else if (updatedTramos[index].turno.jornadasLaborales === 4 && updatedTramos[index].turno.jornadasDescanso === 3) {
            } else if (tramos?.turno?.jornadasLaborales === 4 && tramos?.turno?.jornadasDescanso === 3) {
                if (diaSemana !== 1) {
                    toast.warning("El turno seleccionado solo puede ser programado para los lunes.");
                    // return false;
                }
                const fechaHasta = addDays(parsedDate, 4);
                // updatedTramos[index]['fechaDesde'] = value;
                // updatedTramos[index]['fechaHasta'] = format(fechaHasta, 'yyyy-MM-dd'); // Formato YYYY-MM-DD
                setTramos(prev => ({...prev, fechaDesde: value }) );
                // setTramos(prev => ({...prev, fechaHasta: format(fechaHasta, 'yyyy-MM-dd') }) );
            } else {
                // updatedTramos[index]['fechaDesde'] = value;
                setTramos(prev => ({...prev, fechaDesde: value }) );
            }
            // setTramos(updatedTramos);
        }
    } else {
        // updatedTramos[index][name] = value;
        // setTramos(updatedTramos);
        setTramos(prev => ({...prev, [name]: value }) );
    }
};

  
  const handleAgregarTramo = () => {
    const isIn = filteredTramos.findIndex(item => item.id === tramos.id);
  
    if (isIn !== -1) {
      // Actualiza el tramo existente
      const updatedTramos = filteredTramos.map((item, index) => 
        index === isIn ? { ...item, ...tramos } : item
      );
      setFilteredTramos(updatedTramos);
      setActualizar(false);
    } else {
      // Agrega un nuevo tramo con un ID generado
      const tramoWithId = {
        ...tramos,
        id: uuidv4()
      };
      setFilteredTramos([...filteredTramos, tramoWithId]);
      setActualizar(false);
    }
  
    // Limpia el estado de 'tramos'
    setTramos({
      comentarios: "",
      contrato: "",
      fechaDesde: "",
      fechaHasta: "",
      gerencia: "",
      nombre: "",
      siArea: "",
      trabajadoresDia: 0,
      trabajadoresNoche: 0,
    });
  };
  

  useEffect(() => {    
    // console.log('tramos',tramos)
  }, [tramos])
  
  useEffect(() => {
    // console.log('filteredTramos',filteredTramos);
    setFormData(prev => ({...prev, tramos: filteredTramos}))
  }, [filteredTramos])

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });
  const axiosInstance = createAxiosInstance();

  const handleChangeEmpresa = async (event, newValue, index) => {
    if(newValue != null){
      try {
        setFormData({...formData, empresa: newValue});
        const resp = await axiosInstance.get('/api/v1/Solicitud/Contratos/'+newValue.idEmpresa);
        setFilterContratos(resp.data);
      } catch (error) {
        console.log('Error al traer contratos:', error.message);
      }
    } else{
      setFormData({...formData, empresa: 0})
    }
    
  };
  const handleChangeContrato = (event, newValue, index) => {
    if(newValue != null){
      setSelectedEmp(newValue)
      // const updatedTramos = [...tramos];
      // updatedTramos[index]['contrato'] = newValue;
      // setTramos(updatedTramos);
      setTramos(prev => ({...prev, contrato: newValue }) );
      setFormData({...formData, contrato: newValue});
    } else{
      setFormData({...formData, contrato: 0})
    }
    
  };
  const handleChangeTurno = (event, newValue, index) => {
    if(newValue != null){
      setSelectedTurno(newValue)
      // const updatedTramos = [...tramos];
      // updatedTramos[index]['turno'] = newValue;
      // updatedTramos[index]['fechaDesde'] = '';
      // updatedTramos[index]['fechaHasta'] = '';
      // setTramos(updatedTramos);
      setTramos(prev => ({...prev, turno: newValue, fechaDesde: '', fechaHasta: '' }) );
      setFormData({...formData, turno: newValue, fechaDesde: '', fechaHasta: ''});
    } else{
      setFormData({...formData, turno: 0})
    }
    
  };
  const handleChangeVice = (event, newValue, index) => {
    if(newValue != null){
      // const updatedTramos = [...tramos];
      // updatedTramos[index]['vicepresidencia'] = newValue;
      // setTramos(updatedTramos);
      setTramos(prev => ({...prev, vicepresidencia: newValue}) );
    }
  };
  const handleChangeGerencia = (event, newValue, index) => {
    if(newValue != null){
      setSelectedGer(newValue)
      // const updatedTramos = [...tramos];
      // updatedTramos[index]['gerencia'] = newValue;
      // setTramos(updatedTramos);
      setFormData({...formData, gerencia: newValue});
      setTramos(prev => ({...prev, gerencia: newValue }) );
    } else{
      setFormData({...formData, gerencia: 0})
    }
    
  };
  const handleChangeArea = (event, newValue, index) => {
    if(newValue != null){
      setSelectedArea(newValue)
      // const updatedTramos = [...tramos];
      // updatedTramos[index]['siArea'] = newValue;
      // setTramos(updatedTramos);
      setFormData({...formData, area: newValue});
      setTramos(prev => ({...prev, siArea: newValue }) );
    } else{
      setFormData({...formData, area: 0})
    }
    
  };

  const requiredProperties = [
    "nombre",
    "vicepresidencia",
    "gerencia",
    "siArea",
    "contrato",
    // "trabajadoresDia",
    // "trabajadoresNoche",
    "fechaDesde",
    "fechaHasta",
    // "comentarios"
  ];

  useEffect(() => {
    const allValid = formData.tramos.every(item =>
      requiredProperties.every(prop => item.hasOwnProperty(prop))
    );
    
    if (allValid) {
      setCheck(true)      
    } else {
      setCheck(false)
    }
  }, [formData.tramos])
  

  const canSave = [check, formData.empresa.idEmpresa].every(Boolean) && addRequestStatus === 'idle';

  const handleEliminarTramo = () => {
    const tramosFilteres = filteredTramos.filter(item => item.id !== tramos.id)
    setTramos({});
    setFilteredTramos(tramosFilteres)
    setActualizar(false);
  };
  
  const inputRefs = useRef([]);
//-------------------- NUEVO RENDER -------------------------------
// Función para crear una instancia de Date si la fecha es válida

const calcularTrabajadoresPorDia = () => {
  const trabajadoresPorDia = {};

  Array.isArray(filteredTramos) && filteredTramos.forEach(proyecto => {
    const fechaInicio = proyecto.fechaDesde;
    const fechaTermino = proyecto.fechaHasta;
    const trabajadoresDia = parseInt(proyecto.trabajadoresDia);
    const trabajadoresNoche = parseInt(proyecto.trabajadoresNoche);

    for (let fecha = new Date(fechaInicio); fecha <= new Date(fechaTermino); fecha.setDate(fecha.getDate() + 1)) {
      const fechaString = fecha.toISOString().split('T')[0];
      if (!trabajadoresPorDia[fechaString]) {
        trabajadoresPorDia[fechaString] = 0;
      }
      trabajadoresPorDia[fechaString] += trabajadoresDia + trabajadoresNoche;
    }
  });

  return trabajadoresPorDia;
};

const trabajadoresPorDiaFunct = calcularTrabajadoresPorDia();

const canAdd =  [
  // tramos.comentarios,
  tramos.contrato,
  tramos.fechaDesde,
  tramos.fechaHasta,
  tramos.gerencia,
  tramos.nombre,
  tramos.siArea,
  // tramos.trabajadoresDia,
  // tramos.trabajadoresNoche
 ].every(Boolean) && addRequestStatus === 'idle';



const handleSubmit = async () => {
  if(canSave) {
    try {
      setOpen2(true);
      setAddRequestStatus('loading');
      let resp = await dispatch(updateForecast(formData));
      if(resp.payload.status){
        setFormData(data);
        setFilteredTramos([]);
        setOpen(false);
        return false;
      }
      console.log(tramos);
    } catch (error) {
      console.error('Error al crear el registro', error.message);
    } finally{
      setAddRequestStatus('idle');
      setOpen2(false);
      
    }
  }
}

// CODIGO NUEVO PARA CORREGIR LA TAsBLA DE OCUPACION DIARIA
const [rowsNew, setRowsNew] = useState([]);
const [columnNew, setColumnNew] = useState([]);

useEffect(() => {
  if (filteredTramos.length <= 0 || !check) {
    // console.log('entra aqui');
    setRowsNew([]);
    setColumnNew([]);
    return;
  }

  // console.log('filteredTramos: ', filteredTramos);
  // Encontrar la fecha mínima (fechaDesde) y máxima (fechaHasta) en todos los registros
  let minFechaDesde = parseISO(filteredTramos[0].fechaDesde);
  let maxFechaHasta = parseISO(filteredTramos[0].fechaHasta);

  Array.isArray(filteredTramos) && filteredTramos.forEach(item => {
    const fechaDesde = parseISO(item.fechaDesde);
    const fechaHasta = parseISO(item.fechaHasta);

    if (fechaDesde < minFechaDesde) {
      minFechaDesde = fechaDesde;
    }
    if (fechaHasta > maxFechaHasta) {
      maxFechaHasta = fechaHasta;
    }
  });

  // Crear un array de fechas únicas desde minFechaDesde hasta maxFechaHasta
  const fechas = eachDayOfInterval({ start: minFechaDesde, end: maxFechaHasta });

  // Crear las columnas dinámicas basadas en las fechas encontradas
  const columns = [
    { field: 'nombre', headerName: '-', width: 150, disableColumnMenu: true },
    ...fechas.map(fecha => ({
      field: format(fecha, 'dd-MM-yyyy'),
      headerName: format(fecha, 'dd-MM-yyyy'),
      width: 130,
      disableColumnMenu: true, // Desactiva el menú de columna
      // editable: false, // Opcional: puedes permitir la edición si es necesario
    }))
  ];
  setColumnNew(columns);

  // Preparar las filas con los cálculos necesarios para cada fecha
  const row = { id: 0, nombre: 'Totales' };

  fechas.forEach(fecha => {
    const fechaActual = format(fecha, 'dd-MM-yyyy');
    let totalTrabajadoresDia = 0;
    let totalTrabajadoresNoche = 0;

    filteredTramos.forEach(item => {
      const fechaDesde = parseISO(item.fechaDesde);
      const fechaHasta = parseISO(item.fechaHasta);

      const jornadasLaborales = item.turno ? item?.turno.jornadasLaborales : 0;
      const jornadasDescanso = item.turno ? item?.turno.jornadasDescanso : 0;
      const cicloTotal = jornadasLaborales + jornadasDescanso;

      // Calcular la diferencia de días desde el inicio del tramo
      const diferenciaDias = differenceInDays(fecha, fechaDesde);
      const posicionEnCiclo = diferenciaDias % cicloTotal;

      if (posicionEnCiclo < jornadasLaborales) {
        if (fecha >= fechaDesde && fecha <= fechaHasta) {
          totalTrabajadoresDia += parseInt(item.trabajadoresDia) || 0;
          totalTrabajadoresNoche += parseInt(item.trabajadoresNoche) || 0;
        }
      }
    });

    const totalTrabajadores = totalTrabajadoresDia + totalTrabajadoresNoche;
    row[fechaActual] = totalTrabajadores.toString();
  });

  setRowsNew([row]);
}, [filteredTramos, check]);

  const options = Array.isArray(vicep) ? vicep.map((item) => ({ value: item, label: item })) : [];
  const gerOptions = Array.isArray(ger) ? ger.map(item => ({ value: item, label: item })) : [];
  const siAreaOptions = Array.isArray(siArea) ? siArea.map(item => ({ value: item, label: item })) : [];
  
  const currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 48);
  const minDate = currentDate.toISOString().split('T')[0];
  
  // Calcula la fecha máxima permitida para "fechaDesde" (2 días hábiles después de la fecha mínima)
  const maxDate = new Date(minDate);
  maxDate.setDate(maxDate.getDate() + 2);
  const maxDateStr = maxDate.toISOString().split('T')[0];

  return (
    <div>
      <Dialog
        // fullScreen
        open={open}
        maxWidth="lg"
        PaperProps={{
          sx: {
           backgroundColor:'#efefef',
           color:'#2d3037',
           borderRadius:'0'
          }
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          // setOpen(false);
        }}
      >
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open2}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <DialogContent>
          <DialogTitle style={{ display:'flex', gap:'5px', margin:'0', padding:'0'}}>
              <StormIcon style={{ fontSize:'3rem', marginTop:'0px' }} className={styles.iconBg} />
              <div style={{ display:'flex', flexDirection:'column' }}>
                <Typography variant='span' style={{ fontSize:'24px', marginBottom:'0', lineHeight:'1'  }}>Actualizar forecast</Typography>
                <span className={styles.subTitle}>Modifica los siguientes campos para actualizar el registro.</span>
              </div>
            </DialogTitle>
            <Grid container={true} spacing={1}>
              <Grid item  xs={12} sm={4} style={{ display:'flex', gap:'10px', paddingTop:'27px', flexDirection:'column' }} mt={2} mb={2} >
              <div style={{ display:'flex', justifyContent:'start', alignItems:'end', gap:'10px' }}>
                <div style={{ display:'flex', flexDirection:'column', width:'100%' }}>
                  <label className={styles.label}>N° de meses</label>
                  <TextField
                    className={[styles.textField, styles.paddingCero]}
                    style={{ width:'100%' }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                        width: '100%',
                      '@media (min-width: 600px)': {
                        width: '30%',
                      },
                      }}
                    variant="outlined"
                    name='buscar'
                    type="number"
                    defaultValue={1}
                    placeholder='N° de meses'
                    onChange={(e) => changeCalendarNumber(e)}
                  />
                </div>
              </div>

              {/*{filteredTramos.length > 0 &&   */}
                <div style={{ width:'100%' }}>
                  <label className={styles.label}>Empresa</label>
                  <Select
                    className={styles.textField}
                    options={empresas}
                    value={empresas.find(option => option.razonSocial === formData?.empresa.razonSocial) || null}
                    onChange={(selectedOption) => handleChangeEmpresa(null, selectedOption)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF', // Fondo azul solo para el input
                        border: 'none',
                        color: '#2d3037', // Texto blanco solo para el input
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#2d3037', // Texto blanco para el valor seleccionado
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: '#2d3037', // Texto blanco para el input
                        padding: '11px'
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF', // Fondo blanco para la lista de resultados
                        color: '#000', // Texto negro para la lista de resultados
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? '#f5f5f5' : '#FFF', // Fondo azul al pasar el ratón y blanco por defecto
                        color:'#000',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: '#FFF', // Color del placeholder
                      }),
                    }}
                    getOptionLabel={(option) => option.razonSocial}
                    getOptionValue={(option) => option.id}
                  />
                </div>
              {/*} */}
              </Grid>
               <Grid item  xs={12} sm={8} style={{ display:'flex', flexDirection:'column' }}>
              </Grid>
              {/* 
              {JSON.stringify(filteredTramos)}
            */} 
              <Grid item  xs={12} sm={4} >
            {/* {filteredTramos.map((tramo, index) => ( */}
                <>
                <div style={{ display:'flex', gap:'5px' }}>
                  <div style={{ width: '50%' }}>
                    <label className={styles.label}>Nombre</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      value={tramos.nombre || ''}
                      name='nombre'
                      type="text"
                      placeholder='Nombre del tramo'
                      onChange={(e) => handleChange(e)}
                      // inputRef={ el => inputRefs.current[index] = el } // Asigna una referencia al input
                    />
                  </div>
                  <div style={{ width: '50%' }}>
                    <label className={styles.label}>Vicepresidencia</label>
                    <Select
                      className={styles?.textField}
                      options={options}
                      value={options.find(option => option.value === tramos?.vicepresidencia) || null}
                      onChange={(selectedOption) => handleChangeVice(null, selectedOption?.value)}
                      onInputChange={handleInputChange}
                      styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF', // Fondo azul solo para el input
                        border: 'none',
                        color: '#2d3037', // Texto blanco solo para el input
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#2d3037', // Texto blanco para el valor seleccionado
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: '#2d3037', // Texto blanco para el input
                        padding: '11px'
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF', // Fondo blanco para la lista de resultados
                        color: '#000', // Texto negro para la lista de resultados
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? '#f5f5f5' : '#FFF', // Fondo azul al pasar el ratón y blanco por defecto
                        color:'#000',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: '#FFF', // Color del placeholder
                      }),
                      }}
                    />
                  </div>
                </div>
                <div style={{ display:'flex', gap:'5px' }}>
                  <div style={{ width: '50%' }}>
                    <label className={styles.label}>Gerencia</label>
                    <Select
                      className={styles.textField}
                      options={gerOptions}
                      value={gerOptions.find(option => option.value === tramos?.gerencia) || null}
                      onChange={(selectedOption) => handleChangeGerencia(null, selectedOption?.value)}
                      onInputChange={handleInputChange}
                      styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF', // Fondo azul solo para el input
                        border: 'none',
                        color: '#2d3037', // Texto blanco solo para el input
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#2d3037', // Texto blanco para el valor seleccionado
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: '#2d3037', // Texto blanco para el input
                        padding: '11px'
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF', // Fondo blanco para la lista de resultados
                        color: '#000', // Texto negro para la lista de resultados
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? '#f5f5f5' : '#FFF', // Fondo azul al pasar el ratón y blanco por defecto
                        color:'#000',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: '#FFF', // Color del placeholder
                      }),
                      }}
                    />
                  </div>
                    <div style={{ width:'50%' }}>
                      <label className={styles.label}>SI-Área</label>
                      <Select
                        className={styles.textField}
                        options={siAreaOptions}
                        value={siAreaOptions.find(option => option.value === tramos?.siArea) || null}
                        onChange={(selectedOption) => handleChangeArea(null, selectedOption?.value)}
                        onInputChange={handleInputChange}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: '#FFF', // Fondo azul solo para el input
                            border: 'none',
                            color: '#2d3037', // Texto blanco solo para el input
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: '#2d3037', // Texto blanco para el valor seleccionado
                          }),
                          input: (provided) => ({
                            ...provided,
                            color: '#2d3037', // Texto blanco para el input
                            padding: '11px'
                          }),
                          menu: (provided) => ({
                            ...provided,
                            backgroundColor: '#FFF', // Fondo blanco para la lista de resultados
                            color: '#000', // Texto negro para la lista de resultados
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? '#f5f5f5' : '#FFF', // Fondo azul al pasar el ratón y blanco por defecto
                            color:'#000',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#FFF', // Color del placeholder
                          }),
                        }}
                      />
                    </div>
                  </div>
                <div style={{ display:'flex', gap:'5px' }}>
                  <div style={{ width:'100%' }}>
                    <label className={styles.label}>Contrato</label>
                    <Autocomplete
                        disablePortal
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                        disableClearable
                        id="campamentos-autocomplete"
                        options={filterContratos || []}
                        name="contrato"
                        value={tramos?.contrato || null}
                        getOptionLabel={(option) => option}
                        onChange={(e, value)=> handleChangeContrato(e, value)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                  </div>
                  <div style={{ width:'100%' }}>
                      <label className={styles.label}>Turnos</label>
                      <Autocomplete
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                        disablePortal
                        disableClearable
                        id="campamentos-autocomplete"
                        options={turnos || []}
                        name="turno"
                        value={tramos?.turno || null}
                        getOptionLabel={(option) => option.descripcion}
                        onChange={(e, value)=> handleChangeTurno(e, value)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                  </div>
                  </div>
                  <div style={{ display:'flex', gap:'5px' }}>
                    <div>
                      <label className={styles.label}>Desde</label>
                      <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        value={tramos.fechaDesde || ''}
                        name='fechaDesde'
                        InputProps={{ inputProps: { min: minDate, max: maxDate } }}
                        type="date"
                        onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div>
                      <label className={styles.label}>Hasta</label>
                      <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        value={tramos.fechaHasta || ''}
                        disabled={!tramos?.turno }
                        name='fechaHasta'
                        InputProps={{ inputProps: { min: tramos.fechaDesde || '' } }}
                        type="date"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div style={{ display:'flex', gap:'5px' }}>
                    <div>
                      <label className={styles.label}>N°Trabajadores día</label>
                      <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        name='trabajadoresDia'
                        value={tramos.trabajadoresDia || ''}
                        InputProps={{ inputProps: { min: 0 } }}
                        type="number"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div>
                      <label className={styles.label}>N°Trabajadores noche</label>
                      <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        name='trabajadoresNoche'
                        value={tramos.trabajadoresNoche || ''}
                        InputProps={{ inputProps: { min: 0 } }}
                        type="number"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    </div>
                    <div style={{ width:'100%' }}>
                      <label className={styles.label}>Comentarios</label>
                      <TextField
                        className={styles.textField}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                        }}
                        variant="outlined"
                        fullWidth
                        value={tramos.comentarios || ''}
                        name='comentarios'
                        type="text"
                        placeholder='Ingrese un comentario sobre el tramo'
                        onChange={(e) => handleChange(e)}
                        // // inputRef={ el => inputRefs.current[index] = el }
                      />
                  </div>
                  <div style={{ marginTop:'10px' }}>
                    <Button 
                      className={ !actualizar ? styles.bgColorOrange : styles.bgColorBlue } 
                      variant="fulled" 
                      disabled={!canAdd}
                      onClick={handleAgregarTramo}
                      style={{ fontSize:'12px', height:'50px', width: !actualizar ? '100%' : '80%',marginRight: !actualizar ? '0' : '5px'  }}>
                      { !actualizar ? 'Agregar tramo' : 'Actualizar tramo'}
                    </Button>
                    { actualizar &&
                      <Button 
                        className={styles.bgColorDelete}
                        variant="outlined"
                        style={{ width:'5%', fontSize:'12px', height:'50px' }}
                        onClick={() => handleEliminarTramo()}
                      >
                        <DeleteIcon/>
                      </Button>
                    }
                  </div>

                  </>
                
                  {/*)) } */}
                </Grid>

              {
                numeroCalendario.map((item, index)=> (
                  <Grid item xs={12} sm={numeroCalendario.length == 1 ? 8 : 4} className={  styles.relativeContainer}>
                    <Card style={{ backgroundColor:'#FFF', color:'var(--textfield-bg)', padding:'10px' }}>
                    <MyCalendar events={filteredTramos || []} trabajadoresPorDia={trabajadoresPorDiaFunct} inputRefs={inputRefs} setTramos={setTramos} setActualizar={setActualizar} defaultDate={moment().add(index, 'month').toDate()}/>
                    </Card>
                  </Grid>
                ))
              }
              <Grid item xs={12} sm={12}>
                    <label className={styles.label} style={{ fontWeight:'500' }}>Notas</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='notas'
                      type="text"
                      multiline
                      rows={4}
                      onChange={(e) => setFormData(prev => ({...prev, notas: e.target.value}))}
                    />
              </Grid>              
              { rowsNew.length > 0 && 
                <Grid item xs={12} sm={12} style={{ displey:'flex', flexDirection:'column', marginTop:'10px', backgroundColor:'#FFF' }}>  
                  <label style={{ fontSize:'16px', fontWeight:'500' }} className={styles.label}>Trabajadores totales por dia:</label>
                  <DataGrid
                    rows={rowsNew}
                    columns={columnNew}
                    pageSize={5} // Tamaño de la página
                    rowsPerPageOptions={[5, 10, 20]} // Opciones de tamaño de página
                    autoHeight // Altura automática
                    sx={{ 
                      '& .MuiDataGrid-cell': {
                        color: '#2d3037',
                        borderBottom: '1px solid #c6c6c6',
                      },
                      '& .MuiDataGrid-columnHeader': {
                        color: '#2d3037',
                        borderBottom: '1px solid #c6c6c6',
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        borderBottom: '1px solid #c6c6c6',
                      },
                      '& .MuiDataGrid-footerContainer': {
                        color: '#2d3037',
                        borderTop: '1px solid #c6c6c6',
                      },
                      '& .MuiTablePagination-root': {
                        color: '#2d3037',
                        borderTop: '1px solid #c6c6c6',
                      },
                      '& .MuiDataGrid-toolbarContainer': {
                        color: '#2d3037',
                      },
                      '& .MuiDataGrid-menu': {
                        color: '#2d3037',
                      },
                      '& .MuiTablePagination-actions': {
                        color: '#2d3037', // Color for pagination actions
                      },
                      '& .MuiTablePagination-select': {
                        color: '#2d3037', // Color for pagination select
                      },
                      '& .MuiTablePagination-selectLabel': {
                        color: '#2d3037', // Color for pagination select label
                      },
                      '& .MuiTablePagination-displayedRows': {
                        color: '#2d3037', // Color for displayed rows text
                      },
                    }}
                    slotProps={{
                      toolbar: {
                        sx: {
                          color: '#2d3037',
                        },
                      },
                      pagination: {
                        sx: {
                          color: '#2d3037',
                        },
                      },
                      menu: {
                        sx: {
                          color: '#2d3037',
                        },
                      },
                    }}
                    classes={{ menuIcon: styles.menuIcon }}
                    style={{ maxHeight:'500px', color:'#FFF', }}
                  /> 
                </Grid>
              }
            </Grid>
              
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding:'0 16px' }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button 
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained" 
                disabled={!canSave}
                >
                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
              </Button>
              <Button 
                onClick={()=>setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined" 
                >
                Cancelar
              </Button>
            </Grid>          
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Edit