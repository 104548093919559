import styles from './components.module.css';
import Drawer from '@mui/material/Drawer';
import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { updateSector } from '../../../../redux/features/sectores/sectorSlice';

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(null);
    const dispatch = useDispatch();
    
    useEffect(() => {
            setFormData(seleItem)
    }, [seleItem])
 
        
    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

    const canSave = [formData?.nombre].every(Boolean) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            let resp = await dispatch(updateSector(formData))
            if(resp.payload.status === 'success'){
                setOpenEdit(false);
            }
        } catch (error) {
            console.error('Error al actualizar el sector', error.message);
        } finally{
            setAddRequestStatus('idle');
            setOpen3(false);
        }
    }

  return (
      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        PaperProps={
            {
              sx: {
                width: { xs: '100%', sm: '75%', md: '50%' },
                backgroundColor:'#efefef',
                color:'#2d3037',
                borderRadius:'0'
              }
            }
        }
      >
        {seleItem && (
            <>
                <DialogTitle>
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={12} style={{ display:'flex', gap:'5px'}}>
                            <ErrorIcon style={{ fontSize:'3rem' }} className={styles.iconBg}/>
                            <div style={{ display:'flex', flexDirection:'column' }}>
                                <Typography variant="h6" className={styles.textIconEdit}>Actualizar sector {seleItem?.nombre}</Typography>
                                <Typography variant="span" className={styles.textIconSpan}>Modifica los campos para actualizar el sector.</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={12}>
                            <label className={styles.label}>Nombre</label>
                            <TextField
                                className={styles.textField}
                                sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                    border: 'none',
                                    },
                                    '&:hover fieldset': {
                                    border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                    border: 'none',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color:'#2d3037',
                                },
                                '& .MuiInputLabel-root': {
                                    color:'#2d3037',
                                },
                                }}
                                variant="outlined"
                                fullWidth
                                name='nombre'
                                type="text"
                                value={formData?.nombre}
                                onChange={(e) => onChange(e)}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={12}>
                            <label className={styles.label}>Descripción (opcional)</label>
                            <TextField
                                className={styles.textField}
                                sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                    border: 'none',
                                    },
                                    '&:hover fieldset': {
                                    border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                    border: 'none',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color:'#2d3037',
                                },
                                '& .MuiInputLabel-root': {
                                    color:'#2d3037',
                                },
                                }}
                                variant="outlined"
                                fullWidth
                                name='descripcion'
                                type="text"
                                value={formData?.descripcion}
                                multiline
                                rows={4}
                                maxRows={4}
                                onChange={(e) => onChange(e)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container={true}>
                        <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                            <Button 
                                onClick={handleEdit}
                                className={styles.bgColorEdit}
                                variant="contained" 
                                disabled={!canSave}
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                            </Button>
                            <Button 
                                onClick={()=>setOpenEdit(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>    
                    </Grid>
                </DialogActions>
            </>
        )}
      </Drawer>
  );
}