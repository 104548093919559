import styles from "./components/components.module.css";
import {
  Grid,
  Backdrop,
  Box,
  Card,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import TextsmsIcon from "@mui/icons-material/Textsms";
import Tab from "@mui/material/Tab";
import Header from "../../../components/header/Header";
import TableCard from "../../../components/tableCard/TableCard";
import Table from "./components/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SkeletonTable from "../../../components/Skeleton";
import { useState } from "react";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import {
  fetchOficinas,
  fetchOficinasDisponibles,
  getAllOficinas,
  getAllOficinasDisponibles,
  getOficinasDisponiblesStatus,
  getOficinasStatus,
} from "../../../redux/features/oficinas/oficinaSlice";
import TableOficinas from "./components/TableOficinas";
import {
  fetchCampamentos,
  getAllCampamentos,
  getCampamentosStatus,
} from "../../../redux/features/campamentos/campamentoSlice";
import {
  fetchWings,
  getAllWings,
  getWingsStatus,
} from "../../../redux/features/wings/wingSlice";
import {
  fetchEdificios,
  getAllEdificios,
  getEdificiosStatus,
} from "../../../redux/features/edificios/edificioSlice";
import {
  fetchOtros,
  getAllOtros,
  getOtrosStatus,
} from "../../../redux/features/otros/otroSlice";
import Create from "../oficinas/components/Create";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const GestionOficina = () => {
  const dispatch = useDispatch();
  const oficinas = useSelector(getAllOficinas);

  const campamentos = useSelector(getAllCampamentos);
  const camStatus = useSelector(getCampamentosStatus);
  const wings = useSelector(getAllWings);
  const WingsStatus = useSelector(getWingsStatus);
  const pabellones = useSelector(getAllEdificios);
  const pabStatus = useSelector(getEdificiosStatus);
  const otros = useSelector(getAllOtros);
  const otrosStatus = useSelector(getOtrosStatus);

  const status = useSelector(getOficinasStatus);
  const [count, setCount] = useState(0);
  const [open2, setOpen2] = useState(false);
  const [openOficina, setOpenOficina] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [check, setCheck] = useState(0);
  const oficinasDisponibles = useSelector(getAllOficinasDisponibles);
  const ofStatus = useSelector(getOficinasDisponiblesStatus);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (camStatus == "idle") {
      dispatch(fetchCampamentos());
    }
    if (WingsStatus == "idle") {
      dispatch(fetchWings());
    }
    if (pabStatus == "idle") {
      dispatch(fetchEdificios());
    }
    if (otrosStatus == "idle") {
      dispatch(fetchOtros());
    }
  }, []);

  useEffect(() => {
    dispatch(fetchOficinas());
    if (ofStatus === "idle") {
      dispatch(fetchOficinasDisponibles());
    }
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Gestión de oficinas"
          subtitle="En este módulo, aquí podrás visualizar, asignar, liberar e inhabilitar oficinas registradas en la plataforma."
        >
          {<BookmarkAddIcon style={{ fontSize: "1.5rem" }} />}
        </Header>

        <TableCard>
          {/*<Create setOpen2={setOpen2} open2={open2} campamentos={campamentos} wings={wings} pabellones={pabellones} otros={otros}/> */}
          {/*campamentos={campamentos} wings={wings} pabellones={pabellones} otros={otros}  */}
          {/*<Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            style={{ borderRight: "none", marginBottom: "10px" }}
            sx={{
              borderRight: 1,
              borderColor: "divider",
              "& .MuiTabs-indicator": {
                backgroundColor: "#FFF",
                height: 3,
              },
              "& .MuiTab-root.Mui-selected": {
                color: "#2d3037",
              },
              "& .MuiTab-root:not(.Mui-selected)": {
                color: "gray",
              },
              "& .MuiTabs-root": {
                borderRight: "none",
              },
            }}
          >
            <Tab label="Gestíon" {...a11yProps(0)} />
          </Tabs>
          <CustomTabPanel
            value={value}
            index={0}
            style={{ padding: "0", margin: "0" }}
          > */}
          {status == "loading" ? (
            <SkeletonTable />
          ) : (
            <>
              <Create
                setOpen2={setOpenOficina}
                open2={openOficina}
                campamentos={campamentos}
                wings={wings}
                pabellones={pabellones}
                otros={otros}
              />
              <Table
                setOpen2={setOpen2}
                open2={open2}
                registros={oficinas}
                setOpen3={setOpen3}
                setCheck={setCheck}
                check={check}
              />
            </>
          )}
          {/* </CustomTabPanel>
         <CustomTabPanel
           value={value}
           index={1}
           style={{ padding: "0", margin: "0" }}
         >
           {ofStatus == "loading" ? (
             <SkeletonTable />
           ) : (
             <TableOficinas
               oficinas={oficinasDisponibles.data || []}
               addRequestStatus={addRequestStatus}
               setOpen3={setOpen4}
               open3={open4}
             />
           )}
         </CustomTabPanel> */}
        </TableCard>
      </Container>
    </Box>
  );
};

export default GestionOficina;
