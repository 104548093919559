import styles from "./components/components.module.css";
import { Container, Box, Card, CardContent, Button } from "@mui/material";
import LoginForm from "./components/LoginForm";
import { useEffect, useState } from "react";
import WorkerTypeForm from "./WorkType";
import SSO from "./SSO";

const Login = () => {
  const [workerType, setWorkerType] = useState(null);

  useEffect(() => {
    const storedWorkerType = localStorage.getItem("tipo_trabajador");
    if (storedWorkerType) {
      setWorkerType(storedWorkerType);
    }
  }, []);

  const handleWorkerTypeRegister = () => {
    const storedWorkerType = localStorage.getItem("tipo_trabajador");
    setWorkerType(storedWorkerType);
  };

  const toggleWorkerType = () => {
    const newWorkerType = workerType === "ESED" ? "CMDIC" : "ESED";
    localStorage.setItem("tipo_trabajador", newWorkerType);
    setWorkerType(newWorkerType);
  };

  return (
    <div className="w-full d-flex align-items-center justify-content-center h-full">
      <Box className="h-100 login_box d-flex align-items-center justify-content-center">
        <Container maxWidth="xs" style={{ display: "flex" }}>
          <Card
            style={{
              boxShadow: "0px 10px 20px 2px rgba(0, 0, 0, 0.25)",
              borderRadius: "6px",
              // paddingTop: "20px",
              minHeight: "550px",
            }}
            className={styles.cardBgBlur}
          >
            <CardContent className="text-start" style={{ padding: "0"}}>
              <img
                src={process.env.PUBLIC_URL + "/logo_black.png"}
                alt="loginImg"
                width={100}
                height={100}
                style={{ objectFit: "contain", background: "#1b401d",padding: "20px", marginBottom: "4px" }}
              />
            </CardContent>
            <CardContent>
              {/*
                {workerType ? (
                workerType === "ESED" ? (
                  <>
                   
              */}
              <LoginForm />
              {/*      <Button
                      onClick={toggleWorkerType}
                      className={styles.bgColorOutlined}
                      style={{
                        width: "100%",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                      }}
                    >
                      Cambiar a CMDIC
                    </Button>
                  </>
                ) : (
                  <>
                    <SSO />
                    <Button
                      onClick={toggleWorkerType}
                      className={styles.bgColorOutlined}
                      style={{
                        width: "100%",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                      }}
                    >
                      Cambiar a ESED
                    </Button>
                  </>
                )
              ) : (
                <WorkerTypeForm onRegister={handleWorkerTypeRegister} />
              )} */}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </div>
  );
};

export default Login;
