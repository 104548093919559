import styles from "./components.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Popper,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { addNewMotivo } from "../../../../redux/features/motivos/motivoSlice";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import CircleIcon from "@mui/icons-material/Circle";

const criticidad = [
  {
    id: 1,
    nombre: "Red de Agua Potable",
    options: [
      { id: 1, solicitud: "Filtración en WC", prioridad: "Alta" },
      { id: 2, solicitud: "Filtración en Ducha", prioridad: "Alta" },
      { id: 3, solicitud: "Filtración en Lavamanos", prioridad: "Alta" },
      {
        id: 4,
        solicitud: "Problemas en estanque de W.C. (Cadena, flapper o Manilla)",
        prioridad: "Alta",
      },
      { id: 5, solicitud: "Reparación/Cambio de Tapa WC", prioridad: "Baja" },
      {
        id: 6,
        solicitud: "Filtración de Agua en Pasillo de Pabellón",
        prioridad: "Alta",
      },
      {
        id: 7,
        solicitud: "Reparación de Llave Mono mando Lavamanos",
        prioridad: "Media",
      },
      {
        id: 8,
        solicitud: "Reparación de Llave Mono mando de Ducha",
        prioridad: "Alta",
      },
      { id: 9, solicitud: "Reparación de Ducha Teléfono", prioridad: "Media" },
      {
        id: 10,
        solicitud: "Reparación de Challa de ducha (salida de agua)",
        prioridad: "Baja",
      },
      { id: 11, solicitud: "Reparación de Brazo de ducha", prioridad: "Alta" },
      {
        id: 12,
        solicitud: "Sin suministro de agua fría en ducha",
        prioridad: "Alta",
      },
      {
        id: 13,
        solicitud: "Sin suministro de agua fría en lavamanos",
        prioridad: "Alta",
      },
      {
        id: 14,
        solicitud:
          "Problemas en llaves, sin suministro de agua caliente en ducha",
        prioridad: "Alta",
      },
      {
        id: 15,
        solicitud:
          "Problemas en llaves, sin suministro de agua caliente en Lavamanos",
        prioridad: "Alta",
      },
      { id: 16, solicitud: "Filtración exterior Pabellón", prioridad: "Alta" },
      {
        id: 17,
        solicitud: "Variación en Presión de Agua Fría/Caliente",
        prioridad: "Alta",
      },
      { id: 18, solicitud: "Rejillas varias", prioridad: "Media" },
    ],
  },
  {
    id: 2,
    nombre: "Red de Gas de Baja Presión",
    options: [
      { id: 1, solicitud: "Normalizar Red de Gas", prioridad: "Alta" },
      {
        id: 2,
        solicitud: "Presencia de Olor a Gas en el Sector",
        prioridad: "Alta",
      },
    ],
  },
  {
    id: 3,
    nombre: "Alcantarillado",
    options: [
      { id: 1, solicitud: "Destape de WC", prioridad: "Alta" },
      { id: 2, solicitud: "Destape de Urinario", prioridad: "Alta" },
      { id: 3, solicitud: "Destape de Cámaras", prioridad: "Alta" },
      { id: 4, solicitud: "Destape de Desagüe de ducha", prioridad: "Alta" },
      { id: 5, solicitud: "Malos Olores en Baños", prioridad: "Media" },
      { id: 6, solicitud: "Malos Olores en el Pabellón", prioridad: "Media" },
      { id: 7, solicitud: "Varillaje de Cámaras", prioridad: "Alta" },
      {
        id: 8,
        solicitud: "Destape de Desagüe de Lavamanos",
        prioridad: "Alta",
      },
      { id: 9, solicitud: "Limpieza de Piletas", prioridad: "Media" },
    ],
  },
  {
    id: 4,
    nombre: "Electricidad",
    options: [
      {
        id: 1,
        solicitud: "Sin energía en Pabellón (Reposición de energía)",
        prioridad: "Alta",
      },
      {
        id: 2,
        solicitud: "Sin energía en enchufes (Reposición de energía)",
        prioridad: "Alta",
      },
      {
        id: 3,
        solicitud: "Sin energía completa en habitación",
        prioridad: "Alta",
      },
      {
        id: 4,
        solicitud: "Sin energía completa en pasillo",
        prioridad: "Alta",
      },
      {
        id: 5,
        solicitud: "Cambio de equipo luminaria Central",
        prioridad: "Alta",
      },
      {
        id: 6,
        solicitud: "Cambio de equipo luminaria Cabecera o Aplique",
        prioridad: "Baja",
      },
      { id: 7, solicitud: "Reparación luminaria Central", prioridad: "Alta" },
      { id: 8, solicitud: "Reparación luminaria Cabecera", prioridad: "Baja" },
      { id: 9, solicitud: "Cambio de luminaria central", prioridad: "Alta" },
      {
        id: 10,
        solicitud: "Cambio de luminaria de cabecera",
        prioridad: "Baja",
      },
      {
        id: 11,
        solicitud: "Reparación de aplique nuevo estándar (con entrada USB)",
        prioridad: "Baja",
      },
      {
        id: 12,
        solicitud: "Reparación luminaria de Pasillo",
        prioridad: "Alta",
      },
      {
        id: 13,
        solicitud: "Reparación Luminaria de Baño Batería A la Par",
        prioridad: "Alta",
      },
      { id: 14, solicitud: "Reparación Cinta Calefactora", prioridad: "Alta" },
      { id: 15, solicitud: "Reposición cinta calefactora", prioridad: "Alta" },
      {
        id: 16,
        solicitud: "Reparación Luminaria de Baño Batería Ala Impar",
        prioridad: "Alta",
      },
      {
        id: 17,
        solicitud: "Equipo de Luminaria Suelto o Colgando en Pasillo",
        prioridad: "Alta",
      },
      {
        id: 18,
        solicitud:
          "Equipo de Luminaria Suelto o Colgando en Baño Batería a la par",
        prioridad: "Alta",
      },
      {
        id: 19,
        solicitud:
          "Equipo de Luminaria Suelto o Colgando en Baño Batería ala impar",
        prioridad: "Alta",
      },
      {
        id: 20,
        solicitud: "Equipo de Luminaria Central Suelto o Colgando",
        prioridad: "Alta",
      },
      {
        id: 21,
        solicitud: "Equipo de Luminaria Cabecera Suelto o Colgando",
        prioridad: "Alta",
      },
      { id: 22, solicitud: "Reparación de enchufes", prioridad: "Alta" },
      { id: 23, solicitud: "Reparación de interruptores", prioridad: "Alta" },
      {
        id: 24,
        solicitud: "Reposición de tapa de interruptor",
        prioridad: "Alta",
      },
      { id: 25, solicitud: "Canalización eléctrica", prioridad: "Media" },
      {
        id: 26,
        solicitud: "Reposición de Tapa de Tablero Eléctrico",
        prioridad: "Alta",
      },
      { id: 27, solicitud: "Reparación Tablero eléctrico", prioridad: "Alta" },
      {
        id: 28,
        solicitud: "Reparación de Tablero auxiliar en habitación",
        prioridad: "Alta",
      },
      {
        id: 29,
        solicitud: "Reposición de Tapa de Tablero auxiliar en habitación",
        prioridad: "Alta",
      },
    ],
  },
  {
    id: 5,
    nombre: "INFRAESTRUCTURA",
    options: [
      {
        id: 1,
        solicitud: "Reposición de Black Out en Ventana",
        prioridad: "Media",
      },
      {
        id: 2,
        solicitud: "Cambio de Black Out en Ventana",
        prioridad: "Media",
      },
      {
        id: 3,
        solicitud: "Reposición de Cortina Roller en Ventana",
        prioridad: "Media",
      },
      {
        id: 4,
        solicitud: "Cambio de Cortina Roller en Ventana",
        prioridad: "Media",
      },
      { id: 5, solicitud: "Pintar Guarda Polvos", prioridad: "Baja" },
      { id: 6, solicitud: "Instalar Guarda Polvos", prioridad: "Baja" },
      { id: 7, solicitud: "Cielo a punto de caer", prioridad: "Alta" },
      { id: 8, solicitud: "Reparar cielo", prioridad: "Alta" },
      {
        id: 9,
        solicitud: "Reparar tabiquería o paredes por agujeros",
        prioridad: "Alta",
      },
      { id: 10, solicitud: "Cambio de Extintores Vencidos", prioridad: "Alta" },
      { id: 11, solicitud: "Instalar o reponer Extintor", prioridad: "Alta" },
      { id: 12, solicitud: "Ajuste de Bisagras de Puerta", prioridad: "Alta" },
      {
        id: 13,
        solicitud: "Ajuste de Bisagras de Puerta Salida Emergencia",
        prioridad: "Alta",
      },
      {
        id: 14,
        solicitud: "Pintura Paredes (por deterioro o manchas)",
        prioridad: "Baja",
      },
      {
        id: 15,
        solicitud: "Pintura Cielo (por deterioro o manchas)",
        prioridad: "Baja",
      },
      {
        id: 16,
        solicitud: "Pintura Área Completa (por deterioro o manchas)",
        prioridad: "Baja",
      },
      {
        id: 17,
        solicitud:
          "Pintura de Paredes por Hongos (Tratamiento de Hongos Realizado por Aseo)",
        prioridad: "Media",
      },
      {
        id: 18,
        solicitud:
          "Pintura de Cielo por Hongos (Tratamiento de Hongos Realizado por Aseo)",
        prioridad: "Media",
      },
      {
        id: 19,
        solicitud:
          "Pintura Área Completa por Hongos (Tratamiento de Hongos Realizado por Aseo)",
        prioridad: "Media",
      },
      {
        id: 20,
        solicitud: "Puerta Salida Emergencia Caída",
        prioridad: "Alta",
      },
      { id: 21, solicitud: "Puerta caída", prioridad: "Alta" },
      {
        id: 22,
        solicitud: "Puerta Salida Emergencia a punto de caer",
        prioridad: "Alta",
      },
      { id: 23, solicitud: "Puerta a punto de caer", prioridad: "Alta" },
      {
        id: 24,
        solicitud: "Reparación de locker o casillero",
        prioridad: "Media",
      },
      {
        id: 25,
        solicitud: "Reposición de Locker (Autorizado por CMDIC Hotelería)",
        prioridad: "Baja",
      },
      {
        id: 26,
        solicitud: "Reposición de Casillero (Autorizado por CMDIC Hotelería)",
        prioridad: "Baja",
      },
      {
        id: 27,
        solicitud: "Cambio de Locker (Autorizado por CMDIC Hotelería)",
        prioridad: "Baja",
      },
      {
        id: 28,
        solicitud: "Cambio de Casillero (Autorizado por CMDIC Hotelería)",
        prioridad: "Baja",
      },
      {
        id: 29,
        solicitud: "Instalación de Brazo Hidráulico",
        prioridad: "Alta",
      },
      {
        id: 30,
        solicitud: "Reparación de Brazo Hidráulico",
        prioridad: "Alta",
      },
      { id: 31, solicitud: "Reparación de puertas", prioridad: "Media" },
      { id: 32, solicitud: "Reparación de Cielo", prioridad: "Media" },
      { id: 33, solicitud: "Reparación de piso mal estado", prioridad: "Alta" },
      {
        id: 34,
        solicitud: "Reparación de piso salida de Baños",
        prioridad: "Alta",
      },
      { id: 35, solicitud: "Reparación de greating", prioridad: "Alta" },
      { id: 36, solicitud: "Instalación de Dispensadores", prioridad: "Baja" },
      { id: 37, solicitud: "Cambio de Dispensadores", prioridad: "Baja" },
      { id: 38, solicitud: "Pintura Estructuras", prioridad: "Baja" },
      { id: 39, solicitud: "Cambio de mobiliario", prioridad: "Baja" },
      {
        id: 40,
        solicitud: "Traslado o retiro de Mobiliario",
        prioridad: "Baja",
      },
      {
        id: 41,
        solicitud: "Pintado de pilastras y guardapolvos",
        prioridad: "Baja",
      },
      {
        id: 42,
        solicitud: "Instalación / cambio Cerámica",
        prioridad: "Media",
      },
      {
        id: 43,
        solicitud: "Reposición de Camas (Autorizado por CMDIC Hotelería)",
        prioridad: "Alta",
      },
      {
        id: 44,
        solicitud: "Cambio de Veladores (Autorizado por CMDIC Hotelería)",
        prioridad: "Baja",
      },
      { id: 45, solicitud: "Reparación de Veladores", prioridad: "Baja" },
      {
        id: 46,
        solicitud: "Reposición de Veladores (Autorizado por CMDIC Hotelería)",
        prioridad: "Baja",
      },
      { id: 47, solicitud: "Reparación Camas", prioridad: "Alta" },
      {
        id: 48,
        solicitud: "Reposición de Camas (Autorizado por CMDIC Hotelería)",
        prioridad: "Alta",
      },
      {
        id: 49,
        solicitud: "Cambio Camas (Autorizado por CMDIC Hotelería)",
        prioridad: "Alta",
      },
      {
        id: 50,
        solicitud: "Cambio Colchones (Autorizado por CMDIC Hotelería)",
        prioridad: "Alta",
      },
      {
        id: 51,
        solicitud: "Reposición de Colchones (Autorizado por CMDIC Hotelería)",
        prioridad: "Alta",
      },
      {
        id: 52,
        solicitud: "Cambio de TV (Autorizado por CMDIC Hotelería)",
        prioridad: "Baja",
      },
      {
        id: 53,
        solicitud: "Reposición de TV (Autorizado por CMDIC Hotelería)",
        prioridad: "Baja",
      },
      { id: 54, solicitud: "House keeping sector", prioridad: "Alta" },
      {
        id: 55,
        solicitud: "Cambio / Reposición Cable Coaxial",
        prioridad: "Baja",
      },
    ],
  },
  {
    id: 6,
    nombre: "CERRAJERÍA",
    options: [
      { id: 1, solicitud: "Corte de Candado", prioridad: "Baja" },
      { id: 2, solicitud: "Cambio de Chapa puerta", prioridad: "Alta" },
      { id: 3, solicitud: "Reparación de Chapa puerta", prioridad: "Alta" },
      { id: 4, solicitud: "Cambio de Chapín de casillero", prioridad: "Media" },
      {
        id: 5,
        solicitud: "Reparación de Chapín casillero",
        prioridad: "Media",
      },
      {
        id: 6,
        solicitud: "Reposición de chapín de casillero",
        prioridad: "Media",
      },
      { id: 7, solicitud: "Cambio de Chapín de mueble", prioridad: "Baja" },
      { id: 8, solicitud: "Reparación de Chapín de mueble", prioridad: "Baja" },
      { id: 9, solicitud: "Reposición de chapín de mueble", prioridad: "Baja" },
      { id: 10, solicitud: "Cambio de Chapín de velador", prioridad: "Baja" },
      {
        id: 11,
        solicitud: "Reparación de Chapín de velador",
        prioridad: "Baja",
      },
      {
        id: 12,
        solicitud: "Reposición de chapín de velador",
        prioridad: "Baja",
      },
      { id: 13, solicitud: "Confección Copia de Llaves", prioridad: "Alta" },
      {
        id: 14,
        solicitud: "Reparación manilla de puerta salida emergencia",
        prioridad: "Alta",
      },
      { id: 15, solicitud: "Reparación chapa antipánico", prioridad: "Alta" },
      { id: 16, solicitud: "Reparación manilla de puerta", prioridad: "Alta" },
    ],
  },
  {
    id: 7,
    nombre: "Sistema de Agua Caliente",
    options: [
      { id: 1, solicitud: "Reparación de Termos", prioridad: "Alta" },
      { id: 2, solicitud: "Cambio de Termos", prioridad: "Alta" },
      { id: 3, solicitud: "Reparación de Calderas", prioridad: "Alta" },
      { id: 4, solicitud: "Cambio de Calderas", prioridad: "Alta" },
      { id: 5, solicitud: "Encendido de Termos", prioridad: "Alta" },
      { id: 6, solicitud: "Reparación de Boiler", prioridad: "Alta" },
      { id: 7, solicitud: "Cambio de Boiler", prioridad: "Alta" },
      {
        id: 8,
        solicitud:
          "Sin suministro de agua caliente en baño general (Ducha y llave mono mando)",
        prioridad: "Alta",
      },
      {
        id: 9,
        solicitud: "Sin suministro de agua caliente en llave mono mando",
        prioridad: "Alta",
      },
      { id: 10, solicitud: "Encendido de Calderas", prioridad: "Alta" },
    ],
  },
  {
    id: 8,
    nombre: "Sistema de Climatización",
    options: [
      { id: 1, solicitud: "Reparación de estufa", prioridad: "Alta" },
      {
        id: 2,
        solicitud: "Reposición de Estufa (Autorizado por CMDIC)",
        prioridad: "Alta",
      },
      {
        id: 3,
        solicitud: "Cambio de Estufa (Autorizado por CMDIC)",
        prioridad: "Alta",
      },
      {
        id: 4,
        solicitud: "Reparación de equipo de aire acondicionado",
        prioridad: "Alta",
      },
      {
        id: 5,
        solicitud:
          "Reposición de equipo de aire acondicionado (Autorizado por CMDIC)",
        prioridad: "Alta",
      },
      {
        id: 6,
        solicitud:
          "Cambio de equipo de aire acondicionado (Autorizado por CMDIC)",
        prioridad: "Alta",
      },
      { id: 7, solicitud: "Reparación de cortina de aire", prioridad: "Alta" },
      {
        id: 8,
        solicitud: "Cambio de cortina de aire (Autorizado por CMDIC)",
        prioridad: "Alta",
      },
      { id: 9, solicitud: "Instalación de Dragones", prioridad: "Alta" },
      { id: 10, solicitud: "Reparación de Dragones", prioridad: "Alta" },
      {
        id: 11,
        solicitud: "Instalación de Aero ventiladores",
        prioridad: "Alta",
      },
      {
        id: 12,
        solicitud: "Reposición de cortina de aire (Autorizado por CMDIC)",
        prioridad: "Alta",
      },
    ],
  },
  {
    id: 9,
    nombre: "Conectividad",
    options: [
      { id: 1, solicitud: "Disponibilidad de WIFI", prioridad: "Media" },
      { id: 2, solicitud: "Disponibilidad de TV Cable", prioridad: "Media" },
    ],
  },
  {
    id: 10,
    nombre: "Seguridad",
    options: [
      { id: 1, solicitud: "Paneles centrales de alarma", prioridad: "Alta" },
      { id: 2, solicitud: "Detectores de incendio", prioridad: "Alta" },
      { id: 3, solicitud: "Pulsadores manuales", prioridad: "Alta" },
      { id: 4, solicitud: "Redes húmedas", prioridad: "Alta" },
      { id: 5, solicitud: "Grifos", prioridad: "Alta" },
      { id: 6, solicitud: "Luces de Emergencia", prioridad: "Alta" },
      { id: 7, solicitud: "Teléfonos de emergencia", prioridad: "Alta" },
      { id: 8, solicitud: "Botones de emergencia pasillos", prioridad: "Alta" },
      { id: 9, solicitud: "cámaras de seguridad", prioridad: "Alta" },
    ],
  },
];

const prioridades = ["Alta", "Media", "Baja"];

const data = {
  especialidad: "",
  solicitud: "",
  prioridad: "",
  descripcion: "",
};

const Create = ({ open2, setOpen2, requerimientos }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [selectedEspecialidad, setSelectedEspecialidad] = useState({
    options: [],
  });
  const [selectedSolicitud, setSelectedSolicitud] = useState(null);
  const [selectedPrioridad, setSelectedPrioridad] = useState(null);

  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [value, setValue] = useState(null);

  const handleChangeEspecialidad = (event, newValue) => {
    console.log(newValue);
    setSelectedPrioridad("");
    if (newValue != null) {
      // const filteredOptions = newValue.options.filter(option => {
      //   return !requerimientos.some(req => req.solicitud === option.solicitud);
      // });
      const filteredOptions = newValue.options.filter((option) =>
        requerimientos.some((req) => req.solicitud === option.solicitud)
      );
      // const updatedEspecialidad = { ...newValue, options: filteredOptions };
      const updatedEspecialidad = { ...newValue, options: filteredOptions };

      setFormData({
        ...formData,
        especialidad: updatedEspecialidad.nombre,
        solicitud: "",
        prioridad: "",
      });
      setSelectedEspecialidad(updatedEspecialidad);
      // setSelectedEspecialidad(requerimientos);
      setSelectedSolicitud(null);
      setSelectedPrioridad("");
    } else {
      setFormData({
        ...formData,
        especialidad: "",
        solicitud: "",
        prioridad: "",
      });
    }
  };

  const handleChangeSolicitud = (event, newValue) => {
    console.log("Nueva solicitud", newValue);
    if (newValue != null) {
      setFormData({
        ...formData,
        solicitud: newValue.solicitud,
        prioridad: newValue.prioridad,
      });
      setSelectedSolicitud(newValue);
      setSelectedPrioridad(newValue.prioridad);
    } else {
      setFormData({ ...formData, solicitud: "", prioridad: "" });
      setSelectedPrioridad(null);
    }
  };
  const handleChangePrioridad = (event, newValue) => {
    console.log("Nueva prioridad", newValue);
    if (newValue != null) {
      setFormData({ ...formData, prioridad: newValue });
      setSelectedPrioridad(newValue);
    } else {
      setFormData({ ...formData, solicitud: "", prioridad: "" });
      setSelectedPrioridad(null);
    }
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const canSave =
    [formData.especialidad, formData.solicitud, formData.prioridad].every(
      Boolean
    ) && addRequestStatus === "idle";

  const handleSubmit = async () => {
    if (canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus("loading");
        let resp = await dispatch(addNewMotivo(formData));
        console.log(resp);
        if (resp.payload.status === "success") {
          setFormData(data);
          setOpen(false);
        }
      } catch (error) {
        console.error("Error al añadir el registro", error.message);
      } finally {
        setOpen2(false);
        setAddRequestStatus("idle");
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          El siguiente <strong>botón</strong> te mostrará un formulario para
          poder registrar un registro de criticidad.
        </Typography>
        <Button
          variant="contanied"
          size="large"
          className={styles.bgColor}
          onClick={() => setOpen(true)}
        >
          <CircleIcon className={styles.iconButton} />
          Añadir registro
        </Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <TroubleshootIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              Registrar requerimiento
            </Typography>
            <span className={styles.subTitle}>
              Rellena los siguientes campos para registrar el requerimiento.
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Especialidad</label>
              <Autocomplete
                disablePortal
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                id="campamentos-autocomplete"
                name="campamento"
                disableClearable
                options={
                  Array.isArray(criticidad) && criticidad.length > 0
                    ? criticidad
                    : []
                }
                getOptionLabel={(option) => option.nombre}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    sx={{
                      maxHeight: "200px",
                    }}
                    disablePortal
                  />
                )}
                onChange={(e, value) => handleChangeEspecialidad(e, value)}
                renderInput={(params) => (
                  <TextField {...params} label="Selecciona una especialidad" />
                )}
              />
            </Grid>
            {selectedEspecialidad?.options.length > 0 ? (
              <Grid item xs={12} sm={12}>
                <label className={styles.label}>Solicitud</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: "#2d3037",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="solicitud"
                  type="text"
                  onChange={(e) => onChange(e)}
                />
                {/*
                <Autocomplete
                  disablePortal
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color:'#2d3037',
                    },
                    '& .MuiInputLabel-root': {
                      color:'#2d3037',
                    },
                  }}
                  disableClearable
                  id="campamentos-autocomplete"
                  name="solicitud"
                  value={selectedSolicitud}
                  options={selectedEspecialidad?.options || []}
                  getOptionLabel={(option) => option.solicitud}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      sx={{
                        height: '200px',
                        overflowY: 'auto',
                      }}
                    />
                  )}
                  onChange={(e, value) => handleChangeSolicitud(e, value)}
                  renderInput={(params) => <TextField {...params} label="Selecciona una solicitud" />}
                /> */}
              </Grid>
            ) : // : selectedEspecialidad.nombre ? (
            //   <Grid item xs={12} sm={12}>
            //     <span style={{ fontWeight: '600' }}>Todas las solicitudes asociadas a la especialidad ya han sido registradas.</span>
            //   </Grid>
            // )
            null}

            {selectedEspecialidad?.options.length > 0 && (
              <Grid item xs={12} sm={12}>
                <label className={styles.label}>Prioridad</label>
                <Autocomplete
                  disablePortal
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  disableClearable
                  id="campamentos-autocomplete"
                  name="solicitud"
                  value={selectedPrioridad}
                  options={prioridades || []}
                  getOptionLabel={(option) => option}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      sx={{
                        height: "200px",
                        overflowY: "auto",
                      }}
                    />
                  )}
                  onChange={(e, value) => handleChangePrioridad(e, value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona una prioridad" />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Descripción (opcional)</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="descripcion"
                type="text"
                multiline
                rows={4}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding: "0 16px" }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained"
                disabled={!canSave}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Crear"
                )}
              </Button>
              <Button
                onClick={() => setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Create;
