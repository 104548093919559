import styles from './components/components.module.css'
import { Grid, Backdrop, Box, Card, Container, Typography, CircularProgress } from "@mui/material"
import { NavLink } from 'react-router-dom'
import DashboardIcon from '@mui/icons-material/Dashboard';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { useDispatch, useSelector } from "react-redux";
import { fetchCampamentos, getAllCampamentos, getCampamentosStatus } from "../../../redux/features/campamentos/campamentoSlice";
import { useEffect, useState } from "react";
import TableReclamos from './components/TableReclamos';
import Create from './components/Create';
import TableReserva from './components/TableReserva';
import { fetchReclamosSugerencias, getAllReclamosSugerencias, getReclamosSugerenciasStatus } from '../../../redux/features/reclamos_sugerencias/reclamos_sugerenciaSlice';
import { fetchMotivos, getAllMotivos, getMotivosStatus } from '../../../redux/features/motivos/motivoSlice';
import { fetchWings, getAllWings, getWingsStatus } from '../../../redux/features/wings/wingSlice';
import { fetchEdificios, getAllEdificios, getEdificiosStatus } from '../../../redux/features/edificios/edificioSlice';
import { fetchHabitaciones, getAllHabitaciones, getHabitacionesStatus } from '../../../redux/features/habitaciones/habitacionSlice';
import { fetchOtros, getAllOtros, getOtrosStatus } from '../../../redux/features/otros/otroSlice';
import TableOficinas from './components/TableOficinas';
import { fetchOficinasDisponibles, getAllOficinasDisponibles, getOficinasDisponiblesStatus } from '../../../redux/features/oficinas/oficinaSlice';
import SkeletonTable from '../../../components/Skeleton';
import { fetchReservas, getAllreservas, getReservasStatus, getTrabajadoresAll, getTrabajadoresAllStatus } from '../../../redux/features/reservas/reservaSlice';
import { fetchEspacios, getAllEspacios, getEspaciosStatus } from '../../../redux/features/espacios/espacioSlice';
import Reasignar from './components/Reasignar2';
import VerInfoTrabajador from './components/VerInfoTrabajador';
import VerDetallesSolicitud from './components/asignarHabitaciones/VerDetallesSolicitud';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TableHabilitar from './components/TableHabilitar';
import ReasignarCMDIC from '../../adm.cmdic/components/Reasignar2'
import VerInfoTrabajadorCMDIC from '../../adm.cmdic/components/VerInfoTrabajador';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel2(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel2.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps2(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const DashboardRecepcionista = () => {

    const campamentos = useSelector(getAllCampamentos);
    const camStatus = useSelector(getCampamentosStatus);
    
    const wings = useSelector(getAllWings);
    const winStatus = useSelector(getWingsStatus);
    const otros = useSelector(getAllOtros);
    const otStatus = useSelector(getOtrosStatus);
    const pabellones = useSelector(getAllEdificios);
    const pabStatus = useSelector(getEdificiosStatus);
    const habitaciones = useSelector(getAllHabitaciones);
    const habStatus = useSelector(getHabitacionesStatus);
    const espacios = useSelector(getAllEspacios);
    const espStatus = useSelector(getEspaciosStatus);
    const motivos = useSelector(getAllMotivos);
    const motStatus = useSelector(getMotivosStatus);
    const oficinas = useSelector(getAllOficinasDisponibles);
    const ofStatus = useSelector(getOficinasDisponiblesStatus);
    const reclamos_sugerencias = useSelector(getAllReclamosSugerencias);
    const status = useSelector(getReclamosSugerenciasStatus);
    const reservas = useSelector(getAllreservas);
    const resStatus = useSelector(getReservasStatus);
    const trabajadores = useSelector(getTrabajadoresAll);
    const traStatus = useSelector(getTrabajadoresAllStatus);

    const [addRequestStatus, setAddRequestStatus] = useState('idle')
    const [open, setOpen] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [openReasignar, setOpenReasignar] = useState(false);
    const [openReasignar2, setOpenReasignar2] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    
    const [loaderList, setLoaderList] = useState(false);
    
    const [seleItem, setSeleItem] = useState();
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [value2, setValue2] = useState(0);

    const handleChange2 = (event, newValue) => {
      setValue2(newValue);
    };
  
    const handleChangeTab = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(()=>{
        if(status === 'idle'){
          dispatch(fetchReclamosSugerencias());
        }
        if(motStatus === 'idle'){
          dispatch(fetchMotivos());
        }
        if(ofStatus === 'idle'){
          dispatch(fetchOficinasDisponibles());
        }
        if(camStatus === 'idle'){
            dispatch(fetchCampamentos());
        }
        if(winStatus === 'idle'){
          dispatch(fetchWings());
        }
        if(pabStatus === 'idle'){
          dispatch(fetchEdificios());
        }
        if(habStatus === 'idle'){
          dispatch(fetchHabitaciones());
        }
        if(resStatus === 'idle'){
          dispatch(fetchReservas());
        }
        if(otStatus === 'idle'){
          dispatch(fetchOtros());
        }
        if(espStatus === 'idle'){
          dispatch(fetchEspacios());
        }
        if(traStatus === 'idle'){
          // dispatch(fetchTrabajadoresAll());
        }
      },[]);

  return (
    <div>
    <Backdrop
          sx={{ color: '#fff', zIndex: '100000' }}
          open={open3}
        >
          <CircularProgress color="inherit" />
      </Backdrop>
          <Grid container={true} spacing={2} style={{ marginTop:'10px' }}>
            <Grid item xs={12} sm={3}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value2} onChange={handleChange2} style={{ borderRight:'none', marginBottom:'10px' }}
                sx={{ borderRight: 1, borderColor: 'divider',
                  "& .MuiTabs-indicator": {
                      backgroundColor: "#FFF",
                      height: 3,
                    },
                    "& .MuiTab-root.Mui-selected": {
                      color: '#2d3037'
                    },
                    "& .MuiTab-root:not(.Mui-selected)": {
                      color: 'gray',
                    },
                    "& .MuiTabs-root": {
                      borderRight: 'none',
                    }
                }}>
                  <Tab label="ESED" {...a11yProps2(0)} />
                  <Tab label="CMDIC" {...a11yProps2(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value2} index={0}>
                <Card className={styles.card} style={{ marginBottom:'10px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', backgroundColor:'var(--inst-li-blue)', padding:'10px' }}>

                    <NavLink to='/historial' style={{ display:'flex', alignItems:'start',gap:'5px', textDecoration:'none' }}>
                      <DashboardIcon style={{ color:'#f19d38' }}/>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px' }}>
                        <Typography  style={{ color: '#FFF', marginLeft: '0' }}>Historial de reservas</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás gestionar las solicitudes de reservas dentro del historial de reservas.</Typography>
                      </div>
                    </NavLink>
                    </div>
                </Card>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Card className={styles.card2} style={{ backgroundColor:'var(--inst-blue)', color:'#FFF', padding:'10px',  marginBottom:'10px' }}>
                    <Reasignar open2={openReasignar} setOpen2={setOpenReasignar} status={traStatus} trabajadores={trabajadores} pabellones={pabellones} wings={wings}/>
                  </Card>
                  <Card className={styles.card2} style={{ backgroundColor:'var(--inst-blue)', color:'#FFF', padding:'10px' }}>
                    <VerInfoTrabajador open2={openReasignar} setOpen2={setOpenReasignar} status={traStatus} trabajadores={trabajadores} pabellones={pabellones} wings={wings} criticidadRequerimientos={motivos} />
                  </Card>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value2} index={1}>
                <Card className={styles.card} style={{ marginBottom:'10px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', backgroundColor:'var(--inst-li-blue)', padding:'10px' }}>
                    <NavLink to='/reservascmdic' style={{ display:'flex', alignItems:'start',gap:'5px', textDecoration:'none' }}>
                        <DashboardIcon style={{ color:'#f19d38' }}/>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px' }}>
                          <Typography  style={{ color: '#FFF', marginLeft: '0' }}>Gestión Asignación CMDIC</Typography>
                          <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás visualizar y modificar las asignaciones CMDIC.</Typography>
                        </div>
                      </NavLink>

                      <NavLink to='/turnos_rotativos' style={{ display:'flex', alignItems:'start',gap:'5px', textDecoration:'none' }}>
                        <DashboardIcon style={{ color:'#f19d38' }}/>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px' }}>
                          <Typography  style={{ color: '#FFF', marginLeft: '0' }}>Turnos rotativos</Typography>
                          <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Asignación de habitaciones en turnos rotativos.</Typography>
                        </div>
                      </NavLink>
                    </div>
                </Card>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Card className={styles.card2} style={{ backgroundColor:'var(--inst-blue)', color:'#FFF', padding:'10px',  marginBottom:'10px' }}>
                    <ReasignarCMDIC open2={openReasignar2} setOpen2={setOpenReasignar2} status={traStatus} trabajadores={trabajadores} wings={wings}/> 
                  </Card>
                  <Card className={styles.card2} style={{ backgroundColor:'var(--inst-blue)', color:'#FFF', padding:'10px' }}>
                    <VerInfoTrabajadorCMDIC open2={openInfo} setOpen2={setOpenInfo} status={traStatus} trabajadores={trabajadores} wings={wings} criticidadRequerimientos={motivos} />
                  </Card>
                </div>
              </CustomTabPanel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example" style={{ borderRight:'none', marginBottom:'10px' }}
                sx={{ borderRight: 1, borderColor: 'divider',
                  "& .MuiTabs-indicator": {
                      backgroundColor: "#FFF",
                      height: 3,
                    },
                    "& .MuiTab-root.Mui-selected": {
                      color: '#2d3037'
                    },
                    "& .MuiTab-root:not(.Mui-selected)": {
                      color: 'gray',
                    },
                    "& .MuiTabs-root": {
                      borderRight: 'none',
                    }
                }}>
                <Tab label="Reservas" {...a11yProps(0)} />
                <Tab label="Reclamos" {...a11yProps(1)} />
                <Tab label="Habitaciones" {...a11yProps(2)} />
              </Tabs>
              <CustomTabPanel value={value} index={0} style={{ padding:'0', margin:'0' }}>
                <Card style={{ width:'100%', padding:'16px', display:'flex', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap', backgroundColor:'#FFF', color:'var(--inst-card-bg)' }}>
                  <div style={{ color:'var(--inst-card-bg)' }}>
                    <Typography variant="h5" style={{ display:'flex', alignItems:'center', color:'var(--inst-card-bg)' }}>
                      <TextsmsIcon style={{ fontSize:'1.5rem' }}/>Reservas Actuales
                    </Typography>
                    <span style={{ color:'var(--inst-card-bg)' }}>A continuación ver y modificar las reservas actuales en el sistema.</span>
                  </div>
                  <VerDetallesSolicitud setOpen2={setOpen4} open2={open4} open={open} setOpen={setOpen} seleItem={seleItem} campamentos={campamentos} wings={wings}  setSeleItem={setSeleItem} pabellones={pabellones} setLoaderList={setLoaderList} loaderList={loaderList}/>
                  {/*<CreateReserva setOpen2={setOpen4} open2={open4} open={open} setOpen={setOpen} seleItem={seleItem} campamentos={campamentos} wings={wings}  setSeleItem={setSeleItem} pabellones={pabellones} setLoaderList={setLoaderList} loaderList={loaderList}/> */}
                </Card>
                { resStatus == 'loading' ? <SkeletonTable/> :   <TableReserva reservas={reservas} addRequestStatus={addRequestStatus} setOpen3={setOpen3} open={open} setOpen={setOpen} setSeleItem={setSeleItem}/> }
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1} style={{ padding:'0', margin:'0' }}>
                <Card mt={2} style={{ width:'100%', padding:'16px', display:'flex', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap', marginTop:'16px', backgroundColor:'#FFF', color:'var(--inst-card-bg)' }}>
                  <div>
                    <Typography variant="h5" className={styles.header} style={{ display:'flex', alignItems:'center' }}>
                      <TextsmsIcon style={{ fontSize:'1.5rem' }}/>Reclamos y Sugerencias
                    </Typography>
                    <span>A continuación podrás ingresar un reclamo o sugerencia de un huesped.</span>
                  </div>
                  <Create setOpen2={setOpen2} open2={open2} motivos={motivos} campamentos={campamentos} wings={wings} pabellones={pabellones} habitaciones={habitaciones} otros={otros} espacios={espacios}/>
                </Card>
                { status == 'loading' ? <SkeletonTable/> :<TableReclamos reclamos_sugerencias={reclamos_sugerencias} addRequestStatus={addRequestStatus} setOpen3={setOpen3} open3={open3}/>}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2} style={{ padding:'0', margin:'0' }}>
                <Card mt={2} style={{ width:'100%', padding:'16px', display:'flex', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap', marginTop:'16px', backgroundColor:'#FFF', color:'var(--inst-card-bg)' }}>
                  <div>
                    <Typography variant="h5" className={styles.header} style={{ display:'flex', alignItems:'center' }}>
                      <TextsmsIcon style={{ fontSize:'1.5rem' }}/>Habilitar/Deshabilitar habitación</Typography> 
                    <span>A continuación podrás habilitar o deshabilitar las habitaciones <strong>seleccionando</strong> los registros.</span>
                  </div>
                </Card>
                { habStatus == 'loading' ? <SkeletonTable/> :<TableHabilitar habitaciones={habitaciones || []} addRequestStatus={addRequestStatus} setOpen3={setOpen3} open3={open3}/>}

              </CustomTabPanel>
            </Grid>
          </Grid>
    </div>
  )
}

export default DashboardRecepcionista

{/*<Card style={{ width:'100%', padding:'16px', marginBottom:'10px', backgroundColor:'var(--inst-card-bg)', color:'#FFF', border:'1px solid var(--border-orange)' }}>
            
              </Card>
              <Card style={{ width:'100%', padding:'16px', marginBottom:'10px', backgroundColor:'var(--inst-card-bg)', color:'#FFF', border:'1px solid var(--border-orange)' }}>
                
              </Card> */}

// <div>
//       <Backdrop
//           sx={{ color: '#fff', zIndex: '100000' }}
//           open={open3}
//         >
//           <CircularProgress color="inherit" />
//       </Backdrop>

//       <Box sx={{ width: '100%', typography: 'body1' }}>
//             <TabContext value={value}>
//               <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//                 <TabList onChange={handleChange} aria-label="lab API tabs example">
//                   <Tab label="Item One" value="1" tabIndex={-1} />
//                   <Tab label="Item Two" value="2" tabIndex={-1} />
//                   <Tab label="Item Three" value="3" tabIndex={-1} />
//                 </TabList>
//               </Box>
//               <TabPanel value="1">
//                 <Card style={{ width:'100%', padding:'16px', display:'flex', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap' }}>
//                     <div>
//                         <Typography variant="h5" className={styles.header} style={{ display:'flex', alignItems:'center' }}>
//                         <TextsmsIcon style={{ fontSize:'1.5rem' }}/>
//                         Reservas Actuales
//                     </Typography>
//                     <span>A continuación ver y modificar las reservas actuales en el sistema.</span>
//                     </div>

//                     <CreateReserva setOpen2={setOpen4} open2={open4} open={open} setOpen={setOpen} seleItem={seleItem} campamentos={campamentos} wings={wings} habitaciones={habitaciones} />
//                 </Card>
                
//                   <TableReserva rows={reservas} addRequestStatus={addRequestStatus} setOpen3={setOpen3} open={open} setOpen={setOpen} setSeleItem={setSeleItem}/>
//               </TabPanel>
//               <TabPanel value="2">
//                 <Card mt={2} style={{ width:'100%', padding:'16px', display:'flex', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap', marginTop:'16px' }}>
//                     <div>
//                         <Typography variant="h5" className={styles.header} style={{ display:'flex', alignItems:'center' }}>
//                         <TextsmsIcon style={{ fontSize:'1.5rem' }}/>
//                         Reclamos y Sugerencias
//                     </Typography>
//                     <span>A continuación podrás ingresar un reclamo o sugerencia de un huesped.</span>
//                     </div>
//                     <Create setOpen2={setOpen2} open2={open2} motivos={motivos} campamentos={campamentos} wings={wings} pabellones={pabellones} habitaciones={habitaciones} otros={otros}/>
//                 </Card>
                
//                 <TableReclamos reclamos_sugerencias={reclamos_sugerencias} addRequestStatus={addRequestStatus} setOpen3={setOpen3} open3={open3}/>
//               </TabPanel>
//               <TabPanel value="3">Item Three</TabPanel>
//             </TabContext>
//           </Box>

//         <Box width={1} mt={0} mb={2}>
//             <Container className={styles.container} style={{ padding:0 }}>
                
//             </Container>
//         </Box>
//     </div>
