import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { deleteSector } from '../../../redux/features/sectores/sectorSlice';
import { deleteCategoria } from '../../../redux/features/inventario/inventarioSlice';
import { format } from 'date-fns';
import { confirmarRecepcionCambiar } from '../../../redux/features/bodegas/bodegaSlice';

export default function CambiarEstado({ open, setOpen, seleItem, setOpen3 }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const dispatch = useDispatch();
  
    const handleDelete = async () => {
      try {
        setOpen3(true);
        setAddRequestStatus('loading');
        const resp = await dispatch(confirmarRecepcionCambiar(seleItem.id))
      } catch (error) {
        console.error('Error al actualizar el estado del registro: ', error.message);
      } finally{
        setAddRequestStatus('idle');
        setOpen(false);
        setOpen3(false);
      }
    }

  return (
    <div>
      <Drawer
        anchor='bottom'
        open={open}
        onClose={() => setOpen(false)}
      >
        {seleItem && (
            <Box width={1} mt={0} style={{ backgroundColor:'#FFF', color:'var(--inst-card-bg)', padding:'16px' }}>
                <Container className={styles.containerDelete}>
                    <div className={styles.textContainer}>
                        <Typography variant="h6" className={styles.textIconDelete}><ErrorIcon/>Estás a punto de actualizar el estado del traspaso de bodega a <strong>Recibido</strong></Typography>
                        <Typography variant='span' className={styles.paragrap}>¿Estas seguro de proceder?</Typography>
                    </div>

                    <div className={styles.textContainer} style={{ display:'flex', justifyContent:'center', flexDirection:'column',textAlign:'center', width:'100%', marginTop:'10px' }}>
                        <Typography variant="span" style={{ display:'flex', justifyContent:'center',textAlign:'center', width:'100%', fontSize:'16px'}} className={styles.textIcon}>Desde: {seleItem?.bodegaOrigen?.nombre}</Typography>
                        <Typography variant="span" style={{ display:'flex', justifyContent:'center',textAlign:'center', width:'100%', fontSize:'16px'}} className={styles.textIcon}>Hacia: {seleItem?.bodegaDestino?.nombre}</Typography>
                        <Typography variant="span" style={{ display:'flex', justifyContent:'center',textAlign:'center', width:'100%', fontSize:'16px'}} className={styles.textIcon}>{seleItem?.fecha ? format(seleItem?.fecha, 'dd-MM-yyyy') : ''}</Typography>
                    </div>

                    <Grid container={true} spacing={1} mt={1}>
                        <Grid item xs={12} sm={12} className={styles.buttonContainerDelete}>
                          <Button
                              onClick={handleDelete}
                              className={styles.bgColorOrange}
                              variant="contained" 
                              >
                              {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                          </Button>
                          <Button 
                              onClick={()=>setOpen(false)}
                              className={styles.bgColorOutlined}
                              variant="outlined" 
                              style={{ border:'none' }}
                              >
                              Cancelar
                          </Button>
                        </Grid>
                    </Grid>

                </Container>                
            </Box>
        )}
      </Drawer>
    </div>
  );
}