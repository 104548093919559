import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';

import { axiosFormData, createAxiosFormDataInstance, createAxiosInstance } from "../../../api/axios";

const BODEGAS_URL = '/api/v1/Inventario/bodega';
const TRASPASO_BODEGAS_URL = '/api/v1/Inventario/traspaso';
const ESPACIO_BODEGAS_URL = '/api/v1/Inventario/espacio-bodega';
const INGRESOS_BODEGAS_URL = '/api/v1/Inventario/ingreso-bodega';
const SALIDAS_BODEGAS_URL = '/api/v1/Inventario/salida-bodega';
const BODEGA_URL = '/api/v1/Inventario/bodega/';
const DELETE_BODEGA_URL = '/api/v1/Inventario/bodega/';
const DELETE_TRASPASO_BODEGA_URL = '/api/v1/Inventario/traspaso/';
const DELETE_ESPACIO_BODEGA_URL = '/api/v1/Inventario/espacio-bodega/';
const DELETE_INGRESO_BODEGA_URL = '/api/v1/Inventario/ingreso-bodega/';
const UPDATE_BODEGA_URL = '/api/v1/Inventario/bodega/'
const UPDATE_ESPACIO_BODEGA_URL = '/api/v1/Inventario/espacio-bodega/'
const ADD_BODEGA_URL = '/api/v1/Inventario/bodega';
const ADD_ESPACIO_BODEGA_URL = '/api/v1/Inventario/espacio-bodega';
const ADD_TRASPASO_BODEGA_URL = '/api/v1/Inventario/traspaso';
const ADD_INGRESO_BODEGA_URL = '/api/v1/Inventario/ingreso-bodega';
const ADD_SALIDA_BODEGA_URL = '/api/v1/Inventario/salida-bodega';
const CONFIRMAR_RECEPCION_URL = '/api/v1/Inventario/traspaso/confirmar-recepcion/';
const APROBAR_RECHAZAR_RECEPCION_URL = '/api/v1/Inventario/traspaso/update-estado';

const axiosInstance = createAxiosInstance();
const axiosInstanceFormData = createAxiosFormDataInstance();

// ACTIONS
export const fetchBodegas = createAsyncThunk("bodegas/fetchBodegas", async ()=> {
    try {
        const response = await axiosInstance.get(BODEGAS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchTraspasosBodegas = createAsyncThunk("bodegas/fetchTraspasosBodegas", async ()=> {
    try {
        const response = await axiosInstance.get(TRASPASO_BODEGAS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchEspaciosBodega = createAsyncThunk("bodegas/fetchEspaciosBodega", async ()=> {
    try {
        const response = await axiosInstance.get(ESPACIO_BODEGAS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchIngresosBodega = createAsyncThunk("bodegas/fetchIngresosBodega", async ()=> {
    try {
        const response = await axiosInstance.get(INGRESOS_BODEGAS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})

export const fetchSalidasBodega = createAsyncThunk("bodegas/fetchSalidasBodega", async ()=> {
    try {
        const response = await axiosInstance.get(SALIDAS_BODEGAS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})

export const fetchBodega = createAsyncThunk("sector/fetchBodega", async (id)=> {
    try {
        const response = await axiosInstance.get(BODEGA_URL+id);        
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const deleteBodega = createAsyncThunk("bodegas/deleteBodega", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_BODEGA_URL+id);
        
        if (response.status >= 200 && response.status < 300) {
            toast.success('Bodega eliminada correctamente.');
            dispatch(fetchBodegas());
            return { data: {id} ,status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el bodega: ' + e.response.data);
        return e.message;
    }
})
export const deleteTraspaso = createAsyncThunk("bodegas/deleteTraspaso", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_TRASPASO_BODEGA_URL+id);
        
        if (response.status >= 200 && response.status < 300) {
            toast.success('Traspaso de bodega eliminado correctamente.');
            dispatch(fetchTraspasosBodegas());
            return { data: {id} ,status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el registro de traspaso de bodega: ' + e.response.data);
        return e.message;
    }
})
export const deleteEspacioBodega = createAsyncThunk("bodegas/deleteEspacioBodega", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_ESPACIO_BODEGA_URL+id);
        
        if (response.status >= 200 && response.status < 300) {
            toast.success('Espacio de bodega eliminado correctamente.');
            dispatch(fetchEspaciosBodega());
            return { data: {id} ,status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el espacio de bodega: ' + e.response.data);
        return e.message;
    }
})
export const deleteIngresoBodega = createAsyncThunk("bodegas/deleteIngresoBodega", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_INGRESO_BODEGA_URL+id);
        
        if (response.status >= 200 && response.status < 300) {
            toast.success('Registro de ingreso a bodega eliminado correctamente.');
            dispatch(fetchIngresosBodega());
            return { data: {id} ,status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el registro de ingreso a bodega: ' + e.response.data);
        return e.message;
    }
})
export const updateBodega = createAsyncThunk("bodegas/updateBodega", async (upBodega, {dispatch})=> {
    console.log('bodega', upBodega);
    try {
        const response = await axiosInstanceFormData.put(UPDATE_BODEGA_URL+upBodega.id, {
            nombre: upBodega.nombre,
            campamentoId: upBodega.campamentoId,
            descripcion: upBodega.descripcion,
            latitud: upBodega.latitud,
            longitud: upBodega.longitud,
            imagen: upBodega.imagen,
        });
        
        if (response.status === 200) {
            toast.success('Bodega actualizada correctamente.');
            dispatch(fetchBodegas());
            return { data: response.data, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar la bodega: ' + e.response.data);
        return e.message;
    }
})
export const updateEspacioBodega = createAsyncThunk("bodegas/updateEspacioBodega", async (upEspacio, {dispatch})=> {
    console.log('bodega', upEspacio);
    try {
        const response = await axiosInstance.put(UPDATE_ESPACIO_BODEGA_URL+upEspacio.id, {
            identificador: upEspacio.identificador,
            // pasilloVertical: upEspacio.pasilloVertical,
            // pasilloHorizontal: upEspacio.pasilloHorizontal,
            // ubicacion: upEspacio.ubicacion,
            // altura: upEspacio.altura,
            bodegaId: upEspacio.bodegaId,
        });
        
        if (response.status === 200) {
            toast.success('Espacio de bodega actualizado correctamente.');
            dispatch(fetchEspaciosBodega());
            return { data: response.data, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el espacio de bodega: ' + e.response.data);
        return e.message;
    }
})

export const confirmarRecepcionCambiar = createAsyncThunk("inventarios/confirmarRecepcionCambiar", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.put(CONFIRMAR_RECEPCION_URL+id);
        if (response.status === 200) {
            toast.success('Estado actualizado correctamente.');
            dispatch(fetchTraspasosBodegas());
            return { id, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el estado del registro: ' + e.response.data);
        return e.message;
    }
})
export const aprobarRechazarInventarioSupervisor = createAsyncThunk("inventarios/aprobarRechazarInventarioSupervisor", async (data, {dispatch})=> {
    console.log('data', data);
    try {
        const response = await axiosInstance.put(APROBAR_RECHAZAR_RECEPCION_URL,{
            id: data.id,
            estado: data.estado,
            razonRechazo: data.razonRechazo,
        });

        if (response.status === 200) {
            toast.success('Estado actualizado correctamente.');
            dispatch(fetchTraspasosBodegas());
            return { data, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el estado del registro: ' + e.response.data);
        return e.message;
    }
})

export const addNewBodega = createAsyncThunk("bodegas/createBodegas", async (initialCampamento, { dispatch }) => {
    console.log('lo que llega: ',initialCampamento)
    try {
        const response = await axiosInstanceFormData.post(ADD_BODEGA_URL, {
            nombre: initialCampamento.nombre,
            campamentoId: initialCampamento.campamentoId,
            descripcion: initialCampamento.descripcion,
            latitud: initialCampamento.latitud,
            longitud: initialCampamento.longitud,
            imagen: initialCampamento.imagen,
        });
        if (response.status >= 200 && response.status < 300) {
            toast.success('Bodega creada correctamente.');
            dispatch(fetchBodegas());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error' };
      } catch (e) {
        toast.error('Error al crear la bodega');
        return {message: e.message, status: 'error'};
    }
})

export const addNewEspacioBodega = createAsyncThunk("bodegas/addNewEspacioBodega", async (initialCampamento, { dispatch }) => {
    console.log('lo que llega: ',initialCampamento)
    try {
        const response = await axiosInstance.post(ADD_ESPACIO_BODEGA_URL, {
            bodegaId: initialCampamento.bodegaId,
            // pasilloVertical: initialCampamento.pasilloVertical,
            // pasilloHorizontal: initialCampamento.pasilloHorizontal,
            // altura: initialCampamento.altura,
            // ubicacion: initialCampamento.ubicacion,
            identificador: initialCampamento.identificador,
        });
        if (response.status >= 200 && response.status < 300) {
            toast.success('Espacio de bodega creado correctamente.');
            dispatch(fetchEspaciosBodega());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error' };
      } catch (e) {
        toast.error('Error al crear el espacio de bodega');
        return {message: e.message, status: 'error'};
    }
})

export const addNewTraspaso = createAsyncThunk("bodegas/addNewTraspaso", async (initialCampamento, { dispatch }) => {
    console.log('lo que llega: ',initialCampamento)
    const mappedArticulos = initialCampamento?.articulos.map(item => ({
        productoId: item.productoId,
        cantidad: parseInt(item.cantidadTraspaso)
    }))
    console.log('articulos',mappedArticulos);
    try {
        const response = await axiosInstance.post(ADD_TRASPASO_BODEGA_URL, {
            encargadoDeBodega: initialCampamento.encargadoDeBodega,
            bodegaOrigenId: initialCampamento.bodegaOrigenId,
            bodegaDestinoId: initialCampamento.bodegaDestinoId,
            montoTotal: initialCampamento.montoTotal,
            articulos: mappedArticulos,
        });
        if (response.status >= 200 && response.status < 300) {
            toast.success('Traspaso registrado correctamente.');
            dispatch(fetchTraspasosBodegas());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error' };
      } catch (e) {
        toast.error('Error al registrar el traspaso de bodegas');
        return {message: e.message, status: 'error'};
    }
})

export const addNewIngreso = createAsyncThunk("bodegas/addNewIngreso", async (initialCampamento, { dispatch }) => {
    console.log('lo que llega: ',initialCampamento)
    const formData = new FormData();
    // return;
    try {
        formData.append('BodegaId', initialCampamento.bodegaId);
        formData.append('receptor', initialCampamento.receptor,);
        formData.append('TipoDocumento', initialCampamento.tipoDocumento,);
        formData.append('OrdenDeCompra', initialCampamento.ordenDeCompra);
        formData.append('NumeroDocumento', initialCampamento.numeroDocumento);
        formData.append('MontoTotal', initialCampamento.montoTotal);
        const mappedItems = initialCampamento.ingresoArticulos.map(item => ({
            productoId: item.productoId,
            cantidadRecibida: parseInt(item.cantidadRecibida),
            cantidadDelDocumento: parseInt(item.cantidadDelDocumento),
            precio: parseInt(item.precio),
        }))
        console.log('envio:', {bodegaId: initialCampamento.bodegaId, receptor: initialCampamento.receptor, tipoDocumento: initialCampamento.tipoDocumento, ordenDeCompra: initialCampamento.ordenDeCompra, numeroDocumento: initialCampamento.numeroDocumento, montoTotal: initialCampamento.montoTotal});

        const articulos = JSON.stringify(mappedItems);
        formData.append('IngresoArticulos', articulos);
        console.log('envio articulos:', articulos);

        if (initialCampamento.imagen1) formData.append('imagen1', initialCampamento.imagen1);
        if (initialCampamento.imagen2) formData.append('imagen2', initialCampamento.imagen2);
        if (initialCampamento.imagen3) formData.append('imagen3', initialCampamento.imagen3);

        const response = await axiosInstanceFormData.post(ADD_INGRESO_BODEGA_URL, formData);
        
        if (response.status >= 200 && response.status < 300) {
            toast.success('Ingreso a bodega registrado correctamente.');
            dispatch(fetchIngresosBodega());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error' };
      } catch (e) {
        dispatch(fetchIngresosBodega());
        toast.error('Error al registrar el ingreso a bodega', e.data.message);
        return {message: e.data.message, status: 'error'};
    }
})

export const addNewSalida = createAsyncThunk("bodegas/addNewSalida", async (initialCampamento, { dispatch }) => {
    console.log('lo que llega: ',initialCampamento)

    const mappedArticulos = initialCampamento.salidaArticulos.map(item => ({
        articuloId: item.id,
        cantidad: item.cantidadDelDocumento,
        precio: item.precio,
    }))
    console.log('aqui pasa')

    try {
        
        const response = await axiosInstance.post(ADD_SALIDA_BODEGA_URL, {
            rutRetira: initialCampamento.rutRetira.rut,
            observaciones: initialCampamento.observaciones,
            bodegaId: initialCampamento.bodega,
            salidaArticulos: mappedArticulos,
            montoTotal: initialCampamento.montoTotal,
            cantidadTraspaso: initialCampamento.cantidadTraspaso,
        });
        
        if (response.status >= 200 && response.status < 300) {
            toast.success('Salida de bodega registrada correctamente.');
            dispatch(fetchSalidasBodega());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error' };
      } catch (e) {
        toast.error('Error al registrar la salida de bodega', e);
        return {message: e.message, status: 'error'};
    }
})



// idle : no hay operaciones en curso
const initialState = {
    bodegas: [],
    bodega: {},
    traspasos: [],
    traspaso: {},
    ingresos: [],
    ingreso: {},
    salidas: [],
    salida: {},
    espacio: {},
    espacios: {},
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    statusEspacios: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    statusTraspasos: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    statusIngresos: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    statusSalidas: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const bodegaSlice = createSlice({
    name:"bodega",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchBodegas.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchBodegas.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.bodegas) !== JSON.stringify(action.payload)) { //fetch
                state.bodegas = action.payload;
            }
        })
        .addCase(fetchBodegas.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchIngresosBodega.pending, (state, action) => {state.statusIngresos = 'loading'} ) //fetch
        .addCase(fetchIngresosBodega.fulfilled, (state, action) => {
            state.statusIngresos = 'succeeded';
            if (JSON.stringify(state.ingresos) !== JSON.stringify(action.payload)) { //fetch
                state.ingresos = action.payload;
            }
        })
        .addCase(fetchIngresosBodega.rejected, (state, action) => { //fetch
            state.statusIngresos = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchSalidasBodega.pending, (state, action) => {state.statusSalidas = 'loading'} ) //fetch
        .addCase(fetchSalidasBodega.fulfilled, (state, action) => {
            state.statusSalidas = 'succeeded';
            if (JSON.stringify(state.salidas) !== JSON.stringify(action.payload)) { //fetch
                state.salidas = action.payload;
            }
        })
        .addCase(fetchSalidasBodega.rejected, (state, action) => { //fetch
            state.statusSalidas = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchTraspasosBodegas.pending, (state, action) => {state.statusTraspasos = 'loading'} ) //fetch
        .addCase(fetchTraspasosBodegas.fulfilled, (state, action) => {
            state.statusTraspasos = 'succeeded';
            if (JSON.stringify(state.traspasos) !== JSON.stringify(action.payload)) { //fetch
                state.traspasos = action.payload;
            }
        })
        .addCase(fetchTraspasosBodegas.rejected, (state, action) => { //fetch
            state.statusTraspasos = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchEspaciosBodega.pending, (state, action) => {state.statusEspacios = 'loading'} ) //fetch
        .addCase(fetchEspaciosBodega.fulfilled, (state, action) => {
            state.statusEspacios = 'succeeded';
            if (JSON.stringify(state.espacios) !== JSON.stringify(action.payload)) { //fetch
                state.espacios = action.payload;
            }
        })
        .addCase(fetchEspaciosBodega.rejected, (state, action) => { //fetch
            state.statusEspacios = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchBodega.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchBodega.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.bodega) !== JSON.stringify(action.payload)) { //fetch
                state.bodega = action.payload;
            }
        })
        .addCase(fetchBodega.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewBodega.fulfilled, (state, action) => { //ADD
            state.status = 'succeeded'
            state.bodegas.push(action.payload);
        })
        .addCase(addNewSalida.fulfilled, (state, action) => { //ADD
            state.statusSalidas = 'succeeded'
            state.salidas.push(action.payload);
        })
        .addCase(addNewEspacioBodega.fulfilled, (state, action) => { //ADD
            state.status = 'succeeded'
            state.espacios.push(action.payload);
        })
        .addCase(addNewIngreso.fulfilled, (state, action) => { //ADD
            state.statusIngresos = 'succeeded'
            state.ingresos.push(action.payload);
        })
        .addCase(deleteBodega.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteBodega.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.bodegas = state.bodegas.filter(bodega => bodega.id !== action.payload.id);
            }else{
                state.status = 'failed';
            }
        })
        .addCase(deleteBodega.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(deleteTraspaso.pending, (state, action) => {state.statusTraspasos = 'loading'} ) //fetch
        .addCase(deleteTraspaso.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.statusTraspasos = 'succeeded';
                state.traspasos = state.traspasos.filter(traspaso => traspaso.id !== action.payload.id);
            }else{
                state.statusTraspasos = 'failed';
            }
        })
        .addCase(deleteTraspaso.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(deleteEspacioBodega.pending, (state, action) => {state.statusEspacios = 'loading'} ) //fetch
        .addCase(deleteEspacioBodega.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.statusEspacios = 'succeeded';
                state.espacios = state.espacios.filter(espacio => espacio.id !== action.payload.id);
            }else{
                state.statusEspacios = 'failed';
            }
        })
        .addCase(deleteEspacioBodega.rejected, (state, action) => { //fetch
            state.statusEspacios = 'failed'
            state.error = action.error.message;
        })
        .addCase(deleteIngresoBodega.pending, (state, action) => {state.statusIngresos = 'loading'} ) //fetch
        .addCase(deleteIngresoBodega.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.statusIngresos = 'succeeded';
                state.ingresos = state.ingresos.filter(ingreso => ingreso.id !== action.payload.id);
            }else{
                state.statusIngresos = 'failed';
            }
        })
        .addCase(deleteIngresoBodega.rejected, (state, action) => { //fetch
            state.statusIngresos = 'failed'
            state.error = action.error.message;
        })
        .addCase(updateBodega.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateBodega.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.bodegas = state.bodegas.map(bodega => {
                    if (bodega.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return bodega;
                });
            }else{
                state.status = 'failed';    
            }
        })
        .addCase(updateBodega.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(confirmarRecepcionCambiar.pending, (state, action) => {
            state.statusTraspasos = 'loading';
        })
        .addCase(confirmarRecepcionCambiar.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.statusTraspasos = 'succeeded';
                state.traspasos = state.traspasos.map(traspaso => {
                    if (traspaso.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return traspaso;
                });
            }else{
                state.statusTraspasos = 'failed';    
            }
        })
        .addCase(confirmarRecepcionCambiar.rejected, (state, action) => {
            state.statusTraspasos = 'failed';
            state.error = action.error.message;
        })
        .addCase(aprobarRechazarInventarioSupervisor.pending, (state, action) => {
            state.statusTraspasos = 'loading';
        })
        .addCase(aprobarRechazarInventarioSupervisor.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.statusTraspasos = 'succeeded';
                state.traspasos = state.traspasos.map(traspaso => {
                    if (traspaso.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return traspaso;
                });
            }else{
                state.statusTraspasos = 'failed';    
            }
        })
        .addCase(aprobarRechazarInventarioSupervisor.rejected, (state, action) => {
            state.statusTraspasos = 'failed';
            state.error = action.error.message;
        })
        .addCase(updateEspacioBodega.pending, (state, action) => {
            state.statusEspacios = 'loading';
        })
        .addCase(updateEspacioBodega.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.statusEspacios = 'succeeded';
                state.espacios = state.espacios.map(espacio => {
                    if (espacio.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return espacio;
                });
            }else{
                state.statusEspacios = 'failed';    
            }
        })
        .addCase(updateEspacioBodega.rejected, (state, action) => {
            state.statusEspacios = 'failed';
            state.error = action.error.message;
        })
    }
})

export const getAllBodegas = (state) => state.bodegas.bodegas;
export const getAllEspacios = (state) => state.bodegas.espacios;
export const getAllTraspasos = (state) => state.bodegas.traspasos;
export const getAllIngresos = (state) => state.bodegas.ingresos;
export const getAllSalidas = (state) => state.bodegas.salidas;

export const getBodega = (state) => state.bodegas.sector;
export const getBodegasStatus = (state) => state.bodegas.status;
export const getEspaciosStatus = (state) => state.bodegas.statusEspacios;
export const getTraspasosStatus = (state) => state.bodegas.statusTraspasos;
export const getIngresosStatus = (state) => state.bodegas.statusIngresos;
export const getSalidasStatus = (state) => state.bodegas.statusSalidas;
export const getBodegasError = (state) => state.bodegas.error;

export const {} = bodegaSlice.actions;
export default bodegaSlice.reducer;