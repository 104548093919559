import styles from './components.module.css'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { addNewProducto } from '../../../redux/features/inventario/inventarioSlice';
import { useNavigate } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';

const data = {
  codigo: '',
  nombre: '',
  descripcion: '',
  stockMinimo: 0,
  tiempo_reposicion: 0,
  categoria: '',
  duracion: '',
}
const CreateArticulo = ({ open2, setOpen2, categorias }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); //variable para abrir y cerrar modal

  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  const canSave = [formData.nombre,formData.codigo,formData.nombre,formData.descripcion,formData.stockMinimo,formData.tiempo_reposicion, formData.categoria].every(Boolean) && addRequestStatus === 'idle';
  
  const changeSelect = (event) => {
    setFormData({ ...formData, categoria: event.target.value });
  }

  const handleSubmit = async () => {
    
    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewProducto(formData)); 
        if(resp.payload.data.success){
          setFormData(data);
          setOpen(false);
          setOpen2(false);
          return;
        }
      } catch (error) {
        console.error('Error al añadir articulo al inventario.', error.message);
      } finally{
        setAddRequestStatus('idle');
        setOpen2(false);
      }
    }
  }

  const navigate = useNavigate();


  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between', marginBottom:'0.5em' }}>
        <Typography>El siguiente <strong>botón</strong> te devolverá inicio.</Typography>
        <Button variant='contained' size='large' className={styles.bgColorOrange} onClick={() => { navigate('/inicio'); }}>Volver</Button>
      </div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder añadir un articulo al inventario.</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> setOpen(true)}><CircleIcon className={styles.iconButton}/>Añadir articulo</Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
           backgroundColor:'#efefef',
           color:'#2d3037',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open2}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <HolidayVillageIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Crear árticulo</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para crear el árticulo.</span>
          </div>
        </DialogTitle>
        
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={4}>
              <label className={styles.label}>Codigo</label>
              <TextField
               className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                }}
                variant="outlined"
                fullWidth
                name='codigo'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={8}>
              <label className={styles.label}>Nombre</label>
              <TextField
               className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                }}
                variant="outlined"
                fullWidth
                name='nombre'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={4}>
              <label className={styles.label}>Stock mínimo</label>
              <TextField
               className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                }}
                variant="outlined"
                fullWidth
                name='stockMinimo'
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={8}>
              <label className={styles.label}>Categoría</label>
              <Select
                  className={styles.textField}
                  sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label=""
                style={{ width:'100%' }}
                onChange={changeSelect}
                >
                {categorias?.map((cat, i)=> (
                  <MenuItem value={cat.id} key={i}>{cat.nombre}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Tiempo promedio de reposición (dias)</label>
              <TextField
               className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                }}
                variant="outlined"
                fullWidth
                name='tiempo_reposicion'
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Duración (por meses)</label>
              <TextField
               className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                }}
                variant="outlined"
                fullWidth
                name='duracion'
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Descripciòn</label>
              <TextField
               className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                }}
                variant="outlined"
                fullWidth
                name='descripcion'
                type="text"
                multiline
                rows={4}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} spacing={1} style={{ marginBottom:'15px' }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained" 
                disabled={!canSave}>
                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Añadir'}
              </Button>
              <Button 
                onClick={()=>setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined" 
                >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        </Dialog>
    </div>
  )
}

export default CreateArticulo