import styles from "./components.module.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewWing } from "../../../../redux/features/wings/wingSlice";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Popper,
  TextField,
  Typography,
  MenuItem,
  Select,
  DialogActions,
} from "@mui/material";
import { getAllEstandares } from "../../../../redux/features/estandares/estandarSlice";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import CircleIcon from "@mui/icons-material/Circle";

const data = {
  idCampamento: 0,
  nombre: "",
  n_habitaciones: "",
  descripcion: "",
  sector: 0,
  piso: 0,
  standard: [],
  generoHuesped: "",
};
const tipo_huesped = [
  { label: "Sólo mujeres", value: "Solo mujeres" },
  { label: "Sólo hombres", value: "Solo hombres" },
  { label: "Unisex", value: "Unisex" },
];
const Create = ({ campamentos, open2, setOpen2 }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const standares = useSelector(getAllEstandares);
  const [standars, setStandars] = useState([]);
  const [selectedStandar, setSelectedStandar] = useState([]);
  const [age, setAge] = useState("");

  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const letras = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i)
  );

  const handleChange = (event) => {
    setAge(event.target.value);
    setFormData({ ...formData, nombre: event.target.value });
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    setStandars(standares.filter((item) => item.campamentoId == newValue.id));
    if (newValue != null) {
      setFormData({
        ...formData,
        idCampamento: newValue.id,
        sector: 0,
        standard: [],
      });
      setSelectedSector(null);
      setSelectedStandar([]);
    } else {
      setFormData({ ...formData, idCampamento: 0 });
    }
  };
  const handleChangeSector = (event, newValue) => {
    if (newValue != null) {
      setFormData({ ...formData, sector: newValue.id });
      setSelectedSector(newValue);
    } else {
      setFormData({ ...formData, sector: 0 });
    }
  };

  const handleChangeTipo = (event, newValue) => {
    if (newValue != null) {
      setFormData({ ...formData, generoHuesped: newValue.value });
    } else {
      setFormData({ ...formData, generoHuesped: 0 });
    }
  };
  const handleChangeStandar = (event, newValue) => {
    if (newValue != null) {
      // setFormData({...formData, standardId: newValue.id})
      setSelectedStandar(newValue);
      const selectedIds = newValue.map((item) => item.id);
      setFormData({ ...formData, standard: selectedIds });
    } else {
      // setFormData({...formData, standardId: 0})
      setFormData({ ...formData, standard: [] });
    }
  };

  const canSave =
    [
      formData.nombre,
      formData.descripcion,
      formData.n_habitaciones,
      formData.idCampamento,
      formData.piso,
      formData.standard.length > 0,
      formData.generoHuesped,
    ].every(Boolean) && addRequestStatus === "idle";

  const handleSubmit = async () => {
    if (canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus("loading");
        let resp = await dispatch(addNewWing(formData));
        if (resp.payload.data.success) {
          setFormData(data);
          setOpen(false);
          return;
        }
      } catch (error) {
        console.error("Error al crear el Wing", error);
      } finally {
        setAddRequestStatus("idle");
        setOpen2(false);
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          El siguiente <strong>botón</strong> te mostrará un formulario para
          poder registrar un wing.
        </Typography>
        <Button
          variant="contanied"
          size="large"
          className={styles.bgColor}
          onClick={() => setOpen(true)}
        >
          <CircleIcon className={styles.iconButton} />
          Crear Wing
        </Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <LabelImportantIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="span" style={{ fontSize: "24px" }}>
              Crear wing
            </Typography>
            <span className={styles.subTitle}>
              Rellena los siguientes campos para crear el wing.
            </span>
          </div>
        </DialogTitle>

        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>Nombre</label>
              <Select
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="nombre"
                onChange={handleChange}
                style={{ width: "100%" }}
              >
                {letras.map((letra) => (
                  <MenuItem value={letra}>{letra}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={campamentos}
                name="idCampamento"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value) => handleChangeCampamento(e, value)}
                renderInput={(params) => (
                  <TextField {...params} label="Selecciona un campamento" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>N° de Pisos</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="piso"
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>N° de habitaciones</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="n_habitaciones"
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label className={styles.label}>Tipo de huesped</label>
              <Autocomplete
                disablePortal
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                id="campamentos-autocomplete"
                options={tipo_huesped}
                name="tipo_huesped"
                getOptionLabel={(option) => option.label}
                onChange={(e, value) => handleChangeTipo(e, value, "huesped")}
                renderInput={(params) => <TextField {...params} />}
                disableClearable
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <label className={styles.label}>Tipo de standard</label>
              {selectedCampamento ? (
                <Autocomplete
                  disablePortal
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  id="campamentos-autocomplete"
                  options={standars}
                  multiple
                  name="tipo_standar"
                  value={selectedStandar || []}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, value) => handleChangeStandar(e, value)}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecciona un tipo de standard"
                    />
                  )}
                />
              ) : (
                <Autocomplete
                  disablePortal
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  id="campamentos-autocomplete"
                  options={[]}
                  multiple
                  disabled
                  name="tipo_standar"
                  value={[]}
                  getOptionLabel={(option) => option.nombre}
                  disableClearable
                  renderInput={(params) => (
                    <TextField {...params} label="Seleccione un campamento" />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Descripción</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="descripcion"
                type="text"
                multiline
                rows={4}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding: "0 16px" }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained"
                disabled={!canSave}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Crear"
                )}
              </Button>
              <Button
                onClick={() => setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Create;
