import styles from './components.module.css';
import { Backdrop, Box, Card, Container, Grid, Paper, Typography, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { fetchMotivos, getAllMotivos, getMotivosStatus } from '../../redux/features/motivos/motivoSlice';
import { fetchWings, getAllWings, getWingsStatus } from '../../redux/features/wings/wingSlice';
import { fetchEdificios, getAllEdificios, getEdificiosStatus } from '../../redux/features/edificios/edificioSlice';
import { fetchTrabajadoresCMDIC, getAllTrabajadoresCMDIC, getTrabajadoresAll, getTrabajadoresAllStatus, getTrabajadoresCMDICStatus } from '../../redux/features/reservas/reservaSlice';
import ReasignarCMDIC from './components/Reasignar2'
import VerInfoTrabajadorCMDIC from './components/VerInfoTrabajador'
import { useDispatch, useSelector } from 'react-redux';

const DashboardCMDIC = () => {
  
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openReasignar, setOpenReasignar] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const dispatch = useDispatch();

  // const trabajadores = useSelector(getAllTrabajadoresCMDIC);
  const trabajadores = [];
  const traStatus = useSelector(getTrabajadoresCMDICStatus);
  
  const wings = useSelector(getAllWings);
  // const wings = [
  //   {
  //     "id": 14,
  //     "nombre": "A",
  //     "descripcion": "",
  //     "numeroPisos": 3,
  //     "numeroHabitaciones": 0,
  //     "generoHuesped": "Unisex",
  //     "campamento": {
  //       "id": 26,
  //       "nombre": "campamento 2",
  //       "direccion": "santiago",
  //       "descripcion": null,
  //       "plano": null,
  //       "latitud": "-33.3660954",
  //       "longitud": "-70.7514249"
  //     },
  //     "campamentoId": 26,
  //     "standard": [
  //       {
  //         "id": 57,
  //         "nombre": "INDIVIDUAL",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 26
  //       }
  //     ]
  //   },
  //   {
  //     "id": 15,
  //     "nombre": "B",
  //     "descripcion": "",
  //     "numeroPisos": 3,
  //     "numeroHabitaciones": 0,
  //     "generoHuesped": "Unisex",
  //     "campamento": {
  //       "id": 25,
  //       "nombre": "campamento 1",
  //       "direccion": "dsaadsa",
  //       "descripcion": "descripción",
  //       "plano": "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
  //       "latitud": "-33.5281715",
  //       "longitud": "-70.5807978"
  //     },
  //     "campamentoId": 25,
  //     "standard": [
  //       {
  //         "id": 60,
  //         "nombre": "ROTATIVO",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       }
  //     ]
  //   },
  //   {
  //     "id": 16,
  //     "nombre": "C",
  //     "descripcion": "",
  //     "numeroPisos": 3,
  //     "numeroHabitaciones": 0,
  //     "generoHuesped": "Unisex",
  //     "campamento": {
  //       "id": 25,
  //       "nombre": "campamento 1",
  //       "direccion": "dsaadsa",
  //       "descripcion": "descripción",
  //       "plano": "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
  //       "latitud": "-33.5281715",
  //       "longitud": "-70.5807978"
  //     },
  //     "campamentoId": 25,
  //     "standard": [
  //       {
  //         "id": 61,
  //         "nombre": "7X7",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       }
  //     ]
  //   },
  //   {
  //     "id": 17,
  //     "nombre": "D",
  //     "descripcion": "",
  //     "numeroPisos": 3,
  //     "numeroHabitaciones": 0,
  //     "generoHuesped": "Unisex",
  //     "campamento": {
  //       "id": 25,
  //       "nombre": "campamento 1",
  //       "direccion": "dsaadsa",
  //       "descripcion": "descripción",
  //       "plano": "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
  //       "latitud": "-33.5281715",
  //       "longitud": "-70.5807978"
  //     },
  //     "campamentoId": 25,
  //     "standard": [
  //       {
  //         "id": 61,
  //         "nombre": "7X7",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       },
  //       {
  //         "id": 63,
  //         "nombre": "GERENTE",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       }
  //     ]
  //   },
  //   {
  //     "id": 18,
  //     "nombre": "E",
  //     "descripcion": "",
  //     "numeroPisos": 3,
  //     "numeroHabitaciones": 0,
  //     "generoHuesped": "Unisex",
  //     "campamento": {
  //       "id": 25,
  //       "nombre": "campamento 1",
  //       "direccion": "dsaadsa",
  //       "descripcion": "descripción",
  //       "plano": "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
  //       "latitud": "-33.5281715",
  //       "longitud": "-70.5807978"
  //     },
  //     "campamentoId": 25,
  //     "standard": [
  //       {
  //         "id": 61,
  //         "nombre": "7X7",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       },
  //       {
  //         "id": 62,
  //         "nombre": "4X3",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       },
  //       {
  //         "id": 63,
  //         "nombre": "GERENTE",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       },
  //       {
  //         "id": 64,
  //         "nombre": "VIP",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       }
  //     ]
  //   },
  //   {
  //     "id": 19,
  //     "nombre": "F",
  //     "descripcion": "",
  //     "numeroPisos": 3,
  //     "numeroHabitaciones": 0,
  //     "generoHuesped": "Unisex",
  //     "campamento": {
  //       "id": 25,
  //       "nombre": "campamento 1",
  //       "direccion": "dsaadsa",
  //       "descripcion": "descripción",
  //       "plano": "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
  //       "latitud": "-33.5281715",
  //       "longitud": "-70.5807978"
  //     },
  //     "campamentoId": 25,
  //     "standard": [
  //       {
  //         "id": 61,
  //         "nombre": "7X7",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       },
  //       {
  //         "id": 62,
  //         "nombre": "4X3",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       },
  //       {
  //         "id": 63,
  //         "nombre": "GERENTE",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       }
  //     ]
  //   },
  //   {
  //     "id": 20,
  //     "nombre": "G",
  //     "descripcion": "",
  //     "numeroPisos": 3,
  //     "numeroHabitaciones": 0,
  //     "generoHuesped": "Unisex",
  //     "campamento": {
  //       "id": 25,
  //       "nombre": "campamento 1",
  //       "direccion": "dsaadsa",
  //       "descripcion": "descripción",
  //       "plano": "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
  //       "latitud": "-33.5281715",
  //       "longitud": "-70.5807978"
  //     },
  //     "campamentoId": 25,
  //     "standard": [
  //       {
  //         "id": 61,
  //         "nombre": "7X7",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       },
  //       {
  //         "id": 62,
  //         "nombre": "4X3",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       },
  //       {
  //         "id": 63,
  //         "nombre": "GERENTE",
  //         "descripcion": "",
  //         "campamento": null,
  //         "campamentoId": 25
  //       }
  //     ]
  //   }
  // ];

  const winStatus = useSelector(getWingsStatus);
  const motivos = useSelector(getAllMotivos);
  const motStatus = useSelector(getMotivosStatus);

  useEffect(()=>{
    if(motStatus === 'idle'){
      dispatch(fetchMotivos());
    }
    if(winStatus === 'idle'){
      dispatch(fetchWings());
    }
    // if(traStatus === 'idle'){
    //   dispatch(fetchTrabajadoresCMDIC());
    // }
  },[])
  useEffect(()=>{
    console.log('wings', wings);
  },[wings])

  return (

    <div>
      <Backdrop
          sx={{ color: '#fff', zIndex: '100000' }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
      </Backdrop>

        <Box width={1} mt={0} mb={2}>
            <Container className={styles.container} style={{ padding:0 }}>
              
            {/*  
              <Card style={{ width:'100%', padding:'16px', marginBottom:'10px', backgroundColor:'var(--inst-card-bg)', color:'#FFF', border:'1px solid var(--border-orange)' }}>
              <ReasignarCMDIC open2={openReasignar} setOpen2={setOpenReasignar} status={traStatus} trabajadores={trabajadores} wings={wings}/> 
              </Card>
              */}
              {/*
              <Card style={{ width:'100%', padding:'16px', marginBottom:'10px', backgroundColor:'var(--inst-card-bg)', color:'#FFF', border:'1px solid var(--border-orange)' }}>
              <VerInfoTrabajadorCMDIC open2={openInfo} setOpen2={setOpenInfo} status={traStatus} trabajadores={trabajadores} wings={wings} criticidadRequerimientos={motivos} /> 
              </Card>
              */}

            </Container>
        </Box>
    </div>
  )
}

export default DashboardCMDIC