import { Backdrop, Box, Container } from "@mui/material"
import Header from "../../components/header/Header"
import MapIcon from '@mui/icons-material/Map';
import TableCard from "../../components/tableCard/TableCard";
import Create from "./componentsNotificaciones/Create";
import Table from './componentsNotificaciones/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SkeletonTable from "../../components/Skeleton";
import { useState } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from '@mui/material';
import { fetchCategorias, getAllCategorias, getInventariosStatus } from "../../redux/features/inventario/inventarioSlice";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { fetchTraspasosBodegas, getAllTraspasos, getTraspasosStatus } from "../../redux/features/bodegas/bodegaSlice";

const Categorias = () => {

  const dispatch = useDispatch();
  // const categorias = useSelector(getAllCategorias);
//   const sectores = useSelector(getAllSectores);
  const traspasos = useSelector(getAllTraspasos);
  const traspasosStatus = useSelector(getTraspasosStatus);
  const status = useSelector(getInventariosStatus);
  const [count, setCount] = useState(0);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if(traspasosStatus === 'idle'){
    }
    dispatch(fetchTraspasosBodegas());
  }, []);
  

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Notificaciones"
          subtitle="En este módulo, podrás administrar de manera eficiente las solicitudes de traspaso de inventario.">
          {<NotificationsIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>

        <TableCard>
          {/*<Create setOpen2={setOpen2} open2={open2}/> */}
          { traspasosStatus == 'loading' ? <SkeletonTable/> : <Table registros={traspasos} setOpen3={setOpen3} /> }
        </TableCard>
      </Container>
    </Box>
  )
}

export default Categorias