import styles from './components/components.module.css'
import { Backdrop, Box, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchWings, getAllWings, getWingsStatus } from "../../../redux/features/wings/wingSlice";
import SkeletonTable from "../../../components/Skeleton";
import { fetchCampamentos, getAllCampamentos, getCampamentosStatus } from "../../../redux/features/campamentos/campamentoSlice";
import { CircularProgress } from '@mui/material';
import { fetchEstandares, getEstandarStatus } from "../../../redux/features/estandares/estandarSlice";

const Wings = () => {

    const dispatch = useDispatch();
    const wings = useSelector(getAllWings);
    const campamentos = useSelector(getAllCampamentos);
    const status = useSelector(getWingsStatus);
    const statusCamp = useSelector(getCampamentosStatus);
    const statusStand = useSelector(getEstandarStatus);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);

  useEffect(()=>{
    if(status == 'idle'){
        dispatch(fetchWings());
      }
    if(statusCamp == 'idle'){
        dispatch(fetchCampamentos());
      }
    if(statusStand == 'idle'){
        dispatch(fetchEstandares());
      }
  },[])

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestíon de Wings"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con los wings en la plataforma.">
          {<LabelImportantIcon style={{ fontSize:'1.5rem' }} className={styles.iconBgDelete}/>}
        </Header>

        <TableCard>
          <Create campamentos={campamentos} setOpen2={setOpen2} open2={open2} className={styles.iconBg}/>
          { status == 'loading' ? <SkeletonTable/> : <Table wings={wings} setOpen3={setOpen3}/> }
        </TableCard>
      </Container>
    </Box>
  )
}

export default Wings