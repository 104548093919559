import { Box, Container } from "@mui/material"
import Header from "../../components/header/Header"
import TableCard from "../../components/tableCard/TableCard";
import SkeletonTable from "../../components/Skeleton";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import Create from "./components/Create";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from '@mui/material';
import { fetchInventarios, getAllInventarios, getInventariosStatus } from "../../redux/features/inventario/inventarioSlice";

const Articulo = () => {

  const dispatch = useDispatch();
  // const inventario = useSelector(getAllInventarios);
  const status = useSelector(getInventariosStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(()=>{
    if(status === 'idle'){
        dispatch(fetchInventarios());
    }
  },[]);

  const inventario = [
    {
      codigo: 'asdsada123',
      nombre: 'Articulo 1',
      tiempo_reposicion: '2 dias',
      fecha_expiracion: '03-05-2023',
      stock_minimo: 16,
      descripcion: 'Articulo falso',
      estado: true,
    }
  ]

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestíon de articulos"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con los articulos pertenecientes al inventario de la plataforma.">
          {<HolidayVillageIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>

        <TableCard>
          <Create setOpen2={setOpen2} open2={open2}/>
          { status == 'loading' ? <SkeletonTable/> : <Table inventario={inventario ? inventario : []} setOpen3={setOpen3}/>
        }
        </TableCard>
      </Container>
    </Box>
  )
}
export default Articulo