import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { createAxiosInstance } from "../../../api/axios";

const USERS_URL = "/api/v1/Register/admins";
const INVENTARIO_BODEGA_USUARIOS_URL = "/api/v1/Inventario/opciones-ingreso";
const HUESPEDES_URL = "/api/v1/Register/Huesped";
const ADMINS_URL = "/api/v1/Usuario";
const CARGOS_URL = "/api/v1/Register/cargos";
const TURNOS_URL = "/api/v1/Solicitud/Turnos";
const CONTRATOS_URL = "/api/v1/Solicitud/Contratos";
const WORKERS_URL = "/api/v1/Usuario";
const FILTER_WORKERS_URL = "/api/v1/Solicitud/Trabajadores";
const ADD_USER_URL = "/api/v1/Register/admins";
const GET_PASS_URL = "/api/v1/Auth/recoverpassword?email=";
const ADD_HUESPED_URL = "/api/v1/Register/Huesped";
const USERS_LIVE_URL = "/api/v1/Register/rut/";
const ENCARGADOS_LIVE_URL = "/api/v1/Inventario/encargados-bodega?bodegaId=";
const USERS_GET_INFO_URL = "/api/v1/Register/rut/";
const USER_ESSED_GET_INFO_URL = "/api/v1/Solicitud/Trabajadores";
const USERS_GET_INFO_URL_ID = "/api/v1/Register/user/";
const UPDATE_USER_URL = "/api/v1/Register/admins/";
const TRAZABILIDAD_URL = "/api/v1/Auth/Trazabilidad";
const CMDIC_TRABAJADORES_URL = "/api/v1/Solicitud/TrabajadoresCMDIC";
const ASOCIAR_PERSONAL_OFICINA_URL = "/api/v1/";

const GET_NOTIFICATIONS_URL = "/api/v1/Auth/GetNotificaciones";

const axiosInstance = createAxiosInstance();
// ACTIONS
export const fetchTrazabilidad = createAsyncThunk(
  "users/trazabilidad",
  async () => {
    //FETCH DE HUESPEDES
    try {
      const response = await axiosInstance.get(TRAZABILIDAD_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const getNotifications = createAsyncThunk(
  "users/fetchNotificaciones",
  async () => {
    try {
      const resp = await axiosInstance.get(GET_NOTIFICATIONS_URL);
      const sortedNotifications = resp.data.sort(
        (a, b) => new Date(b.fecha) - new Date(a.fecha)
      );
      return sortedNotifications;
    } catch (error) {
      console.log("error al obtener las notificaciones: --> ", error);
    }
  }
);
export const fetchHuespedes = createAsyncThunk(
  "users/fetchHuespedes",
  async () => {
    //FETCH DE HUESPEDES
    try {
      const response = await axiosInstance.get(HUESPEDES_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  //FETCH DE USUARIOS
  try {
    const response = await axiosInstance.get(USERS_URL);
    return response.data;
  } catch (e) {
    return e.message;
  }
});
export const fetchUsersInventarioBodega = createAsyncThunk(
  "users/fetchUsersInventarioBodega",
  async () => {
    //FETCH DE USUARIOS
    try {
      const response = await axiosInstance.get(INVENTARIO_BODEGA_USUARIOS_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchCargos = createAsyncThunk("users/fetchCargos", async () => {
  //FETCH DE USUARIOS
  try {
    const response = await axiosInstance.get(CARGOS_URL);
    return response.data;
  } catch (e) {
    return e.message;
  }
});
export const fetchTurnos = createAsyncThunk("users/fetchTurnos", async () => {
  //FETCH DE USUARIOS
  try {
    const response = await axiosInstance.get(TURNOS_URL);
    return response.data;
  } catch (e) {
    return e.message;
  }
});
export const fetchContratos = createAsyncThunk(
  "users/fetchContratos",
  async () => {
    //FETCH DE USUARIOS
    try {
      const response = await axiosInstance.get(CONTRATOS_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchWorkers = createAsyncThunk("users/fetchWorkers", async () => {
  //FETCH DE TRABAJADORES
  try {
    const response = await axiosInstance.get(WORKERS_URL);
    return response.data;
  } catch (e) {
    return e.message;
  }
});
export const filterWorkers = createAsyncThunk(
  "users/fetchWorkers",
  async (data, { dispatch }) => {
    console.log("llega", data);
    try {
      const response = await axiosInstance.get(
        "/api/v1/Solicitud/Trabajadores",
        {
          params: {
            Contrato: data.contrato,
            Rut: data.rut,
            Nombre: data.nombre,
          },
        }
      );
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const addNewHuesped = createAsyncThunk(
  "users/createHuesped",
  async (initialUser, { dispatch }) => {
    const empresas = initialUser.empresa
      ? initialUser.empresa.map((empresa) => empresa.idEmpresa)
      : [];
    try {
      const response = await axiosInstance.post(ADD_HUESPED_URL, {
        email: initialUser.email,
        rut: initialUser.rut,
        nombre: initialUser.nombre,
        apellido: initialUser.apellido,
        telefono: initialUser.telefono,
        cargoId: initialUser.cargoId ? initialUser.cargoId : null,
        fechaNacimiento: initialUser.fechaNacimiento,
        bodegas: initialUser.bodegas,
        // roles: initialUser.rol,
        // empresas: empresas
      });
      if (response.status === 200) {
        toast.success("Huésped creado correctamente.");
        dispatch(fetchHuespedes());
        return { data: response.data, status: "success" };
      }
      throw new Error("Error al crear el huésped.");
    } catch (e) {
      toast.error("Error al crear al huésped: " + e.response.data);
      throw e;
    }
  }
);
export const asociarPersonalOficina = createAsyncThunk(
  "users/asociarPersonalOficina",
  async (data, { dispatch }) => {
    const rutsEmail = data.ruts.map((item) => ({
      rut: item.rut,
      email: item.email,
    }));

    try {
      console.log("llega", rutsEmail, data);
      const response = await axiosInstance.post(ASOCIAR_PERSONAL_OFICINA_URL, {
        ruts: rutsEmail || [],
        idOficina: data.oficina,
      });
      if (response.status === 200) {
        toast.success("Se ha enviado un correo al personal seleccionado.");
        dispatch(fetchUsers());
        return response.data; // Devolver solo los datos relevantes
      }
      throw new Error("Error al crear el usuario.");
    } catch (e) {
      console.log("error en el catch del slice:", e.response.data);
      toast.error("Error al asignar personal a la oficina:");
      throw e;
    }
  }
);

export const addNewUser = createAsyncThunk(
  "users/createUsers",
  async (initialUser, { dispatch }) => {
    const empresas = initialUser.empresa
      ? initialUser.empresa.map((empresa) => empresa.idEmpresa)
      : [];
    try {
      const response = await axiosInstance.post(ADD_USER_URL, {
        email: initialUser.email,
        rut: initialUser.rut,
        nombre: initialUser.nombre,
        apellido: initialUser.apellido,
        telefono: initialUser.telefono,
        roles: initialUser.rol,
        cargoId: initialUser.cargoId,
        empresas: empresas,
        bodegas: initialUser.bodegas,
      });
      if (response.status === 200) {
        toast.success("Usuario creado correctamente.");
        dispatch(fetchUsers());
        return response.data; // Devolver solo los datos relevantes
      }
      throw new Error("Error al crear el usuario.");
    } catch (e) {
      toast.error("Error al crear al usuario: " + e.response.data);
      throw e;
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (upUser, { dispatch }) => {
    const empresas = upUser.empresas.map((empresa) => empresa.idEmpresa);
    console.log("llega", upUser);
    console.log("empresas", empresas);
    console.log("envio", {
      rut: upUser.rut,
      nombre: upUser.nombre,
      apellido: upUser.apellido,
      cargoId: upUser.cargoId,
      email: upUser.email,
      telefono: upUser.phoneNumber,
      roles: upUser.roles,
      empresas: upUser.cargoId == "10000010" ? null : empresas,
      bodegas: upUser.bodegas,
    });
    try {
      const response = await axiosInstance.put(UPDATE_USER_URL + upUser.id, {
        rut: upUser.rut,
        nombre: upUser.nombre,
        apellido: upUser.apellido,
        cargoId: upUser.cargoId,
        email: upUser.email,
        telefono: upUser.phoneNumber,
        roles: upUser.roles,
        empresas: upUser.cargoId == "10000010" ? null : empresas,
        bodegas: upUser.bodegas,
      });

      if (response.status === 200) {
        toast.success("Usuario actualizado correctamente.");
        dispatch(fetchUsers());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al actualizar al usuario: " + e.response.data);
      return e.message;
    }
  }
);
export const liveSearchByRut = createAsyncThunk(
  "users/liveSearchByRut",
  async (value) => {
    try {
      const response = await axiosInstance.get(USERS_LIVE_URL + value);
      return response; //lanzar data desde aca para quitar error de header
    } catch (error) {}
  }
);
export const listWorkersCMDIC = createAsyncThunk(
  "users/listWorkersCMDIC",
  async (value) => {
    try {
      const response = await axiosInstance.get(CMDIC_TRABAJADORES_URL);
      return response; //lanzar data desde aca para quitar error de header
    } catch (error) {}
  }
);
export const liveSearchEncargadoBodegaByBodegaId = createAsyncThunk(
  "users/liveSearchEncargadoBodegaByBodegaId",
  async (value) => {
    try {
      const response = await axiosInstance.get(ENCARGADOS_LIVE_URL + value);
      return response;
    } catch (error) {}
  }
);
export const liveSearchByRutAdmUsuarios = createAsyncThunk(
  "users/liveSearchByRutAdmUsuarios",
  async (value) => {
    try {
      const response = await axiosInstance.get(USERS_LIVE_URL + value);
      return response.data;
    } catch (error) {}
  }
);
export const liveSearchByRutAndName = createAsyncThunk(
  "users/liveSearchByRutAndName",
  async (value) => {
    try {
      const response = await axiosInstance.get(
        USERS_LIVE_URL + value + "/info"
      );
      return response;
    } catch (error) {}
  }
);
export const getInfoByRut = createAsyncThunk(
  "users/getInfoByRut",
  async (value) => {
    try {
      const response = await axiosInstance.get(
        USERS_GET_INFO_URL + value + "/info"
      );
      return response;
    } catch (error) {}
  }
);
export const getInfoByRutAdmUsuarios = createAsyncThunk(
  "users/getInfoByRutAdmUsuarios",
  async (value) => {
    try {
      const response = await axiosInstance.get(
        USERS_GET_INFO_URL + value + "/info"
      );
      return response.data;
    } catch (error) {}
  }
);
export const getInfoByRutNameContractEssed = createAsyncThunk(
  "users/getInfoByRutEssed",
  async (value) => {
    try {
      const response = await axiosInstance.get(
        USER_ESSED_GET_INFO_URL + "?Rut=" + value
      );
      return response;
    } catch (error) {}
  }
);
export const getInfoById = createAsyncThunk(
  "users/getInfoById",
  async (value) => {
    try {
      const response = await axiosInstance.get(USERS_GET_INFO_URL_ID + value);
      return response;
    } catch (error) {}
  }
);

export const getPass = createAsyncThunk(
  "users/getPass",
  async (data, { dispatch }) => {
    console.log("llega", data);
    try {
      const response = await axiosInstance.post(GET_PASS_URL + data);
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error");
    } catch (e) {
      toast.error("Error: " + e.response.data);
      throw e;
    }
  }
);

// idle : no hay operaciones en curso
const initialState = {
  users: [],
  notificaciones: [],
  bodegaInventarioUsers: [],
  huespedes: [],
  userEssed: {},
  liveUsers: [],
  liveEncargados: [],
  userInfo: [],
  cargos: [],
  trazabilidad: [],
  turnos: [],
  contratos: [],
  workers: [],
  statusUsers: "idle",
  statusTrazabilidad: "idle",
  statusNotificaciones: "idle",
  statusUsersBodegaInventario: "idle",
  statusEncargados: "idle",
  turnosStatus: "idle",
  statusHuesped: "idle",
  statusWorkers: "idle",
  status: "idle", //'idle'|'loading'|'succeeded'|'failed'
  error: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getNotifications.pending, (state, action) => {
        state.statusNotificaciones = "loading";
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.statusNotificaciones = "succeeded";
        if (
          JSON.stringify(state.notificaciones) !==
          JSON.stringify(action.payload)
        ) {
          state.notificaciones = action.payload;
        }
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.statusNotificaciones = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTrazabilidad.pending, (state, action) => {
        state.statusTrazabilidad = "loading";
      }) //fetch
      .addCase(fetchTrazabilidad.fulfilled, (state, action) => {
        state.statusTrazabilidad = "succeeded";
        if (
          JSON.stringify(state.trazabilidad) !== JSON.stringify(action.payload)
        ) {
          //fetch
          state.trazabilidad = action.payload;
        }
      })
      .addCase(fetchTrazabilidad.rejected, (state, action) => {
        //fetch
        state.statusTrazabilidad = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchUsers.pending, (state, action) => {
        state.statusUsers = "loading";
      }) //fetch
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.statusUsers = "succeeded";
        if (JSON.stringify(state.users) !== JSON.stringify(action.payload)) {
          //fetch
          state.users = action.payload;
        }
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        //fetch
        state.statusUsers = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchUsersInventarioBodega.pending, (state, action) => {
        state.statusUsersBodegaInventario = "loading";
      }) //fetch
      .addCase(fetchUsersInventarioBodega.fulfilled, (state, action) => {
        state.statusUsersBodegaInventario = "succeeded";
        if (JSON.stringify(state.users) !== JSON.stringify(action.payload)) {
          //fetch
          state.bodegaInventarioUsers = action.payload;
        }
      })
      .addCase(fetchUsersInventarioBodega.rejected, (state, action) => {
        //fetch
        state.statusUsersBodegaInventario = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchHuespedes.pending, (state, action) => {
        state.statusHuesped = "loading";
      }) //fetch
      .addCase(fetchHuespedes.fulfilled, (state, action) => {
        state.statusHuesped = "succeeded";
        if (
          JSON.stringify(state.huespedes) !== JSON.stringify(action.payload)
        ) {
          //fetch
          state.huespedes = action.payload;
        }
      })
      .addCase(fetchHuespedes.rejected, (state, action) => {
        //fetch
        state.statusHuesped = "failed";
        state.error = action.error.message;
      })
      .addCase(filterWorkers.pending, (state, action) => {
        state.statusWorkers = "loading";
      }) //fetch
      .addCase(filterWorkers.fulfilled, (state, action) => {
        state.statusWorkers = "succeeded";
        if (JSON.stringify(state.workers) !== JSON.stringify(action.payload)) {
          //fetch
          state.workers = action.payload;
        }
      })
      .addCase(filterWorkers.rejected, (state, action) => {
        //fetch
        state.statusWorkers = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCargos.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(fetchCargos.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (JSON.stringify(state.cargos) !== JSON.stringify(action.payload)) {
          //fetch
          state.cargos = action.payload;
        }
      })
      .addCase(fetchCargos.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTurnos.pending, (state, action) => {
        state.turnosStatus = "loading";
      }) //fetch
      .addCase(fetchTurnos.fulfilled, (state, action) => {
        state.turnosStatus = "succeeded";
        if (JSON.stringify(state.turnos) !== JSON.stringify(action.payload)) {
          //fetch
          state.turnos = action.payload;
        }
      })
      .addCase(fetchTurnos.rejected, (state, action) => {
        //fetch
        state.turnosStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchContratos.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(fetchContratos.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (
          JSON.stringify(state.contratos) !== JSON.stringify(action.payload)
        ) {
          //fetch
          state.contratos = action.payload;
        }
      })
      .addCase(fetchContratos.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(liveSearchByRut.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(liveSearchByRut.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (
          JSON.stringify(state.liveUsers) !== JSON.stringify(action.payload)
        ) {
          //fetch
          state.liveUsers = action.payload;
        }
      })
      .addCase(liveSearchByRut.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(liveSearchEncargadoBodegaByBodegaId.pending, (state, action) => {
        state.statusEncargados = "loading";
      }) //fetch
      .addCase(
        liveSearchEncargadoBodegaByBodegaId.fulfilled,
        (state, action) => {
          state.statusEncargados = "succeeded";
          if (
            JSON.stringify(state.liveEncargados) !==
            JSON.stringify(action.payload)
          ) {
            //fetch
            state.liveEncargados = action.payload;
          }
        }
      )
      .addCase(
        liveSearchEncargadoBodegaByBodegaId.rejected,
        (state, action) => {
          //fetch
          state.statusEncargados = "failed";
          state.error = action.error.message;
        }
      )
      .addCase(liveSearchByRutAndName.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(liveSearchByRutAndName.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (
          JSON.stringify(state.liveUsers) !== JSON.stringify(action.payload)
        ) {
          //fetch
          state.liveUsers = action.payload;
        }
      })
      .addCase(liveSearchByRutAndName.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(liveSearchByRutAdmUsuarios.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(liveSearchByRutAdmUsuarios.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (
          JSON.stringify(state.liveUsers) !== JSON.stringify(action.payload)
        ) {
          //fetch
          state.liveUsers = action.payload;
        }
      })
      .addCase(liveSearchByRutAdmUsuarios.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getInfoByRut.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(getInfoByRut.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (JSON.stringify(state.userInfo) !== JSON.stringify(action.payload)) {
          //fetch
          state.userInfo = action.payload;
        }
      })
      .addCase(getInfoByRut.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getInfoByRutAdmUsuarios.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(getInfoByRutAdmUsuarios.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (JSON.stringify(state.userInfo) !== JSON.stringify(action.payload)) {
          //fetch
          state.userInfo = action.payload;
        }
      })
      .addCase(getInfoByRutAdmUsuarios.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getInfoByRutNameContractEssed.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(getInfoByRutNameContractEssed.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (
          JSON.stringify(state.userEssed) !== JSON.stringify(action.payload)
        ) {
          //fetch
          state.userEssed = action.payload;
        }
      })
      .addCase(getInfoByRutNameContractEssed.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        //ADD
        if (action.payload.status === "success") {
          state.status = "succeeded";
          state.users.push(action.payload.data);
        } else {
          state.status = "failed";
        }
      })
      .addCase(addNewUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getPass.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getPass.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        if (action.payload.status === "success") {
          state.status = "succeeded";
          state.users = state.users.map((user) => {
            if (user.id === action.payload.id) {
              return action.payload;
            }
            return user;
          });
        } else {
          state.status = "failed";
        }
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllNotifications = (state) => state.users.notificaciones;
export const getNotificacionesStatus = (state) =>
  state.users.statusNotificaciones;
export const getAllUsers = (state) => state.users.users;
export const getAllTrazabilidad = (state) => state.users.trazabilidad;
export const getAllUsersBodegaInventario = (state) =>
  state.users.bodegaInventarioUsers;
export const getAllHuespedes = (state) => state.users.huespedes;
export const getAllCargos = (state) => state.users.cargos;
export const getAllTurnos = (state) => state.users.turnos;
export const getTurnosStatus = (state) => state.users.turnosStatus;
export const getAllContratos = (state) => state.users.contratos;
export const getAllWorkers = (state) => state.users.workers;
export const getWorkersStatus = (state) => state.users.statusWorkers;
export const getUsersStatus = (state) => state.users.status;
export const getTrazabilidadStatus = (state) => state.users.statusTrazabilidad;
export const getHuespedStatus = (state) => state.users.statusHuesped;
export const getUsersStatusAll = (state) => state.users.statusUsers;
export const getUsersError = (state) => state.users.error;

export const {} = userSlice.actions;
export default userSlice.reducer;
