import { Box, Card, CardContent, Container } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import SetPassForm from './components/SetPassForm';

function SetPass() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const token = searchParams.get('token');
  return (
    <div className="w-full d-flex align-items-center justify-content-center h-full">
        <Box className="h-100 login_box d-flex align-items-center justify-content-center">
            <Container maxWidth="xs">
              <Card style={{ boxShadow: '0px 10px 20px 2px rgba(0, 0, 0, 0.25)', borderRadius:'6px' }}>
                <CardContent className="text-center" style={{ paddingBottom:'0' }}>
                  <img src={process.env.PUBLIC_URL + '/logo_black.png' } alt="loginImg" />
                  <h3 className="font-400">Registra tu contraseña</h3>
                </CardContent>
                <CardContent>
                  <SetPassForm id={id} token={token}/>
                </CardContent>
              </Card>
            </Container>
          </Box>
    </div>
  );
}

export default SetPass;
