import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AuthProvider } from "./context/AppContext";
import { ThemeProvider } from "@mui/material";
import { theme } from "./lib/themeUI";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { msalConfig } from "./authConfig.js";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = msalConfig
  ? new PublicClientApplication(msalConfig)
  : null;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      {msalInstance ? (
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <AuthProvider>
              <ThemeProvider theme={theme}>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
                <ToastContainer />
              </ThemeProvider>
            </AuthProvider>
          </BrowserRouter>
        </MsalProvider>
      ) : (
        <BrowserRouter>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
              <ToastContainer />
            </ThemeProvider>
          </AuthProvider>
        </BrowserRouter>
      )}
    </Provider>
  </React.StrictMode>
);
