import styles from './components.module.css';
import Show from './Show';
import { useEffect, useState } from "react";
import { rotativosHabitacionesColumns, rotativosHabitacionesReservasColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { format } from 'date-fns';
import CalendarMonth from '@mui/icons-material/CalendarMonth';

const Table = ({ habitaciones, setOpenBack, habitacionesFilterModel, handleHabitacionesFilterModelChange, seleccionTablaHabitaciones, setFilterHabitaciones, selectedTrabajadores, changePermanente, filterTrabajadoresTabla, cantidadHabitacionesRestantes }) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = rotativosHabitacionesReservasColumns(setOpen, setSeleItem, setOpenEdit);

  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    if (selectedTrabajadores.trabajador.rut) {
      const filtered = habitaciones.filter(row => {
        if ((row.reservasCMDIC || Array.isArray(row.reservasCMDIC))) {
          return row.reservasCMDIC.every(reserva => {
          const reservaFechaInicio = new Date(reserva.fechaInicio);
          const reservaFechaTermino = new Date(reserva.fechaTermino);
          const trabajadorFechaInicio = new Date(selectedTrabajadores.fechaInicio);
          const trabajadorFechaTermino = new Date(selectedTrabajadores.fechaTermino);
          return (
            trabajadorFechaTermino < reservaFechaInicio || trabajadorFechaInicio > reservaFechaTermino
          );
        });
      }else{
        return true;
      }
    }).filter(it => !it.trabajador);
      setFilteredRows(filtered);
    } else {
      setFilteredRows(habitaciones);
    }
  }, [selectedTrabajadores, habitaciones]);

  return (
    <div style={{ marginTop:'16px' }}>
      <Show open={open} setOpen={setOpen} seleItem={seleItem}/>
        {
          selectedTrabajadores.trabajador.rut && 
          <div style={{ display:'flex', flexDirection:'column' }}>
            <span style={{ display:'flex', alignItems:'center' }}>
              <CalendarMonth/> {filteredRows.length} habitaciones disponibles entre {format(selectedTrabajadores.fechaInicio, 'dd-MM-yyyy')} y {format(selectedTrabajadores.fechaTermino, 'dd-MM-yyyy')}
            </span>
            <span>
              <span style={{ display:'flex', alignItems:'center' }}>
                <CalendarMonth/>
                N° de habitaciones no listadas: {cantidadHabitacionesRestantes}
              </span>
            </span>
          </div>
        }
        <DataGrid
          disableMultipleSelection
          isRowSelectable={(params) => {
            const isSelectable = filteredRows.some(row => row.id === params.id);
            return isSelectable;
          }}
          filterModel={habitacionesFilterModel}
          onFilterModelChange={handleHabitacionesFilterModelChange}
          onRowSelectionModelChange={seleccionTablaHabitaciones}
          getRowId={row=>row.id ? row.id : Math.random() }
          lots={{ toolbar: GridToolbar }}
          // rows={habitaciones}
          rows={filteredRows}
          rowHeight={80}
          columns={finalColumns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15, 20]}
          getRowClassName={(params) => {
            const isSelectable = filteredRows.some(row => row.id === params.id);
            return isSelectable ? '' : styles.nonSelectableRow;
          }}
          onCellClick={(params, event) => {
            if(params.field == 'opciones'){
              event.stopPropagation();
            }
          }}
          // onCellClick={(params, event) => {
          //   console.log('params', params);
          //   let trab = {};
          //   const updatedRows = habitaciones.map((row) => {
          //     if (row.id === params.id) {
          //       if(params.field == 'permanente' && row.trabajador){
          //         const findTrab = filterTrabajadoresTabla.find(trab => trab.habitacion.id === params.row.id);
          //         trab = findTrab;
          //         changePermanente(trab);
          //         event.stopPropagation();
          //         return {
          //           ...row,
          //           [params.field]: !row.permanente
          //         };
          //       } else{
          //         return row
          //       }
          //     }
          //     return row;
          //   });
          //   setFilterHabitaciones(updatedRows);
          // }}
          localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
                labelRowsPerPage: "Filas por página:",
            }
          }}
          classes={{ menuIcon: styles.menuIcon }}
          style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
          sx={{ 
              '& .MuiDataGrid-cell': {
                color: '#2d3037',
                borderBottom: '1px solid #c6c6c6',
              },
              '.nonSelectableRow':{
                backgroundColor: '#f5f5f5',
                color: '#9e9e9e',
                pointerEvents: 'none'
              },
              '& .MuiDataGrid-columnHeader': {
                color: '#2d3037',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-footerContainer': {
                color: '#2d3037',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiTablePagination-root': {
                color: '#2d3037',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-toolbarContainer': {
                color: '#2d3037',
              },
              '& .MuiDataGrid-menu': {
                color: '#2d3037',
              },
              '& .MuiSvgIcon-root':{
                color: '#9ca91c',
              },
              '& .MuiTablePagination-actions': {
                color: '#2d3037', // Color for pagination actions
              },
              '& .MuiTablePagination-select': {
                color: '#2d3037', // Color for pagination select
              },
              '& .MuiTablePagination-selectLabel': {
                color: '#2d3037', // Color for pagination select label
              },
              '& .MuiTablePagination-displayedRows': {
                color: '#2d3037', // Color for displayed rows text
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                color: 'red',
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                color: 'red',
              },
          }}
          slotProps={{
                  toolbar:{
                    sx:{
                      color:'#FFF'
                    }
                  },
                  pagination: {
                    sx: {
                      color: '#f19d38',
                    },
                  },
                  menu: {
                    sx: {
                      color: '#f19d38',
                    },
                  },
          }}
        />
    </div>
  )
}

export default Table