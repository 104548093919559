import styles from "./components.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { addNewEdificio } from "../../../../redux/features/edificios/edificioSlice";
import { getAllEstandares } from "../../../../redux/features/estandares/estandarSlice";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CircleIcon from "@mui/icons-material/Circle";

const data = {
  numero: "",
  campamento: 0,
  sector: null,
  n_pisos: 0,
  n_habitaciones: 0,
  descripcion: "",
  type: "",
  generoHuesped: "",
  standard: [],
};
const tipo_huesped = [
  { label: "Sólo mujeres", value: "Solo mujeres" },
  { label: "Sólo hombres", value: "Solo hombres" },
  { label: "Unisex", value: "Unisex" },
];

const Create = ({ campamentos, open2, setOpen2, sectores }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const standares = useSelector(getAllEstandares);
  const [standars, setStandars] = useState([]);
  const [selectedStandar, setSelectedStandar] = useState([]);

  const [formData, setFormData] = useState(data);
  const [nombreFull, setNombreFull] = useState("");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [check, setCheck] = useState(false);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    setStandars(standares.filter((item) => item.campamentoId == newValue.id));
    setNombreFull("");
    if (newValue != null) {
      setFormData({
        ...formData,
        campamento: newValue.id,
        sector: 0,
        standardId: 0,
      });
      setSelectedSector(null);
      setSelectedStandar([]);
    } else {
      setFormData({ ...formData, campamento: 0 });
    }
  };
  const handleChangeSector = (event, newValue) => {
    if (newValue != null) {
      setFormData({ ...formData, sector: newValue.id });
      setSelectedSector(newValue);
    } else {
      setFormData({ ...formData, sector: 0 });
    }
  };
  const handleChangeTipo = (event, newValue) => {
    if (newValue != null) {
      setFormData({ ...formData, generoHuesped: newValue.value });
    } else {
      setFormData({ ...formData, generoHuesped: 0 });
    }
  };
  const handleChangeStandar = (event, newValue) => {
    if (newValue != null) {
      // setFormData({...formData, standardId: newValue.id})
      setSelectedStandar(newValue);
      const selectedIds = newValue.map((item) => item.id);
      setFormData({ ...formData, standard: selectedIds });
    } else {
      // setFormData({...formData, standardId: 0})
      setFormData({ ...formData, standard: [] });
    }
  };
  const canSave =
    [
      formData.numero,
      formData.sector,
      formData.campamento,
      formData.generoHuesped,
      formData.standard.length > 0,
      formData.n_habitaciones,
      formData.n_pisos,
      check,
    ].every(Boolean) && addRequestStatus === "idle";

  const handleSubmit = async () => {
    if (canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus("loading");
        let resp = await dispatch(addNewEdificio(formData));
        if (resp.payload.status == "success") {
          setFormData(data);
          setSelectedCampamento(null);
          setSelectedSector(null);
          setSelectedStandar(null);
          setOpen(false);
          return;
        }
      } catch (error) {
        console.error("Error al crear el edificio", error);
      } finally {
        setAddRequestStatus("idle");
        setOpen2(false);
      }
    }
  };

  useEffect(() => {
    if (formData.n_pisos < 0 || formData.n_habitaciones < 0) {
      toast.error("No se pueden ingresar valores negativos.");
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [formData.n_pisos, formData.n_habitaciones]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          El siguiente <strong>botón</strong> te mostrará un formulario para
          poder registrar un pabellón.
        </Typography>
        <Button
          variant="contanied"
          size="large"
          className={styles.bgColor}
          onClick={() => setOpen(true)}
        >
          <CircleIcon className={styles.iconButton} />
          Crear pabellón
        </Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <ApartmentIcon className={styles.iconBgCreate} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              Crear pabellón
            </Typography>
            <span className={styles.subTitle}>
              Rellena los siguientes campos para crear el pabellón.
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          {nombreFull.split(" ").filter((w) => w.trim() !== "") &&
          formData.sector ? (
            <Typography
              variant="span"
              style={{ color: "#2d3037", fontWeight: "600", fontSize: "14px" }}
            >
              El pabelllón se guardará como "{nombreFull}"
            </Typography>
          ) : (
            ""
          )}
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={4}>
              <label className={styles.label}>Número</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="numero"
                type="number"
                value={formData.numero}
                onChange={(e) => {
                  setNombreFull((prev) => {
                    const splitNombre = prev.split(" ");
                    splitNombre[0] = e.target.value;
                    return splitNombre.join(" ");
                  });
                  onChange(e);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={campamentos}
                name="campamento"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value) => handleChangeCampamento(e, value)}
                renderInput={(params) => (
                  <TextField {...params} label="Selecciona un campamento" />
                )}
              />
            </Grid>
            {selectedCampamento && (
              <Grid item xs={12} sm={12}>
                <label className={styles.label}>Sector</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  disablePortal
                  disableClearable
                  id="sectores-autocomplete"
                  name="sector"
                  value={selectedSector}
                  options={sectores}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, value) => {
                    if (value !== null) {
                      setNombreFull(formData.numero + " " + value.nombre);
                      handleChangeSector(e, value);
                    } else {
                      setNombreFull(formData.numero);
                      handleChangeSector(e, value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona un sector" />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>Número de pisos</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="n_pisos"
                type="number"
                placeholder="0"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>Número de habitaciones</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="n_habitaciones"
                type="number"
                placeholder="0"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label className={styles.label}>Tipo de huesped</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                disablePortal
                id="campamentos-autocomplete"
                options={tipo_huesped}
                name="tipo_huesped"
                getOptionLabel={(option) => option.label}
                onChange={(e, value) => handleChangeTipo(e, value, "huesped")}
                renderInput={(params) => <TextField {...params} />}
                disableClearable
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <label className={styles.label}>Tipo de standard</label>
              {selectedCampamento ? (
                <Autocomplete
                  disablePortal
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                    "& .MuiChip-deleteIcon": {
                      color: "#2d3037",
                    },
                    "& .MuiChip-label": {
                      color: "#2d3037",
                    },
                    "& .MuiButtonBase-root": {
                      backgroundColor: "#ececec",
                    },
                  }}
                  id="campamentos-autocomplete"
                  options={standars}
                  name="tipo_standar"
                  style={{ color: "#FFF" }}
                  multiple
                  value={selectedStandar || []}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, value) => handleChangeStandar(e, value)}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecciona un tipo de standard"
                    />
                  )}
                />
              ) : (
                <Autocomplete
                  disablePortal
                  disabled
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                    "& .MuiChip-deleteIcon": {
                      color: "#2d3037",
                    },
                    "& .MuiChip-label": {
                      color: "#2d3037",
                    },
                    "& .MuiButtonBase-root": {
                      backgroundColor: "#ececec",
                    },
                  }}
                  id="campamentos-autocomplete"
                  options={[]}
                  name="tipo_standar"
                  style={{ color: "#FFF" }}
                  multiple
                  getOptionLabel={(option) => option.nombre}
                  disableClearable
                  renderInput={(params) => (
                    <TextField {...params} label="Seleccione un campamento" />
                  )}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained"
                disabled={!canSave}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Crear"
                )}
              </Button>
              <Button
                onClick={() => setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Create;

{
  /*<Grid item  xs={12} sm={12}>
              {selectedCampamento && (
                <>
                  <label className={styles.label}>Wing</label>
                  <Autocomplete
                    disablePortal
                    id="sectores-autocomplete"
                    name="wing"
                    value={selectedWing}
                    options={selectedSector ?  selectedSector?.wings ? selectedSector?.wings : [] : []}
                    getOptionLabel={(option) => option.nombre}
                    onChange={(e, value) => handleChangeWing(e, value)}    
                    renderInput={(params) => <TextField {...params} label="Selecciona un wing/pabellón" />}
                  />
                </>
              )}
              </Grid>
             */
}
