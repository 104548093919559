import styles from './components/components.module.css'
import { Backdrop, Box, Container, CircularProgress } from "@mui/material"
import Header from "../../../components/header/Header"
import ExtensionIcon from '@mui/icons-material/Extension';
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchEspacios, getAllEspacios, getEspaciosStatus } from "../../../redux/features/espacios/espacioSlice";
import SkeletonTable from "../../../components/Skeleton";
import { fetchSector, fetchSectores, getAllSectores, getSectoresStatus } from "../../../redux/features/sectores/sectorSlice";
import { fetchCampamentos, getAllCampamentos, getCampamentosStatus } from "../../../redux/features/campamentos/campamentoSlice";
import { toast } from "react-toastify";
import { fetchEdificios, fetchPabellon, getAllEdificios, getEdificiosStatus } from "../../../redux/features/edificios/edificioSlice";
import { fetchWing, fetchWings, getAllWings, getWingsStatus } from "../../../redux/features/wings/wingSlice";
import { fetchOtros, getAllOtros, getOtrosStatus } from '../../../redux/features/otros/otroSlice';

const Espacios = () => {

  const dispatch = useDispatch();
  const espacios = useSelector(getAllEspacios);
  const status = useSelector(getEspaciosStatus);
  const sectores = useSelector(getAllSectores);;
  const sectoresStatus = useSelector(getSectoresStatus);
  const campamentos = useSelector(getAllCampamentos);
  const camStatus = useSelector(getCampamentosStatus);
  const wings = useSelector(getAllWings);
  const wingStatus = useSelector(getWingsStatus);
  const pabellones = useSelector(getAllEdificios);
  const pabStatus = useSelector(getEdificiosStatus);
  const otros = useSelector(getAllOtros);
  const otrosStatus = useSelector(getOtrosStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(()=>{
    if(status == 'idle'){
      dispatch(fetchEspacios());
    }
    if(camStatus == 'idle'){
      dispatch(fetchCampamentos());
    }
    if(sectoresStatus == 'idle'){
      dispatch(fetchSectores());
    }
    if(wingStatus == 'idle'){
      dispatch(fetchWings());
    }
    if(pabStatus == 'idle'){
      dispatch(fetchEdificios());
    }
    if(otrosStatus == 'idle'){
      dispatch(fetchOtros());
    }
  },[]);
  
  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestíon espacios comunes"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con los espacios comunes en la plataforma.">
          {<ExtensionIcon style={{ fontSize:'1.5rem' }} className={styles.iconBgDelete}/>}
        </Header>

        <TableCard>
          <Create campamentos={campamentos} setOpen2={setOpen2} open2={open2} sectores={sectores} wings={wings} pabellones={pabellones} otros={otros}/>
          { status == 'loading' ? <SkeletonTable/> : <Table espacios={espacios} setOpen3={setOpen3}/> }
        </TableCard>
      </Container>
    </Box>
  )
}

export default Espacios