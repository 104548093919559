import styles from '../components.module.css';
import { Button, Grid, Typography, capitalize } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SkeletonTable from "../../../../../components/Skeleton";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { trabajadoresReservaColumns } from "../../../../../lib/columns";
import { fetchTrabajadores, getAllTrabajadores, getTrabajadoresStatus } from '../../../../../redux/features/reservas/reservaSlice';
import Edit from '../../componentsReservas/Edit';

const Detalle = ({ reserva, checkedState, setCheckedState, setCheckedHabitaciones, asignar, setOpenDrawer }) => {
    
    const [seleItem, setSeleItem] = useState();
    const [open3, setOpen3] = useState(false);
    const [open, setOpen] = useState(false);
    const [check, setCheck] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const finalColumns = trabajadoresReservaColumns(setOpen, setSeleItem);
    const trabajadores = useSelector(getAllTrabajadores)
    const status = useSelector(getTrabajadoresStatus);
    
    const selectCheck = true // variable para salir del paso // modficar esto 

    const dispatch = useDispatch();
    
    
    const handleRowSelectionChange = (selectionModel) => {
        const rutUsuarios = selectionModel.map((id) => {
            const row = trabajadores.find((row) => row.id === id);
            return {id: row.id ,rutUsuario: row.rutUsuario, nombre: row.nombre, sexo: row.sexo, cargo: row.cargo, acreditacion_vigente: row.acreditacion_vigente};
          });
        setCheckedState(rutUsuarios);
      };

    useEffect(()=>{
        if(status == 'idle'){
            if(reserva){
                dispatch(fetchTrabajadores(reserva?.id))
            }
        }
    },[reserva])


    const reservas = 
        {
          id:1,
          reservas : [
            {
                id:1,
              campamentoId: 2,
              campamento:{
                id:2,
                nombre: 'campamento 1',
              },
              wingId:2,
              wing:{
                id:2,
                nombre: 'WingB',
                campamentoId: 2
              },
              piso:2,   
              habitacionId:2,
              habitacion: {
                id:1,
                numero:2
                },
              trabajador: {
                rut: 1234,
                nombre: 'Juan Uno',
                cargo: 'Administrador de contrato',
              },
              jornada: 'Diurna',
              turno:{
                idTurno:1,
                descripcion:"Administrativo Ciudad",
                jornadasLaborales:5,
                jornadasDescanso:2,
                duracionCiclo:7,
                tipoJornada:"DIURNA"
              },
            }, 
            {
                id:2,
              campamentoId: 1,
              campamento:{
                id:1,
                nombre: 'campamento 2',
              },
              wing: {
                id:1,
                nombre: 'WingA',
                campamentoId: 1
              },
              wingId:1,
              piso:3,   
              habitacionId:3,
              habitacion: {
                id:3,
                numero:4
                },
              trabajador: {
                rut: 6543,
                nombre: 'Humberto',
                cargo: 'Administrador de contrato',
              },
              jornada: 'Diurna',
              turno:{
                idTurno:1,
                descripcion:"Administrativo Ciudad",
                jornadasLaborales:5,
                jornadasDescanso:2,
                duracionCiclo:7,
                tipoJornada:"DIURNA"
              },
            }, 
          ],
          trabajadores:[
            {
              rut: 1234,
              nombre: 'Juan Uno',
              cargo: 'Administrador de contrato',
              jornada: 'Diurna',
              turno:{
                idTurno:1,
                descripcion:"Administrativo Ciudad",
                jornadasLaborales:5,
                jornadasDescanso:2,
                duracionCiclo:7,
                tipoJornada:"DIURNA"
              },
            },
            {
              rut: 4312,
              nombre: 'Pedro Uno',
              cargo: 'Administrador de aplicación',
              jornada: 'Diurna',
              turno:{
                idTurno:1,
                descripcion:"Administrativo Ciudad",
                jornadasLaborales:5,
                jornadasDescanso:2,
                duracionCiclo:7,
                tipoJornada:"DIURNA"
              },
            },
            {
              rut: 6543,
              nombre: 'Humberto',
              cargo: 'Administrador de aseo',
              jornada: 'Diurna',
              turno:{
                idTurno:1,
                descripcion:"Administrativo Ciudad",
                jornadasLaborales:5,
                jornadasDescanso:2,
                duracionCiclo:7,
                tipoJornada:"DIURNA"
              },
            },
            {
              rut: 5890,
              nombre: 'Antonio',
              cargo: 'Recepcionista',
              jornada: 'Diurna',
              turno:{
                idTurno:1,
                descripcion:"Administrativo Ciudad",
                jornadasLaborales:5,
                jornadasDescanso:2,
                duracionCiclo:7,
                tipoJornada:"DIURNA"
              },
            },
          ],
          wing:{
            id:1,
            nombre:'WingA'
          }
        }
      
    
      const habitaciones = [
        {
          id:1,
          numero:3,
          piso:1,
          wingId:1,
        },
        {
          id:2,
          numero:2,
          piso:1,
          wingId:2,
        },
        {
          id:3,
          numero:4,
          piso:5,
          wingId:1,
        },
        {
          id:4,
          numero:5,
          piso:4,
          wingId:2,
        },
      ]
    
      const campamentos = [
        {
          id:1,
          nombre: 'campamento 2',
        },
        {
          id:2,
          nombre: 'campamento 1',
        },
      ];
      const wings = [
        {
          id:1,
          nombre: 'WingA',
          campamentoId: 1
        },
        {
          id:2,
          nombre: 'WingB',
          campamentoId: 2
        },
        {
          id:3,
          nombre: 'WingC',
          campamentoId: 3
        },
        {
          id:4,
          nombre: 'WingD',
          campamentoId: 2
        }
      ]

  return (
    <Grid container spacing={1}>

        {
            check && <Edit openEdit={check} setOpenEdit={setCheck} seleItem={reservas} setOpen3={setOpen3} habitaciones={habitaciones} campamentos={campamentos} wings={wings} selectCheck={selectCheck}/>
        }

        <Info reserva={reserva}/>
        {
            status == 'loading' 
            ? <SkeletonTable/>
            :
            <>
                <Grid item xs={12} sm={12} mt={2}>
                    <DataGrid
                        getRowId={row=>row.id ? row.id : Math.random() }
                        lots={{ toolbar: GridToolbar }}
                        rows={trabajadores && trabajadores ? trabajadores : []}
                        columns={finalColumns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        isRowSelectable={(params) => params.row.acreditacion_vigente == true}
                        localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        noRowsLabel: "No hay datos disponibles",
                        pagination: {
                            labelRowsPerPage: "Filas por página:",
                        }
                        }}
                        classes={{ menuIcon: styles.menuIcon }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} mt={2} >
                    <Button
                        onClick={()=> setOpenDrawer(true)}
                        className={styles.bgColor}
                        variant="contained" 
                        disabled={checkedState.length == 0}
                        >
                        Repetir solicitud de reserva
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} mt={2} style={{ textAlign:'end' }}>
                    <Button
                        onClick={()=> setCheck(true)}
                        className={styles.bgColorOrange}
                        variant="contained" 
                        >
                        Extender Reserva
                    </Button>
                </Grid>
            </>
        }
    </Grid>
  )
}

export default Detalle

const Info = ({reserva}) => {
    function obtenerFechaValida(f) {
        const fecha = new Date(f);
        return fecha.toLocaleString();
      }
    return (
        <>
        {
            Object.entries(reserva).map(([key, value], i) => (
                <Grid item xs={12} sm={3} key={i}>
                <Typography variant="span">
                {capitalize(
                  key === 'cantidad_trabajadores' ? 'cantidad trabajadores' :
                  key === 'fechaSolicitud' ? 'fecha solicitud' :
                  key === 'nombreSolicitante' ? 'nombre solicitante' :
                  key === 'apellidoSolicitante' ? 'apellido solicitante' :
                  key === 'emailSolicitante' ? 'email solicitante' :
                  key === 'fechaIngreso' ? 'fecha ingreso' :
                  key === 'fechaSalida' ? 'fecha salida' :
                  key === 'descripcion' ? 'Descripción' :
                  key === 'estado' ? 'Estado' :
                  key === 'id' ? 'Id' :
                  key === 'razonRechazo' ? 'Razón rechazo' :
                  ''
                )}
              </Typography>
              
                    <Typography variant="span">
                        <strong>
                        {
                            key === 'solicitanteId' ? '' :
                            value === null ? ' : -' :
                            key === 'fechaIngreso' ? ' : '+ obtenerFechaValida(value) :
                            key === 'fechaSolicitud' ? ': '+ obtenerFechaValida(value) :
                            key === 'fechaSalida' ? ' : '+ obtenerFechaValida(value) :
                            ' : '+value
                }</strong></Typography>
                </Grid>
            ))   
        }
        </>
    )
}