import styles from "./components.module.css";
import Drawer from "@mui/material/Drawer";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateCampamento } from "../../../../redux/features/campamentos/campamentoSlice";
import { useEffect } from "react";
import { MuiFileInput } from "mui-file-input";
import Map from "./Map";

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [value, setValue] = useState(null);
  const [formData, setFormData] = useState({ latitud: "", longitud: "" });
  const [position, setPosition] = useState(null);
  const [newImage, setNewImage] = useState(null);

  useEffect(() => {
    if (seleItem) {
      setFormData(seleItem);
    }
  }, [seleItem]);

  useEffect(() => {
    if (formData && formData.latitud && formData.longitud) {
      setPosition({
        lat: parseFloat(formData.latitud),
        lng: parseFloat(formData.longitud),
      });
    }
  }, [formData]);

  const dispatch = useDispatch();

  const handleChange = (newValue) => {
    setValue(newValue);
    setFormData({ ...formData, FilePlano: newValue });

    if (newValue) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImage(reader.result);
      };
      reader.readAsDataURL(newValue);
    } else {
      setNewImage(null);
    }
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const canSave =
    [formData?.nombre, formData?.direccion].every(Boolean) &&
    addRequestStatus === "idle";

  const handleEdit = async () => {
    try {
      setOpen3(true);
      setAddRequestStatus("loading");
      let resp = await dispatch(updateCampamento(formData));
      if (resp.payload.data.success) {
        setOpenEdit(false);
      }
    } catch (error) {
      toast.error("Error al actualizar el campamento.");
      console.error("Error al actualizar el campamento", error.message);
    } finally {
      setOpen3(false);
      setAddRequestStatus("idle");
    }
  };

  return (
    <Drawer
      anchor="right"
      open={openEdit}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "85%", md: "80%" },
          backgroundColor: "#efefef",
          color: "#2d3037",
          borderRadius: "0",
        },
      }}
      onClose={() => setOpenEdit(false)}
    >
      {seleItem && (
        <>
          <DialogTitle>
            <Grid container={true} spacing={1} p={2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <ErrorIcon
                  style={{ fontSize: "3rem" }}
                  className={styles.iconBg}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h6" className={styles.textIcon}>
                    Actualizar campamento {seleItem?.nombre}
                  </Typography>
                  <Typography variant="span" className={styles.textIconSpan}>
                    Modifica los campos para actualizar el campamento
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container={true} spacing={1} p={2}>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Nombre</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="nombre"
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  type="text"
                  value={formData?.nombre}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              {/*<Grid item  xs={12} sm={6}>
                                <label className={styles.label}>Dirección</label>
                                <TextField
                                className={styles.textField}
                                variant="outlined"
                                fullWidth
                                name='direccion'
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color:'#2d3037',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color:'#2d3037',
                                    },
                                  }}
                                type="text"
                                value={formData?.direccion}
                                onChange={(e) => onChange(e)}
                                />
                            </Grid> */}
              {/* MODIFICAR IMAGEN ACTUAL AL SUBIR LA NUEVA IMAGEN */}
              <Grid item xs={12} sm={12}>
                <label className={styles.label}>Descripción (opcional)</label>
                <TextField
                  className={styles.textField}
                  variant="outlined"
                  fullWidth
                  name="descripcion"
                  type="text"
                  multiline
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  rows={4}
                  maxRows={4}
                  value={formData?.descripcion ? formData?.descripcion : ""}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label
                  className={styles.label}
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <CloudUploadIcon style={{ height: "20px" }} /> Imagen del
                  plano (opcional)
                </label>
                <MuiFileInput
                  inputProps={{ accept: "image/png, image/jpeg, image/jpg" }}
                  style={{ width: "100%", color: "#FFF", marginBottom: "5px" }}
                  className={styles.textField}
                  value={value}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                      borderColor: "#FFF",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFF",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#FFF",
                    },
                    "&:hover .MuiInput-underline:before": {
                      borderBottomColor: "#FFF",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#2d3037",
                    },
                  }}
                  onChange={handleChange}
                  helperText="Extensiones validas: .jpg, .jpeg, .png"
                />
                {newImage ? (
                  <img
                    style={{ height: "auto", width: "100%" }}
                    src={newImage}
                    alt="img_plano"
                  />
                ) : formData?.plano ? (
                  <img
                    style={{ height: "auto", width: "100%" }}
                    src={`${process.env.REACT_APP_API_URL}${formData?.plano}`}
                    alt="img_plano"
                  />
                ) : (
                  ""
                )}
              </Grid>

              <Map
                position={position}
                setPosition={setPosition}
                formData={formData}
                setFormData={setFormData}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container={true}>
              <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                <Button
                  onClick={handleEdit}
                  className={styles.bgColorEdit}
                  variant="contained"
                  disabled={!canSave}
                >
                  {addRequestStatus == "loading" ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Actualizar"
                  )}
                </Button>
                <Button
                  onClick={() => setOpenEdit(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )}
    </Drawer>
  );
}
