import { useEffect, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Grid, Dialog, DialogContent, DialogActions, Button } from '@mui/material';

const Imagenes = ({ registros }) => {
  const [fotos, setFotos] = useState([]);
  const [data, setData] = useState({ img: '', i: 0 });
  const [open, setOpen] = useState(false); // Variable para abrir y cerrar modal

  useEffect(() => {
    const newPositions = registros.map(item => item.plano);
    setFotos(newPositions);
  }, [registros]);

  const viewImage = (img, i) => {
    setData({ img, i });
    if(setOpen != true){
      setOpen(true);
    }
  };

  return (
    <>
      {data.img && (
        <Dialog
          open={open}
          maxWidth="lg"
          fullWidth
          onBackdropClick={() => {
            setOpen(false);
          }}
          onClose={() => {
            setOpen(false);
          }}
          PaperProps={{
            sx: {
             backgroundColor:'#2d3037',
             color:'#FFF',
             borderRadius:'0'
            }
          }}
        >
          <DialogContent style={{ padding: '10px', textAlign: 'center' }}>
            <Grid container={true}>
              <Grid item xs={12}>
                <img
                  src={`${process.env.REACT_APP_API_URL}${data.img}`}
                  style={{ maxWidth: '100%', maxHeight: '100vh' }}
                  alt='Imagen ampliada'
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
          <Button onClick={()=> setOpen(false)} style={{ color:'#FFF' }}>
            Cerrar
          </Button>
          </DialogActions>
        </Dialog>
      )}
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry gutter='20px'>
          {fotos.map((image, index) => (
            <img
              key={index}
              style={{ height: '100%', width: '100%', display: 'block', cursor: 'pointer' }}
              src={`${process.env.REACT_APP_API_URL}${image}`}
              alt='Imagen no disponible'
              onClick={() => viewImage(image, index)}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </>
  );
};

export default Imagenes;
