import styles from './components/components.module.css'
import { Backdrop, Box, Container, CircularProgress } from "@mui/material"
import SkeletonTable from "../../../components/Skeleton";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import Header from "../../../components/header/Header";
import { useEffect, useState } from "react";
import TableCard from "../../../components/tableCard/TableCard";
import Table from "./components/Table";
import Create from "./components/Create";
import { useDispatch, useSelector } from "react-redux";
import { fetchMotivos, getAllMotivos, getMotivosStatus } from "../../../redux/features/motivos/motivoSlice";

const CriticidadRequemientos = () => {

  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const requerimientos = useSelector(getAllMotivos);
  const status = useSelector(getMotivosStatus);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if(status == 'idle'){
      dispatch(fetchMotivos());
    }
  }, [])
  

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Criticidad de Requerimientos de Hotelería"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con los requerimientos de hotelería en la plataforma.">
          {<TroubleshootIcon style={{ fontSize:'1.5rem' }} className={styles.iconBgDelete}/>}
        </Header>
        <TableCard>
          <Create setOpen2={setOpen2} open2={open2} requerimientos={requerimientos}/>
          { status == 'loading' ? <SkeletonTable/> : <Table requerimientos={requerimientos ? requerimientos : []} setOpen3={setOpen3} open3={open3}/>
        }
        </TableCard>

      </Container>
    </Box>
  )
}

export default CriticidadRequemientos