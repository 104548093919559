import styles from './components.module.css';
import { useEffect, useState } from "react";
import { rotativosTrabajadoresReservasColumns, rotativosTrabajadoresConTrabColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { Box } from "@mui/material"
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const TablePorAsignar = ({ trabajadores, setOpen3, selectedTrabajadores, handleRowSelectionModelChange, setFilterTrabajadoresTabla, changePermanente }) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = rotativosTrabajadoresReservasColumns(setOpen, setSeleItem, setOpenEdit);
  //MODIFICAR LAS rotativosTrabajadoresConTrabColumns DEPENDIENDO DEL ARCHIVO RESERVACMDIC
  return (
    <div style={{ marginTop:'16px' }}>
        {/*<Box sx={{ width: '100%' }}>
          <Collapse in={openAlert}>
              <Alert
                severity="info"
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setOpenAlert(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
              >
                Selecciona el campo "Permanente" de la fila correspondiente (que tenga una habitación asignada) para modificar su valor.
              </Alert>
          </Collapse>
        </Box> */}
        <DataGrid
          checkboxSelection={false}
          rowSelectionModel={selectedTrabajadores?.trabajador ? selectedTrabajadores.trabajador.id : []}
          onRowSelectionModelChange={(selection) => {
            const selected = selection.length > 0 ? selection[0] : '';
            handleRowSelectionModelChange(selected);
          }}
          getRowId={(row) => row.trabajador.id ?? Math.random()}
          lots={{ toolbar: GridToolbar }}
          rows={trabajadores}
          columns={finalColumns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15, 20]}
          onCellClick={(params, event) => {
            console.log('params', params);
            if(params.field === 'permanente' && params.row.habitacion){
              event.stopPropagation();
              const updatedRows = trabajadores.map((row) => {
                if (row.trabajador.id === params.id) {
                  changePermanente(row);
                  return {
                    ...row,
                    habitacion: {
                      ...row.habitacion,
                      permanente: !row.habitacion.permanente
                    }
                  };
                }
                return row;
              });
              setFilterTrabajadoresTabla(updatedRows);

            }
          }}
          localeText={{
              ...esES.components.MuiDataGrid.defaultProps.localeText,
              noRowsLabel: "No hay datos disponibles",
              pagination: {
                  labelRowsPerPage: "Filas por página:",
              }
          }}
          classes={{ menuIcon: styles.menuIcon }}
          style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
          sx={{ 
              '& .MuiDataGrid-cell': {
                color: '#2d3037',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeader': {
                color: '#2d3037',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-footerContainer': {
                color: '#2d3037',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiTablePagination-root': {
                color: '#2d3037',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-toolbarContainer': {
                color: '#2d3037',
              },
              '& .MuiDataGrid-menu': {
                color: '#2d3037',
              },
              '& .MuiSvgIcon-root':{
                color: '#9ca91c',
              },
              '& .MuiTablePagination-actions': {
                color: '#2d3037', // Color for pagination actions
              },
              '& .MuiTablePagination-select': {
                color: '#2d3037', // Color for pagination select
              },
              '& .MuiTablePagination-selectLabel': {
                color: '#2d3037', // Color for pagination select label
              },
              '& .MuiTablePagination-displayedRows': {
                color: '#2d3037', // Color for displayed rows text
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                color: 'red',
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                color: 'red',
              },
          }}
          slotProps={{
                  toolbar:{
                    sx:{
                      color:'#FFF'
                    }
                  },
                  pagination: {
                    sx: {
                      color: '#f19d38',
                    },
                  },
                  menu: {
                    sx: {
                      color: '#f19d38',
                    },
                  },
          }}
        />
    </div>
  )
}

export default TablePorAsignar