import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Button, Card, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, Select, TextField, Typography, capitalize } from '@mui/material';
import { useState } from 'react';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import { useDispatch, useSelector } from 'react-redux';
import { reservasTrabajadadoresRecepcionistaColumns } from "../../../lib/columns";
import ApartmentIcon from "@mui/icons-material/Apartment";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useEffect } from 'react';
import {  esES, DataGrid } from '@mui/x-data-grid';
import { aprobarSolicitud, cancelarSolicitud, pendienteSolicitud, rechazarSolicitud } from '../../../redux/features/reservas/reservaSlice';
import { format, formatDate } from 'date-fns';
import { createAxiosInstance } from '../../../api/axios';
import CustomToolbar from '../../../components/SlotsToolBar';
import SkeletonTable from '../../../components/Skeleton';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import AdjustIcon from '@mui/icons-material/Adjust';
import InfoIcon from '@mui/icons-material/Info';

export default function Show({ open, setOpen, seleItem }) {

    const dispatch = useDispatch();
    const [addRequestStatus, setAddRequestStatus] = useState('idle');    
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [formData, setFormData] = useState({})
    const [pageSize, setPageSize] = useState(10);
    const [statusLoading, setStatusLoading] = useState(false);
    const finalColumns = reservasTrabajadadoresRecepcionistaColumns();
    
    const canSave = (
         formData.razonRechazo &&
        formData.id &&
        addRequestStatus === 'idle'
      );
      
    const axiosInstance = createAxiosInstance();
    const [trabajadores, setTrabajadores] = useState([]);

    const callTrabajadores = async () => {
        try {
            setStatusLoading(true);
            const resp = await axiosInstance.get('/api/v1/Solicitud/TrabajadoresSolicitud/'+seleItem?.id);
            console.log('trabajadores',resp.data);
            if(resp.data && resp.data.length > 0){
              setTrabajadores(resp.data);
            }
        } catch (error) {
            console.log(error);
        }finally{
            setStatusLoading(false);
        }
    }

    useEffect(() => {
        if (seleItem) {
          setFormData({ ...formData, id: seleItem?.id });
          console.log(seleItem);
          callTrabajadores();
        }
      }, [seleItem]);
      
    
    useEffect(() => {
        if(formData?.estado == 'Aprobada'){
            setFormData({ ...formData, razonRechazo: '' })
            console.log(seleItem);
        }
    }, [formData?.estado])
    


    const pendiente = async () => {
        setAddRequestStatus('loading')
        try {
            // const resp = await dispatch(aprobarSolicitud(formData));
            const resp = await dispatch(pendienteSolicitud(formData));
            if(resp.payload.status === 'success'){
                setFormData({})
                setOpen3(false)
                setOpen(false)
                return true;
            }
        } catch (error) {
            
        }finally{
            setOpen(false);
            setOpen2(false);
            setAddRequestStatus('idle')
        }
    }
    const rechazar = async () => {
        setAddRequestStatus('loading')
        try {
            const resp = await dispatch(rechazarSolicitud(formData));
            if(resp.payload.status === 'success'){
                setFormData({})
                setOpen2(false)
                setOpen(false)
                return true;
            }
        } catch (error) {
            
        }finally{
            setOpen(false);
            setOpen2(false);
            setAddRequestStatus('idle')
        }
    }

    const cancelar = async () => {
        setAddRequestStatus('loading')
        try {
            const resp = await dispatch(cancelarSolicitud(formData));
            if(resp.payload.status === 'success'){
                setFormData({})
                return true;
            }
        } catch (error) {
            
        }finally{
            setOpen(false);
            setOpen2(false);
            setAddRequestStatus('idle')
        }
    }

  return (
      <Dialog
        anchor='right'
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        PaperProps={{
            sx: {
                backgroundColor:'#efefef',
                color:'#2d3037',
                borderRadius:'0'
            }
          }}
      >
        {seleItem && (
            
            <>
                <DialogTitle style={{ display:'flex', gap:'5px'}}>
                    <FeaturedPlayListIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
                    <div style={{ display:'flex', flexDirection:'column' }}>
                        <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Listado de trabajadores en reserva N°{seleItem?.id}</Typography>
                        <span className={styles.subTitleDark}>Información general de la reserva y listado de trabajadores asociados.</span>
                    </div>
                </DialogTitle>
                <DialogContent>
                  <Grid container={true} spacing={2} mb={2}>
                    <Grid item xs={12} md={4} style={{ textAlign: "start" }}>
                    {
                        statusLoading ? '' 
                        : 
                        <Card style={{ padding: "16px", backgroundColor: "#FFF", color: "var(--inst-card-bg)" }}>
                            <Grid item xs={12} md={12} style={{ marginBottom:'10px', backgroundColor: "#FFF", display: "flex", flexDirection: "column" }} >
                                <label className={styles.label} style={{ fontSize: "12px",fontWeight:'500', color:'var(--inst-orange)', display:'flex', alignItems:'center', gap:'5px' }} > <ApartmentIcon/> Empresa </label>
                                <label className={styles.label} style={{ fontSize: "16px" }}> <strong>{seleItem?.empresa?.razonSocial}</strong></label>
                                <label className={styles.label}> Contrato: {seleItem?.contrato}</label>
                                <label className={styles.label}> Correo: {seleItem?.empresa?.email || 'N/A'}</label>
                                <label className={styles.label}> Fono: {seleItem?.empresa?.fono || 'N/A'}</label>
                                <label className={styles.label}> Fecha Solicitud: {format(seleItem?.fechaSolicitud, 'dd-MM-yyyy')}</label>
                            </Grid>
                  
                            <Grid container={true} mb={1}>
                                <Grid item xs={12} md={5} style={{ backgroundColor: "#FFF", display: "flex", flexDirection: "column", marginBottom:'5px', textAlign:'center' }}>
                                <label className={styles.label} style={{ fontSize: "12px",fontWeight:'500', color:'var(--inst-orange)' }}>Desde</label>
                                <label style={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "5px", }}>
                                    <CalendarMonthIcon /> {seleItem?.fechaIngreso && format(seleItem?.fechaIngreso, "dd-MM-yyyy")}
                                </label>
                                </Grid>
                                
                                <Grid item xs={12} md={2} style={{ textAlign: "center", display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'start', position:'relative', top:'6px', right:'-8px' }}>
                                <label className={styles.label} style={{ fontSize: "12px",fontWeight:'500', color:'var(--inst-orange)' }}></label>
                                <KeyboardDoubleArrowRightIcon />
                                </Grid>

                                <Grid item xs={12} md={5} style={{ backgroundColor: "#FFF", display: "flex", flexDirection: "column", marginBottom:'5px', textAlign:'center' }}>
                                <label className={styles.label} style={{ fontSize: "12px",fontWeight:'500', color:'var(--inst-orange)' }}>Hasta</label>
                                <label style={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "5px" }}><CalendarMonthIcon />{seleItem?.fechaSalida && format(seleItem?.fechaSalida, "dd-MM-yyyy")}</label>
                                </Grid>
                            </Grid>
                            
                            <Grid item xs={12} md={12} style={{ backgroundColor: "#FFF", display: "flex", flexDirection: "column", marginBottom:'5px' }} >
                                <label className={styles.label} style={{ fontSize: "12px",fontWeight:'500', color:'var(--inst-orange)', display:'flex', alignItems:'center', gap:'5px' }}><AdjustIcon/> VP</label>
                                <label className={styles.label}>{seleItem?.vicepresidencia}</label>
                            </Grid>
                            <Grid item xs={12} md={12} style={{ backgroundColor: "#FFF", display: "flex", flexDirection: "column", marginBottom:'5px' }} >
                                <label className={styles.label} style={{ fontSize: "12px",fontWeight:'500', color:'var(--inst-orange)', display:'flex', alignItems:'center', gap:'5px' }}><AdjustIcon/> Gerencia</label>
                                <label className={styles.label}>{seleItem?.gerencia}</label>
                            </Grid>
                            <Grid item xs={12} md={12} style={{ backgroundColor: "#FFF", display: "flex", flexDirection: "column", marginBottom:'10px' }} >
                                <label className={styles.label} style={{ fontSize: "12px",fontWeight:'500', color:'var(--inst-orange)', display:'flex', alignItems:'center', gap:'5px' }}><AdjustIcon/> Si</label>
                                <label className={styles.label}>{seleItem?.sI_Area}</label>
                            </Grid>
                            
                            <Grid item xs={12} md={12} style={{ marginBottom:'10px', backgroundColor: "#FFF", display: "flex", flexDirection: "column" }} >
                                <label className={styles.label} style={{ fontSize: "12px",fontWeight:'500', color:'var(--inst-orange)', display:'flex', alignItems:'center', gap:'5px' }} > <InfoIcon/> Información </label>
                                <label className={styles.label}> Estado: {seleItem?.estado}</label>
                                { seleItem?.rechazadoPor && <label className={styles.label}> Rechazado por: {seleItem?.rechazadoPor.nombre } {' '} {seleItem?.rechazadoPor.apellido }</label> }
                                { seleItem?.aprobaoPor && <label className={styles.label}> Aprobado por: {seleItem?.aprobaoPor.nombre } {' '} {seleItem?.aprobaoPor.apellido }</label> }
                                <label className={styles.label}> Solicitante: {seleItem?.nombreSolicitante} {seleItem?.apellidoSolicitante}</label>
                                <label className={styles.label}> Correo: {seleItem?.emailSolicitante || 'N/A'}</label>
                                <label className={styles.label}> Total Trabajadores: {seleItem?.trabajadoresTotales || 'N/A'}</label>
                                <label className={styles.label}> Observaciones: {seleItem?.observaciones || 'N|A'}</label>
                                { seleItem?.razonRechazo && <label className={styles.label}> Razón Rechazo: {seleItem?.razonRechazo || 'N/A'}</label> }
                            </Grid>

                        </Card>
                    }
                    </Grid>
                    <Grid item xs={12} md={statusLoading ? 12 : 8}>
                    {statusLoading ? <SkeletonTable/> :
                    <Card style={{ padding: "16px", backgroundColor: "#FFF" }}>
                        <DataGrid
                            getRowId={row=>row.id ? row.id : Math.random() }
                            rows={trabajadores}
                            rowHeight={80}
                            columns={finalColumns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10, 15, 20]}
                            localeText={{
                                ...esES.components.MuiDataGrid.defaultProps.localeText,
                                noRowsLabel: "No hay datos disponibles",
                                pagination: {
                                labelRowsPerPage: "Filas por página:",
                                }
                            }}
                            classes={{ menuIcon: styles.menuIcon }}
                            components={{
                                Toolbar: CustomToolbar
                            }}
                            style={{ maxHeight:'500px', border:'none' }}
                            sx={{ 
                                '& .MuiDataGrid-cell': {
                                color: '#2d3037',
                                borderBottom: '1px solid #c6c6c6',
                                },
                                '& .MuiDataGrid-columnHeader': {
                                color: '#2d3037',
                                borderBottom: '1px solid #c6c6c6',
                                },
                                '& .MuiDataGrid-columnHeaders': {
                                borderBottom: '1px solid #c6c6c6',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                color: '#2d3037',
                                borderTop: '1px solid #c6c6c6',
                                },
                                '& .MuiTablePagination-root': {
                                color: '#2d3037',
                                borderTop: '1px solid #c6c6c6',
                                },
                                '& .MuiDataGrid-toolbarContainer': {
                                color: '#2d3037',
                                },
                                '& .MuiDataGrid-menu': {
                                color: '#2d3037',
                                },
                                '& .MuiSvgIcon-root':{
                                color: '#9ca91c',
                                },
                                '& .MuiTablePagination-actions': {
                                color: '#2d3037', // Color for pagination actions
                                },
                                '& .MuiTablePagination-select': {
                                color: '#2d3037', // Color for pagination select
                                },
                                '& .MuiTablePagination-selectLabel': {
                                color: '#2d3037', // Color for pagination select label
                                },
                                '& .MuiTablePagination-displayedRows': {
                                color: '#2d3037', // Color for displayed rows text
                                },
                                '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                                color: 'red',
                                },
                                '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                                color: 'red',
                                },
                            }}
                            slotProps={{
                                    toolbar:{
                                    sx:{
                                        color:'#FFF'
                                    }
                                    },
                                    pagination: {
                                    sx: {
                                        color: '#f19d38',
                                    },
                                    },
                                    menu: {
                                    sx: {
                                        color: '#f19d38',
                                    },
                                    },
                            }}
                        />
                    </Card>
                    }
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid item xs={12} sm={12} className={styles.buttonContainerEdit} style={{ display:'flex', justifyContent:'center' }}>
                    {seleItem && seleItem?.estado && 
                        (seleItem?.estado.trim().toLowerCase() === 'aprobada' || seleItem?.estado.trim().toLowerCase() === 'programada' || seleItem?.estado.trim().toLowerCase() === 'pendiente') && (
                           <Button 
                               onClick={() => setOpen2(true)}
                               className={styles.bgColorDelete}
                               variant="contained" 
                           >
                               Rechazar solicitud
                           </Button>
                       )}
                    </Grid>
                    <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                    {seleItem && seleItem?.estado && (seleItem?.estado.trim().toLowerCase() === 'rechazada' || seleItem?.estado.trim().toLowerCase() === 'programada') && (
                           <Button 
                               onClick={() => setOpen3(true)}
                               className={styles.bgColorOrange}
                               variant="contained" 
                           >
                               cambiar a pendiente
                           </Button>
                       )}                       
                    </Grid>
                    <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                      <Button 
                        onClick={()=>setOpen(false)}
                        className={styles.bgColorOutlined}
                        variant="outlined" 
                      >
                        Cancelar
                      </Button>
                    </Grid>
                </DialogActions>

                <Dialog
                    open={open2}
                    onClose={()=>setOpen2(false)}
                    PaperProps={{
                        sx: {
                            backgroundColor:'#efefef',
                            color:'#2d3037',
                            borderRadius:'0'
                        }
                      }}
                >
                    <DialogTitle>Estás a punto de rechazar el estado de la solicitud {seleItem?.id}</DialogTitle>
                    <DialogContent>
                    <DialogContentText style={{ color:'#2d3037', }}>
                        Escriba el motivo por el cual rechaza la solicitud.
                    </DialogContentText>
                    <Grid container={true} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                className={styles.textField}
                                style={{ padding: '10px' }}
                                sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                    border: 'none',
                                    },
                                    '&:hover fieldset': {
                                    border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                    border: 'none',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color:'#2d3037',
                                },
                                '& .MuiInputLabel-root': {
                                    color:'#2d3037',
                                },
                                padding:'10px',
                                }}
                                margin="dense"
                                id="razon"
                                name="razonRechazo"
                                onChange={(e)=>setFormData({...formData, [e.target.name]: e.target.value})}
                                label="Razón"
                                value={formData.razonRechazo}
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={rechazar}
                            className={styles.bgColorDelete}
                            variant="contained" 
                            disabled={!canSave}
                        >
                            {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Rechazar'}
                        </Button>
                        <Button 
                            onClick={()=>setOpen2(false)}
                            className={styles.bgColorOutlinedDark}
                            variant="outlined" 
                            >
                                Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>

                   <Dialog
                    open={open3}
                    onClose={()=>setOpen3(false)}
                    PaperProps={{
                        sx: {
                            backgroundColor:'#efefef',
                            color:'#2d3037',
                            borderRadius:'0'
                        }
                      }}
                >
                    <DialogTitle>Estás a punto de modificar el estado de la solicitud {seleItem?.id}</DialogTitle>
                    <DialogContent>
                    {/*<DialogContentText style={{ color:'#FFF' }}>
                        Escriba el motivo por el cual cambia el estado de la solicitud a pendiente.
                    </DialogContentText> */}
                    <Grid container={true} spacing={2}>
                        {/*<Grid item xs={12} md={12}>
                            <TextField
                                className={styles.textField}
                                style={{ padding: '10px' }}
                                sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                    border: 'none',
                                    },
                                    '&:hover fieldset': {
                                    border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                    border: 'none',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color:'#2d3037',
                                },
                                '& .MuiInputLabel-root': {
                                    color:'#2d3037',
                                },
                                padding:'10px',
                                }}
                                margin="dense"
                                id="razon"
                                name="razonRechazo"
                                onChange={(e)=>setFormData({...formData, [e.target.name]: e.target.value})}
                                label="Razón"
                                value={formData.razonRechazo}
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid> */}
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={pendiente}
                            className={styles.bgColor}
                            variant="contained" 
                            // disabled={!canSave}
                        >
                            {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Modificar'}
                        </Button>
                        <Button 
                            onClick={()=>setOpen3(false)}
                            className={styles.bgColorOutlinedDark}
                            variant="outlined" 
                            >
                                Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )}
        
      </Dialog>
  );
}