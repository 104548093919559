import styles from './components/components.module.css'
import { Backdrop, Box, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import MapIcon from '@mui/icons-material/Map';
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchSectores, getAllSectores, getSectoresStatus } from "../../../redux/features/sectores/sectorSlice";
import SkeletonTable from "../../../components/Skeleton";
import { useState } from "react";
import { CircularProgress } from '@mui/material';

const Sectores = () => {

  const dispatch = useDispatch();
  const sectores = useSelector(getAllSectores);
  const status = useSelector(getSectoresStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if(status == 'idle'){
      dispatch(fetchSectores());
    }
  }, []);
  
  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestíon de sectores"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con los sectores en la plataforma.">
          {<MapIcon style={{ fontSize:'1.5rem' }} className={styles.iconBgDelete}/>}
        </Header>

        <TableCard>
          <Create setOpen2={setOpen2} open2={open2}/>
          { status == 'loading' ? <SkeletonTable/> : <Table sectores={sectores} setOpen3={setOpen3} /> }
        </TableCard>
      </Container>
    </Box>
  )
}

export default Sectores