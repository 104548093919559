import styles from './components.module.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { toast } from 'react-toastify';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { addNewMerma } from '../../../redux/features/merma/mermaSlice';
import { createAxiosInstance } from '../../../api/axios';
import CircleIcon from '@mui/icons-material/Circle';

const today = new Date();
const date = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

const data = {
  producto: '',
  espacioBodegaId: '',
  bodega:'',
  motivo:'',
  cantidad:0,
  fechaRegistro: date,
  observaciones: '',
  ingresoArticulos: [],
}

const Create = ({ open2, setOpen2, campamentos, bodegas, espacios, inventarios }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); //variable para abrir y cerrar modal
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [filterBodegas, setFilterBodegas] = useState([]);
  const [filterEspacios, setFilterEspacios] = useState([]);
  const [selectedBodega, setSelectedBodega] = useState(null);
  const [selectedEspacio, setSelectedEspacio] = useState(null);
  const [selectedInventario, setSelectedInventario] = useState(null);
  const [searching, setSearching] = useState(false);
  const [newItem, setNewItem] = useState('');
  const [itemsNew, setItemsNew] = useState([]);
  const [items, setItems] = useState([]);
  const [listItems, setListItems] = useState([])
  const [selectedItems, setSelectedItems] = useState(null)
  const [bodegasItems, setBodegasItems] = useState([])
  
  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    setSelectedBodega(null)
    setSelectedEspacio(null);
    setFormData({...formData, bodega: null, espacioBodegaId: null});
    const filter = bodegas.filter(bod => bod.campamento.id == newValue.id);
    console.log('bodegas', bodegas);
    setFilterBodegas(filter);
  }

  const handleChangeBodega = async (event, newValue) => {
    console.log('newValue Bodega', newValue)
    console.log('espacios', espacios);
    setSelectedBodega(newValue);
    setSelectedEspacio(null);
    
    if(newValue != null){
      try {
        const response = await getBodegas(newValue.id);
        setBodegasItems(response);
      } catch (error) {
        
      }
      setFormData({...formData, bodega: newValue.id, espacioBodegaId: null});
      const filter = espacios.filter(bod => bod.bodega.id === newValue.id);
      console.log('espacios Filtrados',filter);
      setFilterEspacios(filter);
    } else{
      setFormData({...formData, bodega: 0})
    }  
  };

  const handleChangeEspacio = (event, newValue) => {
    setSelectedEspacio(newValue);
    if(newValue != null){
      setFormData({...formData, espacioBodegaId: newValue.id});
    } else{
      setFormData({...formData, espacioBodegaId: 0})
    }  
  };
  
  const handleChangeInventario = (event, newValue) => {
    setSelectedInventario(newValue);
    if(newValue != null){
      setFormData({...formData, productoId: newValue.id});
    } else{
      setFormData({...formData, productoId: 0})
    }  
  };

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  const canSave = [formData.producto, formData.espacioBodegaId, formData.cantidad, formData.motivo, formData.observaciones ].every(Boolean) && addRequestStatus === 'idle';


  const onChange2 = (e, index) => {
    const { name, value } = e.target;
    setItemsNew(prevItems => {
        const updatedItems = [...prevItems];
        updatedItems[index][name] = value;

        setFormData(prev => ({
            ...prev,
            ingresoArticulos: updatedItems
        }));
        
        return updatedItems;
    });
};

const handleReplaceItem = (newItem) => {
  const confirmReplace = window.confirm('¿Quieres borrar el artículo existente y reemplazarlo con el nuevo?');
  if (confirmReplace) {
    setFormData(prev => ({
      ...prev,
      productoId: '',
      ingresoArticulos: [newItem]
    }));
  }
};


const axiosInstance = createAxiosInstance();

const getBodegas = async (bodega) => {
  try {
    const response = await axiosInstance.get('/api/v1/Inventario/productos-bodega/'+bodega);
    console.log('bodegas productos: ',response.data);
    // setBodegasItems(response.data);
    setListItems(response.data);
    return response.data
  } catch (error) {
    console.log('Error al traer los items: ',error)
  }
}

const getProducts = async () => {
  try {
    const response = await axiosInstance.get('/api/v1/producto');
    console.log(response);
    setListItems(response.data);
  } catch (error) {
    console.log('Error al traer los items: ',error)
  }
}

useEffect(() => {
  getProducts();
}, [])


const search = async (event, newValue) => {
  try {
    setSearching(true);

    if (newValue) {
      const alreadyIn = formData?.producto.codigo === newValue.codigo;
      if(alreadyIn){
        toast.error('El artículo ya esta seleccionado.');
      } else {
            setItems([...items, newValue.codigo]);
            setItemsNew([...itemsNew, newValue])
            setFormData(prev => ({
              ...prev,
              producto: newValue
            }));
      }
    } else {
      toast.error('No se ha encontrado el artículo.');
    }
  } catch (error) {
    // Manejar error
  } finally {
    setSearching(false);
  }
};

  const handleSubmit = async () => {
    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewMerma(formData));
        if(resp.payload.status === 'success'){
          setFormData(data);
          setOpen(false);
          setOpen2(false);
        }
      } catch (error) {
        console.error('Error al crear la maerma', error);
      } finally{
        setAddRequestStatus('idle');
      }
    }
  }

  const handleRemove = (index) => {
    const newItems = [...itemsNew];
    newItems.splice(index, 1);
    setItemsNew(newItems);
    
    const newFormData = { ...formData };
    newFormData.ingresoArticulos = newItems;
    newFormData.productoId = '';
    setFormData(newFormData);
  };

  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder declarar la merma.</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> setOpen(true)}><CircleIcon className={styles.iconButton}/>Ingresar registro</Button>
      </div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth={true}
        maxWidth="md"
        PaperProps={{
          sx: {
           backgroundColor:'#efefef',
           color:'#2d3037',
           borderRadius:'0'
          }
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <RestoreFromTrashIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }}/>
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Declaración de merma</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para declarar la merma.</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color:'#2d3037',
                      },
                      '& .MuiInputLabel-root': {
                        color:'#2d3037',
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color:'#2d3037',
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color:'#2d3037',
                      },
                    }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={campamentos}
                name="campamento"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeCampamento(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
              />      
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Bodega</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                  }}
                  disablePortal
                  disableClearable
                  disabled={!selectedCampamento}
                  id="campamentos-autocomplete"
                  options={filterBodegas}
                  name="bodega"
                  value={selectedBodega || null}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, value)=> handleChangeBodega(e, value)}
                  renderInput={(params) => <TextField {...params} label="Selecciona una bodega" />}
                /> 
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Espacio en bodega</label>
                 <Autocomplete
                  className={styles.textField}
                  sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color:'#2d3037',
                      },
                      '& .MuiInputLabel-root': {
                        color:'#2d3037',
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color:'#2d3037',
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color:'#2d3037',
                      },
                  }}
                  disablePortal
                  disableClearable
                  disabled={!selectedBodega}
                  id="campamentos-autocomplete"
                  options={filterEspacios}
                  name="bodega"
                  value={selectedEspacio || null}
                  getOptionLabel={(option) => option.identificador}
                  onChange={(e, value)=> handleChangeEspacio(e, value)}
                  renderInput={(params) => <TextField {...params} label="Seleccion un espacio de la bodega" />}
                />
            </Grid>
            
            {/*  ------------- LISTA DE ARTICULOS -------------  */}
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Fecha de Registro</label>
              <div style={{ display:'flex', gap:'5px', alignItems:'center' }}>
                <TextField
                    className={styles.textField}
                    sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color:'#2d3037',
                    },
                    '& .MuiInputLabel-root': {
                      color:'#2d3037',
                    },
                    }}
                    variant="outlined"
                    fullWidth
                    name='fechaRegistro'
                    type="date"
                    value={formData?.fechaRegistro}
                    onChange={(e) => onChange(e)}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
                <label className={styles.label}>
                  {selectedBodega ? 'Lista de artículos (Busca artículos por nombre o por código)' : 'Primero selecciona una bodega para poder buscar árticulos.'}
                </label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color:'#2d3037',
                    },
                    '& .MuiInputLabel-root': {
                      color:'#2d3037',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color:'#2d3037',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color:'#2d3037',
                    },
                  }}
                  disablePortal
                  disableClearable
                  id="campamentos-autocomplete"
                  options={listItems}
                  name="bodega"
                  disabled={!selectedBodega}
                  value={selectedItems}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(event, value) => search(event, value)}
                  renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
            {formData.producto && 
              <Grid item xs={12} sm={12}>
                <Typography style={{ fontSize:'16px', fontWeight:'500' }}>Árticulo</Typography>
              </Grid>
            }
            <Grid container={true} spacing={1} style={{ maxHeight:'500px', overflowY:'auto', padding:'5px', margin:'0', marginTop:'0px' }}> 
              {formData.producto &&
                <>
                <Grid item xs={12} md={2}>
                    <label className={styles.label}>Código</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='codigo'
                      value={formData?.producto?.codigo}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <label className={styles.label}>Nombre</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='nombre'
                      value={formData?.producto?.nombre}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <label className={styles.label}>Descripción</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='descripcion'
                      value={formData?.producto?.descripcion}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <label className={styles.label}>Precio</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='nombre'
                      value={formData?.producto?.precio}
                      type="text"
                    />
                  </Grid>
                </>
              }
            </Grid>
            <Grid item  xs={12} sm={3}>
              <label className={styles.label}>Cantidad</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                }}
                variant="outlined"
                fullWidth
                name='cantidad'
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={9}>
              <label className={styles.label}>Motivo</label>
              <Select
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color:'#2d3037',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color:'#2d3037',
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData?.motivo}
                label=""
                name="motivo"
                onChange={(e) => onChange(e)}
                style={{ width:'100%' }}
              >
                <MenuItem value='Deterioro'>Deterioro</MenuItem>
                <MenuItem value='Caducidad'>Caducidad</MenuItem>
                <MenuItem value='Rotura o daño durante el transporte y manipulación'>Rotura o daño durante el transporte y manipulación</MenuItem>
                <MenuItem value='Obsolescencia'>Obsolescencia</MenuItem>
                <MenuItem value='Defectuoso'>Defectuoso</MenuItem>
                <MenuItem value='Extravío'>Extravío</MenuItem>
              </Select>              
            </Grid>
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Observaciones</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                }}
                variant="outlined"
                fullWidth
                name='observaciones'
                value={formData?.observaciones}
                type="text"
                multiline
                rows={4}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button 
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained" 
                disabled={!canSave}>
                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
              </Button>
              <Button 
                onClick={()=>setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined" 
                >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Create