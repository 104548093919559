import { Box, Container } from "@mui/material"
import Header from "../../components/header/Header"
import TableCard from "../../components/tableCard/TableCard";
import SkeletonTable from "../../components/Skeleton";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import Create from "./components/Create";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from '@mui/material';
import { fetchInventarios, fetchTomas, getAllInventarios, getAllTomas, getInventariosStatus, getTomasStatus } from "../../redux/features/inventario/inventarioSlice";
import CompareIcon from '@mui/icons-material/Compare';
import { createAxiosInstance } from "../../api/axios";
import { fetchBodegas, fetchEspaciosBodega, getAllBodegas, getAllEspacios, getBodegasStatus, getEspaciosStatus } from "../../redux/features/bodegas/bodegaSlice";
import { fetchMermas, getAllMermas, getMermasStatus } from "../../redux/features/merma/mermaSlice";

const Comparacion = () => {

  const dispatch = useDispatch();
  const inventario = useSelector(getAllInventarios);
  const status = useSelector(getInventariosStatus);
  const bodegas = useSelector(getAllBodegas);
  const bodStatus = useSelector(getBodegasStatus);
  const mermas = useSelector(getAllMermas);
  const merStatus = useSelector(getMermasStatus);
  const tomas = useSelector(getAllTomas);
  const tomasStatus = useSelector(getTomasStatus);
  const espacios = useSelector(getAllEspacios);
  const espaciosStatus = useSelector(getEspaciosStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  
  useEffect(()=>{
    if(status === 'idle'){
        dispatch(fetchInventarios());
    }
    if(bodStatus === 'idle'){
    }
    dispatch(fetchBodegas());
    if(merStatus === 'idle'){
        dispatch(fetchMermas());
    }
    if(tomasStatus === 'idle'){
    }
    if(espaciosStatus === 'idle'){
      dispatch(fetchEspaciosBodega());
    }
    dispatch(fetchTomas());
  },[]);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Toma de inventario"
          subtitle="En este módulo, podrás revisar in-situ los items de una bodega y compararlos con los que están en el inventario con el fin de corroborar que la información en el sistema esté correcta. Además permite al administrador revisar si se han perdido items.">
          {<CompareIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>

        <TableCard>
          <Create setOpen2={setOpen2} open2={open2} inventario={inventario} bodegas={bodegas} mermas={mermas} tomas={tomas} espacios={espacios} tomasStatus={tomasStatus}/>
          {/*{ status == 'loading' ? <SkeletonTable/> : <Table inventario={inventario ? inventario : []} setOpen3={setOpen3}/> 
        }
      */}
        </TableCard>
      </Container>
    </Box>
  )
}
export default Comparacion