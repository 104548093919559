import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useAuth } from "../../context/AppContext";

const GetToken = () => {
  const { instance } = useMsal();
  const { loginSSO } = useAuth();

  useEffect(() => {
    const getUrlParameters = () => {
      const urlParams = new URLSearchParams(window.location.hash.substring(1));
      const params = {
        code: urlParams.get("code"),
        client_info: urlParams.get("client_info"), // Base64 string
        state: urlParams.get("state"),
        session_state: urlParams.get("session_state"),
      };
      return params;
    };

    const decodeBase64 = (base64String) => {
      try {
        // Decodificar Base64
        const decodedString = atob(base64String); // Decodifica la cadena Base64
        // Convertir la cadena decodificada en JSON
        const jsonObject = JSON.parse(decodedString);
        return jsonObject;
      } catch (error) {
        console.error("Error al decodificar Base64:", error);
        return null;
      }
    };

    const params = getUrlParameters();
    console.log("Parámetros capturados:", params);

    const handleLogin = async () => {
      if (params.client_info) {
        // Decodificar client_info (que está en Base64)
        const decodedClientInfo = decodeBase64(params.client_info);
        if (decodedClientInfo) {
          const { uid, utid } = decodedClientInfo; // Extraer uid y utid
          console.log("UID:", uid);
          console.log("UTID:", utid);

          // Llamar a loginSSO con el uid extraído
          await loginSSO(uid);
        }
      }
    };

    handleLogin();
  }, [loginSSO]);

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <div>
      <h1>...</h1>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default GetToken;
