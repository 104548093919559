import styles from './components.module.css';
import { useState } from "react";
import { espaciosDashboardColumns } from "../../../lib/columns";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { Card, Typography } from '@mui/material';
import SkeletonTable from '../../../components/Skeleton'
import CustomToolbar from '../../../components/SlotsToolBar';

const Table = ({rows, addRequestStatus}) => {
    const [seleItem, setSeleItem] = useState();
    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const finalColumns = espaciosDashboardColumns(setOpen, setSeleItem);

  return (
    <div style={{ width:'100%', marginTop:'10px' }}>
    { addRequestStatus == 'loading' 
        ? <SkeletonTable/> 
        : <Card style={{ padding:'16px', backgroundColor:'#FFF' }}>
          <Typography style={{ fontSize:'18px', fontWeight:'500', marginBottom:'1rem', color:'var(--inst-card-bg)' }}>Listado de espacios</Typography>
          <DataGrid
            getRowId={row => row.id ? row.id : Math.random() }
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
                toolbar: { rows, columns: finalColumns }
            }}
            rows={rows}
            columns={finalColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15, 20]}
            localeText={{
                ...esES.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: "No hay datos disponibles",
                pagination: {
                    labelRowsPerPage: "Filas por página:",
                }
            }}
            style={{ maxHeight: '500px', color: '#FFF', border: 'none' }}
            sx={{ 
            '& .MuiDataGrid-cell': {
              color: '#2d3037',
              borderBottom: '1px solid #c6c6c6',
            },
            '& .MuiDataGrid-columnHeader': {
              color: '#2d3037',
              borderBottom: '1px solid #c6c6c6',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: '1px solid #c6c6c6',
            },
            '& .MuiDataGrid-footerContainer': {
              color: '#2d3037',
              borderTop: '1px solid #c6c6c6',
            },
            '& .MuiTablePagination-root': {
              color: '#2d3037',
              borderTop: '1px solid #c6c6c6',
            },
            '& .MuiDataGrid-toolbarContainer': {
              color: '#2d3037',
            },
            '& .MuiDataGrid-menu': {
              color: '#2d3037',
            },
            '& .MuiSvgIcon-root':{
              color: '#9ca91c',
            },
            '& .MuiTablePagination-actions': {
              color: '#2d3037', // Color for pagination actions
            },
            '& .MuiTablePagination-select': {
              color: '#2d3037', // Color for pagination select
            },
            '& .MuiTablePagination-selectLabel': {
              color: '#2d3037', // Color for pagination select label
            },
            '& .MuiTablePagination-displayedRows': {
              color: '#2d3037', // Color for displayed rows text
            },
            '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
              color: 'red',
            },
            '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
              color: 'red',
            },
            }}
            slotProps={{
                toolbar:{
                sx:{
                    color:'#FFF'
                }
                },
                pagination: {
                sx: {
                    color: '#f19d38',
                },
                },
                menu: {
                sx: {
                    color: '#f19d38',
                },
                },
            }}
        />
        </Card>
     }
    </div>
  )
}

export default Table